import ListModel from "../misc/ListModel";
import { ContainerImageConfigVolume } from "./Container";
import {
  ContainerVolumeRelation,
  ContainerVolumeRelationFormData,
} from "./ContainerVolumeRelation";

export class ContainerVolumeRelationList extends ListModel<ContainerVolumeRelation> {
  public static fromApiData(data: string[]): ContainerVolumeRelationList {
    const relations = data.map((s) => ContainerVolumeRelation.fromApiData(s));
    return new ContainerVolumeRelationList(relations);
  }

  public static fromFormData(
    data: ContainerVolumeRelationFormData[],
  ): ContainerVolumeRelationList {
    return new ContainerVolumeRelationList(
      data.map((p) => ContainerVolumeRelation.fromFormData(p)),
    );
  }

  public static fromImageMeta(
    data: ContainerImageConfigVolume[],
  ): ContainerVolumeRelationList {
    return new ContainerVolumeRelationList(
      data.map((v) => ContainerVolumeRelation.fromApiData(v.volume)),
    );
  }

  public asFormData(): ContainerVolumeRelationFormData[] {
    return this.items.map((r) => r.asFormData());
  }

  public findRelationByVolumeName(
    name: string,
  ): ContainerVolumeRelation | undefined {
    return this.items.find(
      (r) =>
        (r.type === "volume" && r.volume === name) ||
        r.projectDirectory === name,
    );
  }

  public asStringArray(): string[] {
    return this.items.map((v) => v.data);
  }
}

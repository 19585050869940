import React, { FC } from "react";
import {
  supportPhoneNumber,
  supportPhoneNumberFormatted,
  supportPhoneNumberFreshmen,
  supportPhoneNumberFreshmenFormatted,
} from "../config";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";

interface Props {
  freshmen?: boolean;
}
export const SupportPhoneLink: FC<Props> = ({ freshmen = false }) => {
  return (
    <TextLink
      action={`tel:${freshmen ? supportPhoneNumberFreshmen : supportPhoneNumber}`}
      text={
        freshmen
          ? supportPhoneNumberFreshmenFormatted
          : supportPhoneNumberFormatted
      }
    />
  );
};

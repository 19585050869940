import { MittwaldApi } from "../../api/Mittwald";
import PhoneNumber from "../misc/PhoneNumber";
import User from "../user/User";
import {
  CustomerAddress,
  CustomerAddressUpdateInputs,
} from "./CustomerAddress";
import { SalutationApiSchema } from "../misc/Salutation";

export type CustomerContactApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Customer_Contact;

export interface CustomerContactInputs {
  address: CustomerAddressUpdateInputs;
  company: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  salutation: SalutationApiSchema;
  phoneNumber: string;
}

export class CustomerContact {
  public readonly data: CustomerContactApiData;
  public readonly phoneNumber: PhoneNumber | undefined;
  public readonly address: CustomerAddress;
  public readonly company?: string;
  public readonly definedName?: string;

  private constructor(data: CustomerContactApiData) {
    this.data = Object.freeze(data);
    this.phoneNumber = data.phoneNumbers?.[0]
      ? new PhoneNumber(data.phoneNumbers[0])
      : undefined;
    this.address = CustomerAddress.fromApiData(data.address);
    this.company = data.company;
    this.definedName =
      data.firstName && data.lastName
        ? `${data.firstName} ${data.lastName}`
        : data.company;
  }

  public static fromApiData(data: CustomerContactApiData): CustomerContact {
    return new CustomerContact(data);
  }

  public static useGetContactDefaultValues = (): Partial<
    Pick<
      CustomerContactInputs,
      "emailAddress" | "salutation" | "firstName" | "lastName"
    >
  > => {
    const user = User.useMe();
    const profile = user.useProfile();

    return {
      emailAddress: user.email,
      salutation: profile.person?.title?.value ?? "other",
      firstName: profile.person?.firstName,
      lastName: profile.person?.lastName,
    };
  };
}

export default CustomerContact;

import { Box, BoxProps } from "@mittwald/flow-components/dist/components/Box";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";

interface ConditionsCheckboxProps {
  buttonText?: I18nDefinition;
  marginTop?: BoxProps["_mt"];
  showPHPHint?: boolean;
}

export const ConditionsCheckbox: FC<ConditionsCheckboxProps> = (props) => {
  const { buttonText, marginTop = "l", showPHPHint } = props;

  const conditionsLink = (
    <TextLink
      action={import.meta.env.VITE_CONDITIONS_LINK}
      i18n="conditions"
      target="_blank"
    />
  );

  const privacyLink = (
    <TextLink
      action={import.meta.env.VITE_PRIVACY_LINK}
      i18n="privacy"
      target="_blank"
    />
  );

  const phpConditionsLink = (
    <TextLink
      action={import.meta.env.VITE_PHP_EXTENDED_LINK}
      i18n="phpConditions"
      target="_blank"
    />
  );

  const checkboxTitle = (
    <Text
      i18n={{
        id: showPHPHint ? "checkboxTextWithPhpNotice" : "checkboxText",
        values: {
          conditions: conditionsLink,
          privacy: privacyLink,
          phpConditions: phpConditionsLink,
          buttonText: <Translate i18n={buttonText || "defaultButtonText"} />,
        },
      }}
      small
    />
  );

  return (
    <TranslationProvider name="conditionsCheckbox" type="component">
      <Box _mt={marginTop}>
        <CheckBox
          name="conditions"
          rules={{ required: true }}
          title={checkboxTitle}
        />
      </Box>
    </TranslationProvider>
  );
};

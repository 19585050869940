import { Box } from "@mittwald/flow-components/dist/components/Box";
import { DateTime as DateTimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import { PriceTable } from "@mittwald/flow-components/dist/components/PriceTable";
import HorizontalRuler from "@mittwald/flow-components/dist/components/Section/styled/HorizontalRuler";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { DateTime } from "luxon";
import React, { FC } from "react";
import {
  ProSpaceArticle,
  SpaceServerArticle,
} from "../../../../../../model/article";
import Bytes from "../../../../../../model/misc/Bytes";
import Order from "../../../../../../model/order/Order";
import PriceBox from "@mittwald/flow-components/dist/components/PriceBox";
import { PromoCode } from "../../../../../../widgets/utils/promoCode";

export interface OrderOverviewBoxProps {
  selectedArticle: SpaceServerArticle | ProSpaceArticle;
  storageSize: Bytes;
  freeTrial?: boolean;
  promotion?: PromoCode;
}

export const OrderOverviewBox: FC<OrderOverviewBoxProps> = (props) => {
  const { selectedArticle, freeTrial, storageSize, promotion } = props;

  const inclusiveStorage = selectedArticle.baseStorageAttribute.bytes;
  const additionalStorage = storageSize.subtract(inclusiveStorage);

  const orderPreview = selectedArticle.useOrderPreview({ storageSize });

  return (
    <TranslationProvider name="orderOverview" type="component">
      <Box _itemGap="s">
        <H3 _mb="m" i18n="article" />
        <PriceTable twoColumns>
          <PriceTable.Row
            strong
            title={{
              id: "idHere",
              values: {
                product: selectedArticle.name,
              },
            }}
          />
          <PriceTable.Row
            monthlyPrice={orderPreview.machine.toMoneyValueInput()}
            strong={false}
            title={{ text: selectedArticle.machineType.getDescription("full") }}
          />
          <PriceTable.Row
            monthlyPriceText="inclusive"
            strong={false}
            title={{
              id: "storage",
              values: {
                size: inclusiveStorage.pretty(),
              },
            }}
          />
          {additionalStorage.bytes !== 0 && (
            <PriceTable.Row
              monthlyPrice={orderPreview.storage.toMoneyValueInput()}
              strong={false}
              title={{
                id: "additionalStorage",
                values: {
                  size: additionalStorage.pretty(),
                },
              }}
            />
          )}

          {(freeTrial || promotion) && (
            <>
              <HorizontalRuler _my="s" />
              <PriceTable.Row
                monthlyPrice={0}
                subTitle="allFeatures"
                title={{
                  id: promotion
                    ? "totalWithPromotion"
                    : freeTrial
                      ? "totalWithFreeTrial"
                      : "total",
                  values: { days: promotion?.promotionDays ?? 30 },
                }}
              />
              <Box _mt="m">
                <PriceBox
                  monthlyPrice={orderPreview.total.toMoneyValueInput()}
                  title={{
                    id: "totalAfterFreeTrial",
                    values: {
                      date: (
                        <DateTimeComponent
                          format="date"
                          value={
                            promotion
                              ? DateTime.now().plus({
                                  day: promotion.promotionDays,
                                })
                              : Order.freeTrialUntil
                          }
                        />
                      ),
                    },
                  }}
                />
              </Box>
            </>
          )}
        </PriceTable>
        {!freeTrial && !promotion && (
          <Box _mt="m">
            <PriceBox
              monthlyPrice={orderPreview.total.toMoneyValueInput()}
              title={{
                id: "total",
              }}
            />
          </Box>
        )}
      </Box>
    </TranslationProvider>
  );
};

import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import {
  ProSpaceArticle,
  ProSpaceArticleTag,
} from "../../../../../../../model/article";
import Bytes from "../../../../../../../model/misc/Bytes";
import { PromoCode } from "../../../../../../../widgets/utils/promoCode";
import ProSpaceLiteSection from "./components/ProSpaceLiteSection";
import PriceSection from "./components/PriceSection";
import ProSpaceDedicatedSection from "./components/ProSpaceDedicatedSection";
import ProSpaceSection from "./components/ProSpaceSection";

interface Props {
  selectedArticle: ProSpaceArticle;
  watchedStorageSize: Bytes;
  freeTrial: boolean;
  promotion?: PromoCode;
}

export const OrderPreviewBox: FC<Props> = (props) => {
  const { selectedArticle, watchedStorageSize, freeTrial, promotion } = props;

  return (
    <TranslationProvider name="projectOrderPreviewBox" type="component">
      {selectedArticle.articleTag === ProSpaceArticleTag.proSpaceLite && (
        <ProSpaceLiteSection />
      )}
      {selectedArticle.articleTag === ProSpaceArticleTag.proSpace && (
        <ProSpaceSection />
      )}
      {selectedArticle.articleTag === ProSpaceArticleTag.proSpaceDedicated && (
        <ProSpaceDedicatedSection />
      )}

      <PriceSection
        freeTrial={freeTrial}
        promotion={promotion}
        selectedArticle={selectedArticle}
        watchedStorageSize={watchedStorageSize}
      />
    </TranslationProvider>
  );
};

export default OrderPreviewBox;

import { StraceResultApiData } from "../../model/performance/TtfbAnalysis";
import { demoDate } from "../staticDemoData";

export const demoServerPerformance: StraceResultApiData = {
  executedAt: demoDate,
  id: "1",
  result: {
    slowdownFactor: 1,
    actualUrl: "https://www.mittwald.de/~b",
    dbQueries: [
      {
        query: "SELECT FGHJK_options",
        stats: {
          syscallCount: 1,
          kernelMs: 200.333333,
          userspaceMs: 100,
          occurrences: 1,
        },
        warnLevel: "NO",
      },
    ],
    dbStats: {
      kernelMs: 200.3333333,
      userspaceMs: 200,
      syscallCount: 1,
      occurrences: 1,
    },
    fileOps: [
      {
        filename: "wp-config.php",
        filepath: "/home/p-4e7tz3/my-project/",
        warnLevel: "WARN",

        stats: {
          syscallCount: 4,
          kernelMs: 50,
          userspaceMs: 50,
          occurrences: 4,
        },
      },
      {
        filename: "test.php",
        filepath:
          "/home/p-4e7tz3/my-project/1233543667567-1232142-1241234324/test/",
        warnLevel: "SEVERE",
        stats: {
          syscallCount: 4,
          kernelMs: 50,
          userspaceMs: 50,
          occurrences: 4,
        },
      },
    ],
    fileOpsStats: {
      userspaceMs: 40,
      kernelMs: 80,
      syscallCount: 45,
      occurrences: 45,
    },
    miscStats: {
      kernelMs: 20,
      userspaceMs: 30,
      syscallCount: 78,
      occurrences: 78,
    },
    networkingOps: [
      {
        stats: {
          syscallCount: 40,
          kernelMs: 600,
          userspaceMs: 200,
          occurrences: 50,
        },
        description: "db123.mittwald.server.info:3306",
        ip: "100.12.34.56",
        port: 3000,
        connectionType: "PRIVATE",
        warnLevel: "NO",
      },
    ],
    networkingStats: {
      userspaceMs: 200,
      kernelMs: 100,
      syscallCount: 1,
      occurrences: 1,
    },
    ttfbMs: 860,
  },
};

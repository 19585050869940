import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import {
  ProSpaceProject,
  SpaceServerProject,
  ProjectApiData,
  ProjectBase,
  ProjectListItemApiData,
} from "./";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";

export type AnyProject = ProSpaceProject | SpaceServerProject | ProjectBase;

export class Project {
  public static fromApiData(data: ProjectApiData): AnyProject {
    if (data.projectHostingId) {
      return new ProSpaceProject(data);
    } else if (data.serverId) {
      return new SpaceServerProject(data);
    } else {
      return new ProjectBase(data);
    }
  }

  public static fromProjectListItemApiData(
    data: ProjectListItemApiData,
  ): AnyProject {
    if (data.projectHostingId) {
      return new ProSpaceProject({ ...data, directories: [] });
    } else if (data.serverId) {
      return new SpaceServerProject({ ...data, directories: [] });
    } else {
      return new ProjectBase({ ...data, directories: [] });
    }
  }

  public static useLoadById(id: string): AnyProject {
    const data = mittwaldApi.projectGetProject
      .getResource({
        path: {
          projectId: id,
        },
      })
      .useWatchData();
    return Project.fromApiData(data);
  }

  public static async getLoadById(id: string): Promise<AnyProject> {
    const response = await mittwaldApi.projectGetProject.request({
      path: {
        projectId: id,
      },
    });

    assertStatus(response, 200);

    return Project.fromApiData(response.content);
  }

  public static useLoadByUserId(userId: string): AnyProject[] {
    const data = mittwaldApi.projectListProjects
      .getResource({
        path: {
          userId,
        },
      })
      .useWatchData();
    return data.map(Project.fromProjectListItemApiData);
  }

  public static useTryLoadById(id?: string): AnyProject | undefined {
    const data = mittwaldApi.projectGetProject
      .getResource(id ? { path: { projectId: id } } : null)
      .useWatchData({ optional: true });
    return data ? Project.fromApiData(data) : undefined;
  }

  public static async loadById(id: string): Promise<AnyProject> {
    const response = await mittwaldApi.projectGetProject.request({
      path: {
        projectId: id,
      },
    });

    assertStatus(response, 200);

    return Project.fromApiData(response.content);
  }

  public static useTryLoadByUserId(userId?: string): AnyProject[] {
    const data = mittwaldApi.projectListProjects
      .getResource(userId ? { path: { userId } } : null)
      .useWatchData({ optional: true });
    return data ? data.map(Project.fromProjectListItemApiData) : [];
  }

  public static useTryLoadByCustomerId(customerId?: string): AnyProject[] {
    return (
      mittwaldApi.projectListProjects
        .getResource(customerId ? { query: { customerId } } : null)
        .useWatchData()
        ?.map((p) => Project.fromProjectListItemApiData(p)) ?? []
    );
  }

  public static async getLoadByCustomerId(
    customerId?: string,
  ): Promise<AnyProject[]> {
    const response = await mittwaldApi.projectListProjects.request({
      query: {
        customerId,
      },
    });

    assertStatus(response, 200);

    return response.content.map((p) => Project.fromProjectListItemApiData(p));
  }

  public static useLoadByPathParam(): AnyProject {
    const { projectId } = usePathParams("projectId");
    return Project.useLoadById(projectId);
  }

  public static useTryLoadByPathParam(): AnyProject | undefined {
    const { projectId } = useOptionalPathParams();
    return Project.useTryLoadById(projectId);
  }
}

export default Project;

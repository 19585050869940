import invariant from "invariant";
import { Class } from "type-fest";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import MoneyValue from "../misc/MoneyValue";
import {
  articleModifierFactory,
  ArticleModifier,
  articleAttributeFactory,
  ArticleAttribute,
  ArticleAttributeKey,
} from "./";

export enum ArticleTemplateName {
  spaceServerHosting = "Multi-Tec-Hosting",
  proSpaceHosting = "Pro-Space-Hosting",
  domain = "domain",
}

export enum ArticleId {
  spaceServerHostingStorage = "MT22-Storage",
  proSpaceHostingStorage = "PS23-Storage",
}

export type ArticleApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Article_ReadableArticle;

export class Article {
  public readonly id: string;
  public readonly name: string;
  public readonly data: ArticleApiData;
  public readonly price: MoneyValue;
  public readonly attributes: ArticleAttribute[];
  public readonly modifiers: ArticleModifier[];

  public constructor(data: ArticleApiData) {
    this.id = data.articleId;
    this.name = data.name;
    this.data = Object.freeze(data);
    this.price = new MoneyValue(data.price ?? 0);
    this.attributes =
      data.attributes?.map((a) => articleAttributeFactory(a)) ?? [];
    this.modifiers = data.modifierArticles?.map(articleModifierFactory) ?? [];
  }

  public isEqual(other: Article): boolean {
    return this.id === other.id;
  }

  public static useLoadByIdDry(): undefined {
    mittwaldApi.articleGetArticle.getResource(null).useWatchData();
    return;
  }

  public getModifier(modifier: string): ArticleModifier | undefined {
    return this.modifiers.find((m) => m.constructor.name === modifier);
  }

  public getAttribute(
    key: ArticleAttributeKey | string,
  ): ArticleAttribute | undefined {
    return this.attributes.find((a) => a.key === key);
  }

  public requireAttribute(key: ArticleAttributeKey | string): ArticleAttribute {
    const attribute = this.getAttribute(key);
    invariant(!!attribute, `Required attribute not found: ${key}`);
    return attribute;
  }

  public static assertType<T extends Class<Article>>(
    article: Article,
    type: T,
  ): asserts article is InstanceType<T> {
    invariant(
      article.isOfType(type),
      `Expected value which is \`${type.name}\`, received value of type \`${this.constructor.name}\`.`,
    );
  }

  public asType<T extends Class<Article>>(type: T): InstanceType<T> {
    Article.assertType(this, type);
    return this;
  }

  public isOfType<T extends Class<Article>>(type: T): this is InstanceType<T> {
    return this instanceof type;
  }
}

export default Article;

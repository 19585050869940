import { HorizontalSwitchSliderItems } from "@mittwald/flow-components/dist/components/HorizontalSwitchSlider/types";
import React from "react";
import { HostingArticle } from "../../../../model/article";
import StorageSliderLabel from "../components/StorageSliderLabel";

export const useBuildStorageSwitchSliderItems = (
  article: HostingArticle,
): HorizontalSwitchSliderItems<any> => {
  const modifier = article.storageModifier;
  const modifierBytes = article.storageModifier.useBytes();

  return Array(modifier.maxCount)
    .fill(1)
    .map((ignored, index) => {
      const size = modifierBytes
        .multiply(index)
        .add(article.baseStorageAttribute.bytes);

      return {
        label: <StorageSliderLabel size={size} />,
        value: size.bytes,
      };
    });
};

export default useBuildStorageSwitchSliderItems;

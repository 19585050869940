import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { UseControllerProps } from "react-hook-form";
import React, { FC } from "react";

interface EmailAddressInputProps extends Pick<UseControllerProps, "rules"> {
  name?: string;
  disabled?: boolean;
}

export const EmailAddressInput: FC<EmailAddressInputProps> = (props) => {
  const { name, ...rest } = props;
  return (
    <TextField
      {...rest}
      autoComplete="email"
      label="email"
      name={name ?? "email"}
      type="email"
    />
  );
};

import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoDefaultIngress,
  demoDnsZone,
  demoDomain,
  demoIngress,
  demoTld,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockDomainListTlds(
    {},
    { status: 200, content: [demoTld] },
  );

  mittwaldApiRequestMocking.mockDomainCreateDomainAuthCode(
    { path: { domainId: "*" } },
    { status: 200, content: "123456789" },
  );

  mittwaldApiRequestMocking.mockDomainListDomains(
    { query: { projectId: "*" } },
    { status: 200, content: [demoDomain] },
  );

  mittwaldApiRequestMocking.mockDomainGetDomain(
    { path: { domainId: "*" } },
    { status: 200, content: demoDomain },
  );

  mittwaldApiRequestMocking.mockDomainUpdateDomainNameservers(
    { path: { domainId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockDomainUpdateDomainContact(
    { path: { domainId: "*", contact: "owner" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockDnsListDnsZones(
    { path: { projectId: "*" } },
    { status: 200, content: [demoDnsZone] },
  );

  mittwaldApiRequestMocking.mockIngressListIngresses(
    {},
    { status: 200, content: [demoIngress, demoDefaultIngress] },
  );

  mittwaldApiRequestMocking.mockIngressCreateIngress(
    {},
    { status: 201, content: demoIngress },
  );

  mittwaldApiRequestMocking.mockIngressDeleteIngress(
    { path: { ingressId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockIngressUpdateIngressPaths(
    { path: { ingressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockIngressGetIngress(
    { path: { ingressId: "*" } },
    { status: 200, content: demoIngress },
  );
};

import { SftpUserApiData, SshUserApiData } from "../../model/access/types";
import { demoDate, demoPublicKey } from "../staticDemoData";

export const demoSshUser: SshUserApiData = {
  id: "1",
  createdAt: demoDate,
  authUpdatedAt: demoDate,
  active: true,
  description: "Demo Ssh User",
  hasPassword: true,
  projectId: "1",
  publicKeys: [demoPublicKey],
  userName: "ssh-123456",
};

export const demoSftpUser: SftpUserApiData = {
  id: "1",
  createdAt: demoDate,
  authUpdatedAt: demoDate,
  active: true,
  description: "Demo Sftp User",
  accessLevel: "full",
  directories: ["/"],
  hasPassword: true,
  projectId: "1",
  publicKeys: [demoPublicKey],
  userName: "sftp-123456",
};

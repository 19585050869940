import invariant from "invariant";
import { MittwaldApi } from "../../api/Mittwald";

export type ProjectRoleName =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_ProjectRoles;

const projectRoles: Record<ProjectRoleName, true> = {
  owner: true,
  external: true,
  emailadmin: true,
  notset: true,
};

export const allProjectRoles = Object.keys(projectRoles) as ProjectRoleName[];

export class ProjectRole {
  public readonly name: ProjectRoleName;

  public constructor(name: ProjectRoleName) {
    invariant(allProjectRoles.includes(name), `Unknown project role: ${name}`);
    this.name = name;
  }

  public is(name: ProjectRoleName): boolean {
    return this.name === name;
  }
}

export default ProjectRole;

import { MittwaldApi } from "../../api/Mittwald";
import { AppInstallation, InstallSystemSoftwareInput } from "./AppInstallation";
import SystemSoftware from "./SystemSoftware";
import SystemSoftwareVersion from "./SystemSoftwareVersion";
import SystemSoftwareVersionList from "./SystemSoftwareVersionList";

export type InstalledSystemSoftwareApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_InstalledSystemSoftware;

export interface UpdateSystemSoftwareVersionInputs {
  systemSoftwareVersionId: string;
}

export class InstalledSystemSoftware {
  public readonly data: InstalledSystemSoftwareApiData;
  public readonly appInstallation: AppInstallation;
  public readonly isUpdating: boolean;
  public readonly isInstalling: boolean;

  private constructor(
    data: InstalledSystemSoftwareApiData,
    appInstallation: AppInstallation,
  ) {
    this.data = Object.freeze(data);
    this.appInstallation = appInstallation;
    this.isUpdating =
      !!this.data.systemSoftwareVersion.current &&
      this.data.systemSoftwareVersion.current !==
        this.data.systemSoftwareVersion.desired;
    this.isInstalling = !this.data.systemSoftwareVersion.current;
  }

  public static fromApiData(
    data: InstalledSystemSoftwareApiData,
    appInstallation: AppInstallation,
  ): InstalledSystemSoftware {
    return new InstalledSystemSoftware(data, appInstallation);
  }

  public useSoftware(): SystemSoftware {
    return SystemSoftware.useLoadById(this.data.systemSoftwareId);
  }

  public useIsRequired(): boolean {
    return !!this.appInstallation
      .useVersion()
      .data.systemSoftwareDependencies?.find(
        (d) => d.systemSoftwareId === this.data.systemSoftwareId,
      );
  }

  public useVersion(): SystemSoftwareVersion {
    const version = this.data.systemSoftwareVersion.desired;
    const software = this.useSoftware();
    return SystemSoftwareVersion.useLoadById(
      software.name,
      software.id,
      version,
    );
  }

  public async updateVersion(
    values: UpdateSystemSoftwareVersionInputs,
  ): Promise<void> {
    await this.appInstallation.update({
      systemSoftware: {
        [this.data.systemSoftwareId]: {
          systemSoftwareVersion: values.systemSoftwareVersionId,
        },
      },
    });
  }

  public async delete(): Promise<void> {
    await this.appInstallation.update({
      systemSoftware: { [this.data.systemSoftwareId]: {} },
    });
  }

  public useVersionsInRange(): SystemSoftwareVersionList {
    return this.useSoftware().useVersionsInRange(
      this.appInstallation.useVersion(),
    );
  }

  public useUpdateAvailable(): boolean {
    const currentVersion = this.useVersion().data.internalVersion;
    const versionsInRange = this.useVersionsInRange();

    return (
      versionsInRange
        .useItems()
        .filter((v) => v.data.internalVersion !== currentVersion).length > 0
    );
  }

  public static async install(
    values: InstallSystemSoftwareInput,
    appInstallation: AppInstallation,
  ): Promise<void> {
    await appInstallation.update({
      systemSoftware: {
        [values.systemSoftwareId]: {
          systemSoftwareVersion: values.systemSoftwareVersionId,
          updatePolicy: "patchLevel",
        },
      },
    });
  }

  public getName(systemSoftwares: SystemSoftware[]): string {
    return (
      systemSoftwares.find((s) => this.data.systemSoftwareId === s.id)?.name ??
      ""
    );
  }
}

export default InstalledSystemSoftware;

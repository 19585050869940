import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Domain from "./Domain";

export class DomainModelRelation extends ModelRelation {
  public static type = new ModelRelationType("domain", "domain", iconDomain);

  public constructor(domainId: string) {
    super(domainId, DomainModelRelation.type);
  }

  public useDomain(): Domain {
    return Domain.useLoadById(this.id);
  }

  public useOptionalDomain(): Domain | undefined {
    return Domain.useTryLoadById(this.id);
  }
}

import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import React, { FC } from "react";
import FeatureSwitch from "../../../../../components/FeatureSwitch";
import { ModelActionBuilder } from "../../../../../model/actions/ModelActionBuilder";
import Customer from "../../../../../model/customer/Customer";
import CustomerContact from "../../../../../model/customer/CustomerContact";
import {
  InvoiceSettings,
  InvoiceSettingsInputs,
} from "../../../../../model/customer/InvoiceSettings";
import InvoiceSettingsUI from "../../../../../model/ui/customer/InvoiceSettingsUI";
import { CustomerBankruptBanner } from "../../../components/CustomerBankruptBanner/CustomerBankruptBanner";
import { EmailAddressInput } from "../../../components/EmailAddressInput";
import { ContactFormInputs } from "../../contractPartner/components/ContactFormInputs";
import PaymentMethodSection from "./PaymentMethodSection";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout";

interface PaymentSettingsProps {
  contact: CustomerContact;
  isDisabled?: boolean;
}

export type InvoiceSettingsInputsWithStringInvoicePeriod = Omit<
  InvoiceSettingsInputs,
  "invoicePeriod"
> & {
  invoicePeriod: string;
  otherRecipient?: boolean;
  additionalEmailRecipientActive: boolean;
};

export const getFormDefaultValues = (
  invoiceSettings?: InvoiceSettings,
): InvoiceSettingsInputsWithStringInvoicePeriod => {
  const iban =
    invoiceSettings?.data.paymentSettings?.method === "debit"
      ? invoiceSettings.data.paymentSettings.iban
      : invoiceSettings?.data.lastBankingInformation?.iban ?? "";

  const splitIban = iban.match(/.{1,4}/g);

  const isAdditionalEmailRecipientDefined = !!(
    invoiceSettings?.data.additionalEmailRecipients &&
    invoiceSettings.data.additionalEmailRecipients.length > 0
  );

  return {
    paymentSettings: {
      method: invoiceSettings?.data.paymentSettings?.method ?? "invoice",
      accountHolder:
        invoiceSettings?.data.paymentSettings?.method === "debit"
          ? invoiceSettings.data.paymentSettings.accountHolder
          : invoiceSettings?.data.lastBankingInformation?.accountHolder ?? "",
      bic:
        invoiceSettings?.data.paymentSettings?.method === "debit" &&
        invoiceSettings.data.paymentSettings.bic
          ? invoiceSettings.data.paymentSettings.bic
          : invoiceSettings?.data.lastBankingInformation?.bic ?? "",
      iban: splitIban?.join(" ") ?? "",
      confirmDebit: invoiceSettings?.data.paymentSettings?.method === "debit",
    },
    recipient: {
      address: {
        city: invoiceSettings?.data.recipient?.address.city ?? "",
        countryCode: invoiceSettings?.data.recipient?.address.countryCode ?? "",
        houseNumber: invoiceSettings?.data.recipient?.address.houseNumber ?? "",
        street: invoiceSettings?.data.recipient?.address.street ?? "",
        zip: invoiceSettings?.data.recipient?.address.zip ?? "",
      },
      firstName: invoiceSettings?.data.recipient?.firstName ?? "",
      lastName: invoiceSettings?.data.recipient?.lastName ?? "",
      salutation: invoiceSettings?.data.recipient?.salutation ?? "other",
      emailAddress: invoiceSettings?.data.recipient?.emailAddress ?? "",
      phoneNumber: invoiceSettings?.data.recipient?.phoneNumbers?.[0] ?? "",
      company: invoiceSettings?.data.recipient?.company ?? "",
    },
    invoicePeriod: invoiceSettings?.data.invoicePeriod?.toString() ?? "1",
    invoiceRecipient: invoiceSettings?.data.recipientSameAsOwner
      ? "contractPartner"
      : "other",
    additionalEmailRecipient:
      isAdditionalEmailRecipientDefined &&
      invoiceSettings.data.additionalEmailRecipients
        ? invoiceSettings.data.additionalEmailRecipients[0]
        : undefined,
    additionalEmailRecipientActive: isAdditionalEmailRecipientDefined,
  };
};

export const PaymentSettingsPage: FC<PaymentSettingsProps> = (props) => {
  const { contact, isDisabled } = props;

  const customer = Customer.useLoadByPathParam();
  const invoiceSettings = customer.useInvoiceSettings();
  const access = ModelActionBuilder.build(invoiceSettings, "update").hasAccess;

  const updateForm = useForm<InvoiceSettingsInputsWithStringInvoicePeriod>({
    defaultValues: getFormDefaultValues(invoiceSettings),
    disabled: !access,

    onSubmit: async (values) => {
      const intParsedValues: InvoiceSettingsInputs = {
        paymentSettings: values.paymentSettings,
        invoiceRecipient: values.invoiceRecipient,
        recipient: values.recipient,
        invoicePeriod: parseInt(values.invoicePeriod),
        additionalEmailRecipient: values.additionalEmailRecipientActive
          ? values.additionalEmailRecipient
          : undefined,
      };

      await InvoiceSettings.update(intParsedValues, customer.id);
    },
  });

  const [watchedInvoiceRecipient] = updateForm.watch(["invoiceRecipient"]);
  const [additionalEmailRecipientActive] = updateForm.watch([
    "additionalEmailRecipientActive",
  ]);
  const isBankrupt = customer.useIsBankrupt();

  return (
    <Page form={updateForm}>
      <Section.Item>{isBankrupt && <CustomerBankruptBanner />}</Section.Item>
      <Section.Item headline="invoiceQuantity">
        <Text i18n="label" />
        <SelectBox
          disabled={isDisabled}
          name="invoicePeriod"
          options={InvoiceSettingsUI.invoicePeriodOptions}
        />
      </Section.Item>
      <Section.Layout>
        <PaymentMethodSection customer={customer} isDisabled={isDisabled} />
        <Section.Item headline="invoiceRecipient">
          <SelectBox
            disabled={isDisabled}
            name="invoiceRecipient"
            options={InvoiceSettingsUI.invoiceRecipientOptions}
          />

          {watchedInvoiceRecipient === "other" && (
            <Section.Item>
              <ContactFormInputs isDisabled={isDisabled} type="recipient" />
            </Section.Item>
          )}
          {watchedInvoiceRecipient === "contractPartner" && (
            <Section.Item>
              {contact.company && (
                <StaticInformation
                  label="company"
                  text={{ text: contact.company }}
                />
              )}

              <ColumnLayout medium={[1, 1]}>
                {contact.data.firstName && contact.data.lastName && (
                  <StaticInformation
                    label="name"
                    text={{
                      text: `${contact.data.firstName} ${contact.data.lastName}`,
                    }}
                  />
                )}
                <StaticInformation
                  label="address"
                  text={{
                    text: contact.address.toString(),
                  }}
                />

                <StaticInformation
                  label="email"
                  text={{ text: contact.data.emailAddress }}
                />
                <StaticInformation
                  label="phoneNumber"
                  text={{ text: contact.phoneNumber?.formatted() }}
                />
              </ColumnLayout>
            </Section.Item>
          )}
        </Section.Item>
        <Section.Item
          headline="additionalInvoiceRecipient"
          headlineContent={
            <FeatureSwitch
              disabled={isDisabled}
              name="additionalEmailRecipientActive"
            />
          }
        >
          {additionalEmailRecipientActive && (
            <ColumnLayout medium={[1, 1]}>
              <EmailAddressInput
                disabled={isDisabled}
                name="additionalEmailRecipient"
                rules={{ required: true }}
              />
            </ColumnLayout>
          )}
        </Section.Item>
      </Section.Layout>
    </Page>
  );
};

export default PaymentSettingsPage;

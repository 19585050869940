import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

export type MySqlUserApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Database_MySqlUser;

export interface NewDatabaseUserInputs {
  description: string;
  password: string;
  fullAccess: boolean;
  externalAccess: boolean;
}

export interface UpdatePasswordInputs {
  password: string;
}

export interface UpdateInputs {
  description?: string;
  fullAccess?: boolean;
  externalAccess?: boolean;
}

export class MySqlUser {
  public readonly id: string;
  public readonly data: MySqlUserApiData;
  public readonly isMainUser: boolean;
  public readonly hasFullAccess: boolean;
  public readonly hasExternalAccess: boolean;

  private constructor(data: MySqlUserApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.isMainUser = data.mainUser;
    this.hasFullAccess = data.accessLevel === "full";
    this.hasExternalAccess = data.externalAccess;
  }

  public static fromApiData(data: MySqlUserApiData): MySqlUser {
    return new MySqlUser(data);
  }

  public static useLoadById(id: string): MySqlUser {
    const data = mittwaldApi.databaseGetMysqlUser
      .getResource({
        path: { mysqlUserId: id },
      })
      .useWatchData();

    return MySqlUser.fromApiData(data);
  }

  public static useLoadByPathParam(): MySqlUser {
    const { databaseUserId } = usePathParams("databaseUserId");
    return MySqlUser.useLoadById(databaseUserId);
  }

  public static async createNew(
    values: NewDatabaseUserInputs,
    databaseId: string,
  ): Promise<void> {
    const response = await mittwaldApi.databaseCreateMysqlUser.request({
      requestBody: {
        databaseId,
        password: values.password,
        accessLevel: values.fullAccess ? "full" : "readonly",
        description: values.description,
        externalAccess: values.externalAccess,
      },
      path: {
        mysqlDatabaseId: databaseId,
      },
    });

    assertStatus(response, 201);
  }

  public async updatePassword(values: UpdatePasswordInputs): Promise<void> {
    const response = await mittwaldApi.databaseUpdateMysqlUserPassword.request({
      path: {
        mysqlUserId: this.id,
      },
      requestBody: {
        password: values.password,
      },
    });

    assertStatus(response, 204);
  }

  public async deleteDatabaseUser(): Promise<void> {
    const response = await mittwaldApi.databaseDeleteMysqlUser.request({
      path: { mysqlUserId: this.id },
    });

    assertStatus(response, 204);
  }

  public async update(values: UpdateInputs): Promise<void> {
    const response = await mittwaldApi.databaseUpdateMysqlUser.request({
      requestBody: {
        accessIpMask: "",
        description: values.description ?? this.data.description ?? "",
        accessLevel:
          values.fullAccess === true
            ? "full"
            : values.fullAccess === false
              ? "readonly"
              : this.data.accessLevel,
        externalAccess: values.externalAccess,
      },

      path: {
        mysqlUserId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public async getPhpMyAdminUrl(): Promise<string> {
    const response =
      await mittwaldApi.databaseGetMysqlUserPhpMyAdminUrl.request({
        path: { mysqlUserId: this.id },
      });

    assertStatus(response, 200);

    return response.content.url;
  }

  public checkIsMainUser(): boolean {
    return this.isMainUser;
  }
}

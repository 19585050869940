import { getAvatarImageWidth } from "@mittwald/flow-components/dist/components/Avatar/lib";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { InlineError } from "@mittwald/flow-components/dist/components/InlineError";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import React, { FC } from "react";
import { iconEnvelopeOpenDollar } from "@mittwald/flow-icons/dist/envelopeOpenDollar";
import CustomerInfoBadge from "../../../../customer/components/CustomerInfoBadge";
import PaymentData from "../../../../customer/components/PaymentData";
import Customer from "../../../../../../model/customer/Customer";

export interface CustomerInfoProps {
  customerId: string;
  isWidget?: boolean;
  showContact?: boolean;
}

export const CustomerInfoBox: FC<CustomerInfoProps> = (props) => {
  const { customerId, isWidget, showContact = true } = props;

  const customer = Customer.useLoadById(customerId);

  const canViewPaymentData = ["owner", "accountant"].includes(
    `${customer.myRole}`,
  );

  const iconWidth = getAvatarImageWidth("m");

  return (
    <TranslationProvider name="customerInfo" type="section">
      <ColumnLayout medium={isWidget ? [1, 1] : undefined}>
        <Box _itemGap="s">
          <UniversalBoundary
            loadingView={
              <Text>
                <Skeleton _width={200} count={6} />
              </Text>
            }
          >
            <CustomerInfoBadge
              customerId={customerId}
              showContact={showContact}
            />
          </UniversalBoundary>
        </Box>
        <Box _itemGap="s" _mt="m">
          <Box _flexDirection="row" _itemGap="m">
            <Box
              _flexDirection="row"
              _justifyContent="center"
              _width={iconWidth}
            >
              <Icon
                _color="brand-primary-dark"
                _size="m"
                icon={iconEnvelopeOpenDollar}
              />
            </Box>
            {!canViewPaymentData ? (
              <UniversalBoundary
                errorView={InlineError}
                loadingView={
                  <Text>
                    <Skeleton _width={200} count={6} />
                  </Text>
                }
              >
                <Box _flexDirection="column">
                  <Text i18n="section.overviewStep.payment" strong />
                  <Text i18n="section.overviewStep.specifiedByCustomerOwner" />
                </Box>
              </UniversalBoundary>
            ) : (
              <UniversalBoundary
                errorView={InlineError}
                loadingView={
                  <Text>
                    <Skeleton _width={200} count={6} />
                  </Text>
                }
              >
                <PaymentData
                  customerId={customerId}
                  showDetails={showContact}
                />
              </UniversalBoundary>
            )}
          </Box>
        </Box>
      </ColumnLayout>
    </TranslationProvider>
  );
};

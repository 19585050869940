import { MittwaldApi } from "../../api/Mittwald";

export type CustomerAddressApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Commons_Address;

export interface CustomerAddressUpdateInputs {
  city: string;
  countryCode: string;
  houseNumber: string;
  street: string;
  zip: string;
  addressPrefix?: string;
}

export class CustomerAddress {
  public readonly data: CustomerAddressApiData;

  private constructor(data: CustomerAddressApiData) {
    this.data = Object.freeze(data);
  }

  public static fromApiData(data: CustomerAddressApiData): CustomerAddress {
    return new CustomerAddress(data);
  }

  public toString(): string {
    return `${this.data.street} ${this.data.houseNumber}, ${this.data.zip} ${this.data.city}`;
  }
}

export default CustomerAddress;

import { faRocket } from "@fortawesome/pro-regular-svg-icons/faRocket";
import { OrderResourceSelectBoxOptions } from "@mittwald/flow-components/dist/components/OrderResourceSelect/components";
import { SpaceServerArticle } from "../../article";
import ArticleUI from "./ArticleUI";

export class SpaceServerArticleUI {
  public static readonly icon = faRocket;

  public static buildSelectBoxOptions(
    articles: SpaceServerArticle[],
    promoteArticles = false,
    currentArticle?: SpaceServerArticle,
  ): OrderResourceSelectBoxOptions {
    return articles
      .sort((l, r) => l.machineType.compare(r.machineType))
      .map((article) => ({
        value: article.id,
        marked: currentArticle?.isEqual(article),
        title: { text: article.machineType.getDescription("cpu") },
        description: { text: article.machineType.getDescription("memory") },
        highlightText:
          promoteArticles && ArticleUI.of(article).isPromoted
            ? "recommended"
            : undefined,
        additionalInfo: {
          i18n: {
            id: "recommendedFor",
            values: {
              projectCount: article.attributes.find(
                (attr) => attr.key === "recommended_projects",
              )?.value,
            },
          },
          small: true,
        },
      }));
  }
}

export default SpaceServerArticleUI;

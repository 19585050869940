import { iconProject } from "@mittwald/flow-icons/dist/project";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import { ProjectBase, Project } from "./";
import ModelRelation from "../misc/modelRelation/ModelRelation";

export class ProjectModelRelation extends ModelRelation {
  public static type = new ModelRelationType("project", "project", iconProject);

  public constructor(projectId: string) {
    super(projectId, ProjectModelRelation.type);
  }

  public useProject(): ProjectBase {
    return Project.useLoadById(this.id);
  }

  public useOptionalProject(): ProjectBase | undefined {
    return Project.useTryLoadById(this.id);
  }
}

export default ProjectModelRelation;

import { InvoiceSettings } from "../../customer/InvoiceSettings";

interface PaymentMethodOptions {
  title: string;
  value: string;
  disabled?: boolean;
}

export class InvoiceSettingsUI {
  public static readonly paymentMethodOptions: PaymentMethodOptions[] = [
    { title: "invoice", value: "invoice" },
    { title: "debit", value: "debit" },
  ];

  public static readonly invoicingPeriods = [12, 6, 3, 1];

  public static readonly invoicePeriodOptions = [
    { title: "once", value: "12" },
    { title: "twice", value: "6" },
    { title: "fourTimes", value: "3" },
    { title: "twelveTimes", value: "1" },
  ];

  public static readonly invoiceRecipientOptions = [
    { title: "contractPartner", value: "contractPartner" },
    { title: "other", value: "other" },
  ];

  public readonly invoiceSettings: InvoiceSettings;

  private constructor(invoiceSettings: InvoiceSettings) {
    this.invoiceSettings = invoiceSettings;
  }

  public static of(invoiceSettings: InvoiceSettings): InvoiceSettingsUI {
    return new InvoiceSettingsUI(invoiceSettings);
  }

  public getPaymentMethodOptions(): PaymentMethodOptions[] {
    const isStatusReturnDebitNote =
      this.invoiceSettings.data.status &&
      this.invoiceSettings.data.status.some(
        (invoiceSettingStatus) =>
          invoiceSettingStatus.type === "returnDebitNote",
      );

    return isStatusReturnDebitNote
      ? [
          { title: "invoice", value: "invoice" },
          { title: "debit", value: "debit", disabled: true },
        ]
      : InvoiceSettingsUI.paymentMethodOptions;
  }
}

export default InvoiceSettingsUI;

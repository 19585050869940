import { MittwaldApi } from "../../api/Mittwald";

export type SalutationApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Commons_Salutation;

const salutations: Record<SalutationApiSchema, true> = {
  ms: true,
  mr: true,
  other: true,
};

export const allSalutations = Object.keys(salutations) as SalutationApiSchema[];

export class Salutation {
  public readonly value: SalutationApiSchema;

  public constructor(salutation: SalutationApiSchema) {
    this.value = salutation;
  }
}

export default Salutation;

import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import MetricsQueryResponse from "../metrics/MetricsQueryResponse";
import MetricsTimeRange from "../metrics/MetricsTimeRange";
import { ServerMetricsDataObject } from "../metrics/types";
import { Metrics, MetricsRequestOptions } from "../misc/Metrics";
import { ProjectList } from "../project";
import Server from "./Server";

interface StorageMetricsCategories {
  dataFn: MetricsQueryResponse;
  name: I18nDefinition;
  linkName?: string;
}

export class ServerMetrics {
  public readonly server: Server;

  private constructor(server: Server) {
    this.server = server;
  }

  public static of(server: Server): ServerMetrics {
    return new ServerMetrics(server);
  }

  public useCpuUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `
      (SUM (cloudhosting_databasesetmember_usage_cpu_seconds_total_rate:5m{placement_group="${shortId}"} or vector (0)) + 
      SUM (cloudhosting_project_usage_cpu_seconds_total_rate:5m{placement_group="${shortId}"})) / 
      SUM (cloudhosting_placementgroup_limits_cpu_seconds:5m{placement_group="${shortId}"})
      `,
      options,
    );
  }

  public useProjectCpuUsage(
    options: MetricsRequestOptions,
  ): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `SUM (cloudhosting_project_usage_cpu_seconds_total_rate:5m{placement_group="${shortId}"} OR vector(0)) / 
      SUM (cloudhosting_placementgroup_limits_cpu_seconds:5m{placement_group="${shortId}"})`,
      options,
    );
  }

  public useMemoryUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `
      (SUM (cloudhosting_databasesetmember_usage_memory_bytes:5m:max{placement_group="${shortId}"} or vector (0)) + 
      SUM (cloudhosting_project_usage_memory_bytes:5m:max{placement_group="${shortId}"})) /
      SUM (cloudhosting_placementgroup_limits_memory_bytes:5m{placement_group="${shortId}"})
      `,
      options,
    );
  }

  public useProjectMemoryUsage(
    options: MetricsRequestOptions,
  ): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `SUM (cloudhosting_project_usage_memory_bytes:5m:max{placement_group="${shortId}"} OR vector(0)) / 
      SUM (cloudhosting_placementgroup_limits_memory_bytes:5m{placement_group="${shortId}"})`,
      options,
    );
  }

  public useDbCpuUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `SUM(cloudhosting_databasesetmember_usage_cpu_seconds_total_rate:5m{project_group="${shortId}"} OR vector(0)) by (database_type, database_version)`,
      options,
    );
  }

  public useDbMemoryUsage(
    options: MetricsRequestOptions,
  ): MetricsQueryResponse {
    const shortId = this.server.data.shortId;

    return Metrics.executeRequest(
      `SUM(cloudhosting_databasesetmember_usage_memory_bytes:5m:max{project_group="${shortId}"} OR vector(0)) by (database_type, database_version)`,
      options,
    );
  }

  public useServerCpuMetrics(range: MetricsTimeRange): ServerMetricsDataObject {
    const projectUsage = this.useProjectCpuUsage({
      range,
    });
    const dbUsage = this.useDbCpuUsage({ range });
    const summaryUsage = this.useCpuUsage({ range });
    const limit = this.useCpuLimit().getFirstValue() ?? 0;

    return {
      project: projectUsage.getDataPoints(),
      databases: dbUsage.getMultiFrameDataPoints([
        "database_type",
        "database_version",
      ]),
      summary: summaryUsage.getDataPoints(),
      limit,
    };
  }

  public useServerMemoryMetrics(
    range: MetricsTimeRange,
  ): ServerMetricsDataObject {
    const projectUsage = this.useProjectMemoryUsage({
      range,
    });
    const dbUsage = this.useDbMemoryUsage({ range });
    const summaryUsage = this.useMemoryUsage({ range });
    const limit = this.useMemoryLimit().getFirstValue() ?? 0;

    return {
      project: projectUsage.getDataPoints(),
      databases: dbUsage.getMultiFrameDataPoints([
        "database_type",
        "database_version",
      ]),
      summary: summaryUsage.getDataPoints(),
      limit,
    };
  }

  public useProjectWebStorage(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM(cloudhosting_project_usage_web_storage_bytes:5m:max{placement_group="${shortId}"}) by (project)`,
    );
  }

  public useProjectDbStorage(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM(cloudhosting_project_usage_database_storage_bytes:5m:max{placement_group="${shortId}"}) by (project)`,
    );
  }

  public useProjectMailStorage(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM(project_usage_mail_storage_bytes{placement_group="${shortId}"}) by (project)`,
    );
  }

  public useProjectBackupStorage(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM(cloudhosting_projectbackup_usage_repository_total_size:1m{placement_group="${shortId}"}) by (project)`,
    );
  }

  public useProjectUsages(): StorageMetricsCategories[] {
    const projects = ProjectList.useLoadByServerId(this.server.id).useItems();
    return projects.map((project) => {
      return {
        name: { text: project.description },
        dataFn: Metrics.executeRequest(
          `
(SUM(cloudhosting_project_usage_database_storage_bytes:5m:max{project="${project.shortId}"}) or vector (0)) +
(SUM(cloudhosting_project_usage_web_storage_bytes:5m:max{project="${project.shortId}"}) or vector (0)) +
(SUM(project_usage_mail_storage_bytes{project="${project.shortId}"}) or vector (0))      
`,
        ),
        linkName: "",
      };
    });
  }

  public useCpuLimit(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM (cloudhosting_placementgroup_limits_cpu_seconds:5m{placement_group="${shortId}"})`,
    );
  }

  public useMemoryLimit(): MetricsQueryResponse {
    const shortId = this.server.data.shortId;
    return Metrics.executeRequest(
      `SUM (cloudhosting_placementgroup_limits_memory_bytes:5m{placement_group="${shortId}"})`,
    );
  }

  public useStorageLimit(): MetricsQueryResponse {
    return Metrics.executeRequest(
      `placementgroup_limits_storage_bytes{placement_group="${this.server.data.shortId}"}`,
    );
  }
}

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import UnexpectedResponseError from "@mittwald/api-client/dist/UnexpectedResponseError";
import { retryRunnable } from "@mittwald/awesome-node-utils/promises/retry";
import { constantRetryBackoff } from "@mittwald/awesome-node-utils/promises/retry_backoff";
import { AnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import loginStore from "../../store/login";
import registerStore from "../../store/register";
import store from "../../store/register";
import sessionStore from "../../store/session";
import { SalutationApiSchema } from "../misc/Salutation";

export interface LoginInputs {
  email: string;
  password: string;
}

export interface ChangePasswordInputs {
  multiFactorCode: string;
  oldPassword: string;
  newPassword: string;
}

export interface UpdateEmailAddressInputs {
  email: string;
}

export interface ResendEmailInputs {
  email: string;
}

export interface RegistrationInputs {
  person: {
    title: SalutationApiSchema;
    firstName: string;
    lastName: string;
  };
  email: string;
  password: string;
}

export interface VerifyRegistrationInputs {
  token: string;
}

export interface VerifyMfaInputs {
  multiFactorCode: string;
}

export interface ResetPasswordInputs {
  email: string;
}

export interface ConfirmPasswordResetInputs {
  userId: string;
  token: string;
  password: string;
}

export type AccessTokenRetrievalKey =
  MittwaldApi.Paths.V2_Extension_Instances_ExtensionInstanceId_Actions_Create_Access_Token_Retrieval_Key.Post.Responses.$200.Content.Application_Json;

export type AuthenticationResult =
  MittwaldApi.Paths.V2_Authenticate.Post.Responses.$200.Content.Application_Json;

export class Signup {
  public static async login(
    values: LoginInputs,
    rejectionAnimation: AnimationController,
    appRedirect?: CallableFunction,
    mfaRedirect?: CallableFunction,
  ): Promise<void | false> {
    const { email, password } = values;

    const result = await mittwaldApi.userAuthenticate.request({
      requestBody: {
        email,
        password,
      },
    });

    switch (result.status) {
      case 200:
        sessionStore.login(result.content);
        appRedirect && appRedirect();
        break;
      case 202:
        loginStore.setFirstFactorInformation(email, password);
        sessionStore.setMfaEnabled(true);
        mfaRedirect && mfaRedirect();
        break;
      case 400:
      case 401:
        rejectionAnimation.start();
        return false;
      default:
        throw new UnexpectedResponseError(result);
    }
  }

  public static async register(
    values: RegistrationInputs,
    isEmailInvite?: boolean,
  ): Promise<string | "invalid_string"> {
    const result = await mittwaldApi.userRegister.request({
      requestBody: {
        password: values.password,
        email: values.email,
        person: {
          title: values.person.title,
          firstName: values.person.firstName,
          lastName: values.person.lastName,
        },
      },
    });

    if (
      result.status === 400 &&
      result.content.message?.includes("email must match format")
    ) {
      return "invalid_string";
    }

    assertStatus(result, 201);

    store.setProfileInformation(values);
    store.setUserId(result.content.userId);
    store.setIsEmailInvite(!!isEmailInvite);

    return result.content.userId;
  }

  public static async verifyRegistration(
    values: VerifyRegistrationInputs,
    email: string,
    password: string,
    rejectionAnimation: AnimationController,
    userId?: string,
    appRedirect?: CallableFunction,
  ): Promise<void | number> {
    if (!userId) {
      throw new Error("userId must be set");
    }

    const autoLogin = async (): Promise<void> => {
      const authenticationResult = await mittwaldApi.userAuthenticate.request({
        requestBody: {
          email,
          password,
        },
      });
      if (authenticationResult.status !== 200) {
        throw authenticationResult;
      }
      sessionStore.login(authenticationResult.content);
      appRedirect && appRedirect();
    };

    const result = await mittwaldApi.userVerifyRegistration.request({
      requestBody: {
        email,
        token: values.token,
        userId,
      },
    });

    if (result.status === 400) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(result);
    }

    assertStatus(result, 200);

    /**
     * Wait for readmodel
     */
    await retryRunnable(autoLogin, {
      retries: 5,
      getRetryBackoff: constantRetryBackoff(2),
    });

    registerStore.clearProfileInformation();
    return;
  }

  public static async verifyMfa(
    values: VerifyMfaInputs,
    email: string,
    password: string,
    rejectionAnimation: AnimationController,
  ): Promise<void | false> {
    const result = await mittwaldApi.userAuthenticateMfa.request(
      {
        requestBody: {
          multiFactorCode: values.multiFactorCode,
          email,
          password,
        },
      },
      {
        timeout: 30 * 1000,
      },
    );

    if (result.status !== 200) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(result);
    }

    sessionStore.login(result.content);
    sessionStore.setMfaEnabled(true);
    loginStore.clearFirstFactorInformation();
  }

  public static confirmMfa = async (
    multiFactorCode: string,
    rejectionAnimation: AnimationController,
  ): Promise<string[]> => {
    const res = await mittwaldApi.userConfirmMfa.request(
      {
        requestBody: { multiFactorCode },
      },
      {
        timeout: 30 * 1000,
      },
    );

    if (res.status !== 200) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(res);
    }

    return res.content.recoveryCodesList;
  };

  public static removeMfa = async (
    multiFactorCode: string,
    rejectionAnimation: AnimationController,
  ): Promise<void> => {
    const res = await mittwaldApi.userDisableMfa.request(
      {
        requestBody: { multiFactorCode },
      },
      {
        timeout: 30 * 1000,
      },
    );

    if (res.status !== 204) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(res);
    }
  };

  public static resetRecoveryCodes = async (
    multiFactorCode: string,
    rejectionAnimation: AnimationController,
  ): Promise<string[]> => {
    const res = await mittwaldApi.userResetRecoverycodes.request({
      requestBody: { multiFactorCode: multiFactorCode },
    });

    if (res.status !== 200) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(res);
    }

    return res.content.recoveryCodesList;
  };

  public static verifyEmail = async (
    email: string,
    token: string,
    rejectionAnimation: AnimationController,
  ): Promise<void> => {
    const response = await mittwaldApi.userVerifyEmail.request({
      requestBody: {
        email,
        token,
      },
    });

    if (response.status !== 204) {
      rejectionAnimation.start();
      throw new UnexpectedResponseError(response);
    }
  };

  public static useUserEmailAddress(): string {
    return (
      mittwaldApi.userGetOwnAccount
        .getResource({ path: { userId: "self" } })
        .useWatchData().email ??
      mittwaldApi.userGetOwnEmail.getResource({}).useWatchData().email
    );
  }

  public static usePasswordUpdatedAt(): string {
    return mittwaldApi.userGetPasswordUpdatedAt.getResource({}).useWatchData()
      .passwordUpdatedAt;
  }

  public static useMfaConfirmed(): boolean {
    return mittwaldApi.userGetMfaStatus.getResource({}).useWatchData()
      .confirmed;
  }

  public static async updateEmailAddress(
    values: UpdateEmailAddressInputs,
  ): Promise<void> {
    const response = await mittwaldApi.userChangeEmail.request({
      requestBody: {
        email: values.email,
      },
    });

    assertStatus(response, 204);
  }

  public static async resendEmail(
    values: ResendEmailInputs,
    userId: string = "",
  ): Promise<void> {
    const response = await mittwaldApi.userResendVerificationEmail.request({
      requestBody: {
        userId,
        email: values.email,
      },
    });

    assertStatus(response, 204);
  }

  public static async changePassword(
    values: ChangePasswordInputs,
  ): Promise<AuthenticationResult | 202> {
    const response = await mittwaldApi.userChangePassword.request(
      {
        requestBody: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          multiFactorCode: values.multiFactorCode || undefined,
        },
      },
      {
        timeout: 30 * 1000,
      },
    );

    if (response.status === 202) {
      return 202;
    }

    assertStatus(response, 200);

    // TODO: Remove when API-Schema is up-to-date
    return response.content as AuthenticationResult;
  }
  public static async resetPassword(
    values: ResetPasswordInputs,
  ): Promise<void> {
    const result = await mittwaldApi.userInitPasswordReset.request({
      requestBody: {
        email: values.email,
      },
    });

    assertStatus(result, 201);
  }

  public static async confirmResetPassword(
    values: ConfirmPasswordResetInputs,
    userId: string,
    token: string,
  ): Promise<void> {
    const result = await mittwaldApi.userConfirmPasswordReset.request({
      requestBody: {
        userId,
        token,
        password: values.password,
      },
    });

    assertStatus(result, 204);
  }

  public static async logout(): Promise<void> {
    const result = await mittwaldApi.userLogout.request({});

    // 401 ~> access token not valid
    if ((result.status as number) !== 401) {
      assertStatus(result, 204);
    }

    sessionStore.logout();
  }

  public static async getAccessTokenRetrievalKey(
    extensionInstanceId: string,
  ): Promise<AccessTokenRetrievalKey> {
    const result = await mittwaldApi.extensionCreateRetrievalKey.request({
      path: { extensionInstanceId },
    });
    assertStatus(result, 200);

    return result.content;
  }

  public static useAccessTokenRetrievalKey(
    extensionInstanceId: string,
  ): AccessTokenRetrievalKey {
    return mittwaldApi.extensionCreateRetrievalKey
      .getResource({
        path: {
          extensionInstanceId,
        },
      })
      .useWatchData();
  }
}

import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoServerPerformance } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockPageinsightsGetStraceData(
    { path: { straceId: "*", projectId: "*" } },
    { status: 200, content: demoServerPerformance },
  );

  mittwaldApiRequestMocking.mockPageinsightsScheduleStrace(
    { path: { projectId: "*" } },
    { status: 202, content: { id: 1 } },
  );
};

import { AppModelRelation } from "../../app/AppModelRelation";
import { BackupModelRelation } from "../../backup/BackupModelRelation";
import { RegistryModelRelation } from "../../container/RegistryModelRelation";
import CustomerInviteModelRelation from "../../customer/CustomerInviteModelRelation";
import CustomerModelRelation from "../../customer/CustomerModelRelation";
import { DomainModelRelation } from "../../domain/DomainModelRelation";
import { IngressModelRelation } from "../../domain/IngressModelRelation";
import { SSLCertificateModelRelation } from "../../domain/ssl/SSLCertificateModelRelation";
import { InvoiceModelRelation } from "../../invoice/InvoiceModelRelation";
import { EmailAddressModelRelation } from "../../mail/EmailAddressModelRelation";
import ExtensionInstanceModelRelation from "../../marketplace/ExtensionInstanceModelRelation";
import { OrderModelRelation } from "../../order/OrderModelRelation";
import ProjectInviteModelRelation from "../../project/ProjectInviteModelRelation";
import ProjectModelRelation from "../../project/ProjectModelRelation";
import ServerModelRelation from "../../server/ServerModelRelation";
import ConversationModelRelation from "../../support/ConversationModelRelation";
import GenericModelRelation from "./GenericModelRelation";
import { ModelRelation, ModelRelationApiSchema } from "./ModelRelation";
import ModelRelationType from "./ModelRelationType";

export const modelRelationFactory = (
  data: ModelRelationApiSchema,
): ModelRelation => {
  const { id } = data;
  if (SSLCertificateModelRelation.type.matches(data)) {
    return new SSLCertificateModelRelation(id);
  }
  if (ConversationModelRelation.type.matches(data)) {
    return new ConversationModelRelation(id);
  }
  if (ServerModelRelation.type.matches(data)) {
    return new ServerModelRelation(id);
  }
  if (ProjectModelRelation.type.matches(data)) {
    return new ProjectModelRelation(id);
  }
  if (CustomerModelRelation.type.matches(data)) {
    return new CustomerModelRelation(id);
  }
  if (EmailAddressModelRelation.type.matches(data)) {
    return new EmailAddressModelRelation(id);
  }
  if (AppModelRelation.type.matches(data)) {
    return new AppModelRelation(id);
  }
  if (DomainModelRelation.type.matches(data)) {
    return new DomainModelRelation(id);
  }
  if (IngressModelRelation.type.matches(data)) {
    return new IngressModelRelation(id);
  }
  if (ProjectInviteModelRelation.type.matches(data)) {
    return new ProjectInviteModelRelation(id);
  }
  if (CustomerInviteModelRelation.type.matches(data)) {
    return new CustomerInviteModelRelation(id);
  }
  if (BackupModelRelation.type.matches(data)) {
    return new BackupModelRelation(id);
  }
  if (OrderModelRelation.type.matches(data)) {
    return new OrderModelRelation(id);
  }
  if (RegistryModelRelation.type.matches(data)) {
    return new RegistryModelRelation(id);
  }
  if (ExtensionInstanceModelRelation.type.matches(data)) {
    return new ExtensionInstanceModelRelation(id);
  }

  const parentIsMatchingCustomerModel =
    data.parents?.[0] && CustomerModelRelation.type.matches(data.parents[0]!);
  if (
    InvoiceModelRelation.type.matches(data) &&
    parentIsMatchingCustomerModel
  ) {
    return new InvoiceModelRelation(id);
  }

  return new GenericModelRelation(id, ModelRelationType.fromApiData(data));
};

export default modelRelationFactory;

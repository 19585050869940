import { HorizontalSwitchSlider } from "@mittwald/flow-components/dist/components/HorizontalSwitchSlider";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import { ProSpaceArticle } from "../../../../../../model/article";
import useBuildStorageSwitchSliderItems from "../../../../server/hooks/useBuildStorageSwitchSliderItems";

interface Props {
  selectedArticle: ProSpaceArticle;
  isInWidget?: boolean;
}

export const ConfigurationStorageSection: FC<Props> = (props) => {
  const { selectedArticle, isInWidget } = props;

  const options = useBuildStorageSwitchSliderItems(selectedArticle);

  return (
    <Section.Item headline={isInWidget ? "widget.storage" : "storage"}>
      <HorizontalSwitchSlider
        items={options}
        name="storageSizeInBytes"
        showInitialIndicator
        showStepMarks={false}
      />
      <Text i18n="storageDescription" small squished />
    </Section.Item>
  );
};

export default ConfigurationStorageSection;

import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import iconAccessDenied from "@mittwald/flow-icons/dist/accessDenied";
import React, { FC } from "react";
import { SupportPhoneLink } from "../../support/components/SupportPhoneLink";

export const CustomerBankruptBanner: FC = () => {
  return (
    <Banner
      description={{
        id: "customerBankruptDescription",
        values: {
          phoneLink: <SupportPhoneLink freshmen />,
        },
      }}
      headline="customerBankruptHeadline"
      icon={iconAccessDenied}
      warning
    />
  );
};

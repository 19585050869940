import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import invariant from "invariant";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";

export type VolumeApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_VolumeResponse;

export const volumeNameRegExp = /^[a-zA-Z0-9.-]+$/;
export interface CreateVolumeInputs {
  name: string;
}

export class Volume {
  public readonly data: VolumeApiData;
  public readonly id: string;
  public readonly name: string;
  public readonly stackId: string;

  private constructor(data: VolumeApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.name = data.name;
    this.stackId = data.stackId;
  }

  public static fromApiData(data: VolumeApiData): Volume {
    return new Volume(data);
  }

  public static useLoadById(id: string, stackId: string): Volume {
    const volume = mittwaldApi.containerGetVolume
      .getResource({ path: { volumeId: id, stackId } })
      .useWatchData();

    invariant(volume, "Volume not found by id");
    return new Volume(volume);
  }

  public static useTryLoadById(
    id?: string,
    stackId?: string,
  ): Volume | undefined {
    if (!id || !stackId) {
      return;
    }
    const volume = mittwaldApi.containerGetVolume
      .getResource({ path: { volumeId: id, stackId } })
      .useWatchData({ optional: true });
    return volume ? new Volume(volume) : undefined;
  }

  public static useLoadByPathParam(stackId: string): Volume {
    const { volumeId } = usePathParams("volumeId");
    return this.useLoadById(volumeId, stackId);
  }

  public static async createNew(
    values: CreateVolumeInputs,
    stackId: string,
  ): Promise<Volume> {
    const response = await mittwaldApi.containerUpdateStack.request({
      path: { stackId },
      requestBody: { volumes: { [values.name]: { name: values.name } } },
    });

    assertStatus(response, 200);

    const volume = response.content.volumes?.find(
      (v) => v.name === values.name,
    );

    invariant(volume, "Error while creating volume");

    return new Volume(volume);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.containerDeleteVolume.request({
      path: { stackId: this.stackId, volumeId: this.id },
    });

    assertStatus(response, 204);
  }

  public static generateNewVolumeName(initialName: string): string {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return `${initialName}-${result}`;
  }
}

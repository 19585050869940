import {
  ActionTriggerOptions,
  ActionTriggerResult,
  BaseAction,
} from "@mittwald/flow-components/dist/lib/actions/BaseAction";
import {
  ANALYTICS_EVENT,
  AnalyticsEventPayload,
  OfType,
  OfTypeForStatic,
} from "./types";

export class TrackingAction extends BaseAction {
  public readonly identifier?: string;

  public constructor(identifier?: string) {
    super({ successFeedback: "never" });
    this.identifier = identifier;
  }

  public triggerAnalytics(name: string, data: Record<string, any>): void {
    void this.triggerImplementation({
      args: [name, data],
    });
  }

  protected triggerImplementation(
    options: ActionTriggerOptions,
  ): ActionTriggerResult {
    const [name, data] = options.args ?? [];
    window.dispatchEvent(
      new CustomEvent<AnalyticsEventPayload<any>>(ANALYTICS_EVENT, {
        detail: {
          ...data,
          event: name,
          identifier: this.identifier,
        },
      }),
    );
  }
  public ofType<T>(): OfType<T> {
    return this as OfType<T>;
  }
  public static ofType<T>(): OfTypeForStatic<T> {
    return this as OfTypeForStatic<T>;
  }

  public static addEventListener(
    name: string,
    fn: (data: AnalyticsEventPayload<any>) => void,
  ): void {
    window.addEventListener(ANALYTICS_EVENT, (e) => {
      const event = e as CustomEvent<AnalyticsEventPayload<any>>;
      if (event.detail.event === name) {
        fn(event.detail);
      }
    });
  }
}

export default TrackingAction;

import MoneyValue from "../misc/MoneyValue";

export class HostingPrices {
  public readonly total: MoneyValue;
  public readonly storage: MoneyValue;
  public readonly machine: MoneyValue;

  public constructor(machine: MoneyValue, storage: MoneyValue) {
    this.storage = storage;
    this.machine = machine;
    this.total = storage.add(machine);
  }
}

export default HostingPrices;

import { ValueControllerInstance } from "@mittwald/flow-components/dist/hooks/useValueController";
import { DateTime, Duration } from "luxon";
import useIntervalValue from "../../hooks/useIntervalValue";

export class DueDate {
  public readonly value: DateTime;

  public constructor(date: DateTime) {
    this.value = date;
  }

  public static now(): DueDate {
    return new DueDate(DateTime.now());
  }

  public static fromISO(iso: string): DueDate {
    return new DueDate(DateTime.fromISO(iso));
  }

  public useIsOverdue(): ValueControllerInstance<boolean> {
    return useIntervalValue(() => this.isOverdue());
  }

  public static useIsOverdueDry(): ValueControllerInstance<undefined> {
    return useIntervalValue(() => undefined);
  }

  public isOverdue(threshold = Duration.fromObject({ minutes: 10 })): boolean {
    return DateTime.now() > this.value.minus(threshold);
  }

  public valueOf(): number {
    return this.value.valueOf();
  }
}

export default DueDate;

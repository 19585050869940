import {
  ActionOptions,
  ActionTriggerResult,
  BaseAction,
} from "@mittwald/flow-components/dist/lib/actions/BaseAction";
import combine from "@mittwald/flow-lib/dist/access/combine";
import { AccessCheck } from "@mittwald/flow-lib/dist/access/types";
import is from "@sindresorhus/is";
import invariant from "invariant";
import AclChecker from "./acl/AclChecker";
import { Role } from "./acl/Role";

export type ModelMethod<M> = keyof M & string;

export interface ModelActionConfig<
  TModel,
  TMethod extends ModelMethod<TModel>,
> {
  model: TModel;
  method: TMethod;
  roles: Role[];
  isAvailable?: boolean;
}

export type ModelActionConfigWithArgs<
  TModel,
  TMethod extends ModelMethod<TModel>,
> = ModelActionConfig<TModel, TMethod> &
  (TModel[TMethod] extends (...args: infer TArgs) => any
    ? { args: TArgs }
    : { args?: never });

export class ModelAction<
  TModel,
  TMethod extends ModelMethod<TModel>,
> extends BaseAction {
  public readonly config: Readonly<ModelActionConfigWithArgs<TModel, TMethod>>;
  public readonly model: TModel;

  public constructor(
    config: ModelActionConfigWithArgs<TModel, TMethod>,
    actionOptions: ActionOptions = {},
  ) {
    super({
      ...actionOptions,
      accessCheck: ModelAction.buildAccessCheck(config),
    });

    this.config = Object.freeze(config);
    this.model = config.model;
  }

  private static buildAccessCheck<TModel, TMethod extends ModelMethod<TModel>>(
    actionModelConfig: ModelActionConfig<TModel, TMethod>,
  ): AccessCheck {
    const { model, roles, isAvailable } = actionModelConfig;

    return combine.and(
      () => isAvailable ?? true,
      () => new AclChecker(model).useCheckHasAccess(roles),
    );
  }

  protected triggerImplementation(): ActionTriggerResult {
    const { model, method, args = [] } = this.config;
    const fn = model[method];
    invariant(is.function_(fn), `${method} is not a function`);
    return fn.bind(model)(...args);
  }
}

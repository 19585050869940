import {
  useValueController,
  ValueControllerInstance,
} from "@mittwald/flow-components/dist/hooks/useValueController";
import { useEffect } from "react";

export const useIntervalValue = <T>(
  getter: () => T,
  intervalMs = 1000,
): ValueControllerInstance<T> => {
  const valueController = useValueController(getter());

  useEffect(() => {
    const interval = setInterval(() => {
      valueController.updateValue(getter());
    }, intervalMs);
    return () => {
      clearInterval(interval);
    };
  });

  return valueController;
};

export default useIntervalValue;

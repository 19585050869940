import is from "@sindresorhus/is";

export const getName = <T>(something: T): string => {
  const extractedName = is.string(something)
    ? something
    : is.function_(something)
      ? something.name
      : is.class_(something)
        ? something.constructor.name
        : is.object(something)
          ? something.constructor.name
          : undefined;

  if (extractedName === undefined) {
    throw Error("could not get name from unknown");
  }

  return extractedName;
};

export default getName;

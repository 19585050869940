import MetricsQueryResponse from "../metrics/MetricsQueryResponse";
import { Metrics, MetricsRequestOptions } from "../misc/Metrics";
import { ProSpaceProject } from "./";

export class ProSpaceProjectMetrics {
  public readonly project: ProSpaceProject;
  private readonly projectShortId: string;
  private readonly serverShortId?: string;

  private constructor(project: ProSpaceProject) {
    this.project = project;
    this.projectShortId = project.shortId;
    this.serverShortId = project.data.serverShortId;
  }

  public static of(project: ProSpaceProject): ProSpaceProjectMetrics {
    return new ProSpaceProjectMetrics(project);
  }

  public useCpuUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    if (this.project.useIsProSpaceLite()) {
      return Metrics.executeRequest(
        `
        SUM (cloudhosting_project_usage_cpu_seconds_total_rate:5m{project="${this.projectShortId}"}) / 
        SUM (cloudhosting_project_limits_cpu_seconds:5m{project="${this.projectShortId}"})
        `,
        options,
      );
    }

    return Metrics.executeRequest(
      `clamp_max(
      (SUM (cloudhosting_databasesetmember_usage_cpu_seconds_total_rate:5m{placement_group="${this.serverShortId}"} or vector (0)) + 
      SUM (cloudhosting_project_usage_cpu_seconds_total_rate:5m{project="${this.projectShortId}"})) /
      SUM (cloudhosting_placementgroup_limits_cpu_seconds:5m{placement_group="${this.serverShortId}"}),
      1)`,
      options,
    );
  }

  public useMemoryUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    if (this.project.useIsProSpaceLite()) {
      return Metrics.executeRequest(
        `SUM (cloudhosting_project_usage_memory_bytes:5m:max{project="${this.projectShortId}"}) / 
         SUM (cloudhosting_project_limits_memory_bytes:5m{project="${this.projectShortId}"})`,
        options,
      );
    }

    return Metrics.executeRequest(
      `
      (SUM (cloudhosting_databasesetmember_usage_memory_bytes:5m:max{placement_group="${this.serverShortId}"} or vector (0)) +
      SUM (cloudhosting_project_usage_memory_bytes:5m:max{project="${this.projectShortId}"})) / 
      SUM (cloudhosting_placementgroup_limits_memory_bytes:5m{placement_group="${this.serverShortId}"})`,
      options,
    );
  }
}

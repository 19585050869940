import filesizeParser from "filesize-parser";
import convert, { Data } from "convert";
import prettyBytes from "pretty-bytes";

export class Bytes {
  public readonly bytes: number;

  public constructor(bytes: number) {
    this.bytes = bytes;
  }

  public static of(amount: number, unit: Data = "bytes"): Bytes {
    return new Bytes(convert(amount, unit).to("bytes"));
  }

  public static parse(asString: string): Bytes {
    return new Bytes(filesizeParser(asString));
  }

  public multiply(by: number): Bytes {
    return new Bytes(this.bytes * by);
  }

  public add(other: Bytes): Bytes {
    return new Bytes(this.bytes + other.bytes);
  }

  public subtract(other: Bytes): Bytes {
    return new Bytes(this.bytes - other.bytes);
  }

  public in(unit: Data): number {
    return convert(this.bytes, "bytes").to(unit);
  }

  public isEqualTo(other: Bytes): boolean {
    return this.bytes === other.bytes;
  }

  public isNegative(): boolean {
    return this.bytes < 0;
  }

  public isPositive(): boolean {
    return this.bytes > 0;
  }

  public negate(): Bytes {
    return new Bytes(this.bytes * -1);
  }

  public pretty(binary = true, locale?: string): string {
    return prettyBytes(this.bytes, { locale, binary }).replace("i", "");
  }

  public valueOf(): number {
    return this.bytes;
  }

  public static sum(...bytes: Bytes[]): Bytes {
    return bytes.reduce((sum, cur) => sum.add(cur), new Bytes(0));
  }
}

export default Bytes;

import { iconEmail } from "@mittwald/flow-icons/dist/email";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import EmailAddress from "./EmailAddress";

export class EmailAddressModelRelation extends ModelRelation {
  public static type = new ModelRelationType("mail", "mailaddress", iconEmail);

  public constructor(mailId: string) {
    super(mailId, EmailAddressModelRelation.type);
  }

  public useMail(): EmailAddress {
    return EmailAddress.useLoadById(this.id);
  }

  public useOptionalMail(): EmailAddress | undefined {
    return EmailAddress.useTryLoadById(this.id);
  }
}

export default EmailAddressModelRelation;

import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faBox } from "@fortawesome/pro-regular-svg-icons/faBox";
import { iconServer } from "@mittwald/flow-icons/dist/server";
import { iconStorage } from "@mittwald/flow-icons/dist/storage";
import Article, { ArticleId, ArticleTemplateName } from "../../article/Article";

const promotedArticleIds = ["MT22-0003"];

export class ArticleUI {
  public readonly article: Article;
  public readonly isPromoted: boolean;

  private constructor(article: Article) {
    this.article = article;
    this.isPromoted = promotedArticleIds.includes(article.id);
  }

  public static of(article: Article): ArticleUI {
    return new ArticleUI(article);
  }

  public getIcon(): IconDefinition {
    if (
      this.article.data.template.name === ArticleTemplateName.spaceServerHosting
    ) {
      return iconServer;
    }
    if (this.article.id === ArticleId.spaceServerHostingStorage) {
      return iconStorage;
    }
    return faBox;
  }
}

export default ArticleUI;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

export type BackupApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Backup_ProjectBackup;

export type ArchiveFormatApiData =
  MittwaldApi.Paths.V2_Project_Backups_ProjectBackupId_Export.Post.Parameters.RequestBody["format"];

const archiveFormats: Record<ArchiveFormatApiData, true> = {
  zip: true,
  tar: true,
};

export const allArchiveFormats = Object.keys(archiveFormats);

export interface NewBackupInputs {
  description?: string;
  expirationTime: string;
}

export interface DownloadRequestInputs {
  format: ArchiveFormatApiData;
  password: string;
}

export interface UpdateDescriptionInputs {
  description?: string;
}

export class Backup {
  public readonly id: string;
  public readonly data: BackupApiData;
  public readonly description?: string;
  public readonly hasExport: boolean;
  public readonly exportCompleted: boolean;
  public readonly exportPending: boolean;
  public readonly isPending: boolean;
  public readonly isManual: boolean;
  public readonly createdAt?: DateTime;

  private constructor(data: BackupApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.description = data.description;
    this.hasExport = !!data.export;
    this.exportCompleted = data.export?.phase === "Completed";
    this.exportPending = this.hasExport && !this.exportCompleted;
    this.isPending = data.status !== "Completed";
    this.isManual = !data.parentId;
    this.createdAt = data.createdAt
      ? DateTime.fromISO(data.createdAt)
      : undefined;
  }

  public static fromApiData(data: BackupApiData): Backup {
    return new Backup(data);
  }

  public static useLoadById(backupId: string): Backup {
    const data = mittwaldApi.backupGetProjectBackup
      .getResource({ path: { projectBackupId: backupId } })
      .useWatchData();
    return Backup.fromApiData(data);
  }

  public static useTryLoadById(backupId?: string): Backup | undefined {
    const data = mittwaldApi.backupGetProjectBackup
      .getResource(backupId ? { path: { projectBackupId: backupId } } : null)
      .useWatchData({ optional: true });
    return data ? Backup.fromApiData(data) : undefined;
  }

  public static useLoadByPathParam(): Backup {
    const { backupId } = usePathParams("backupId");
    return Backup.useLoadById(backupId);
  }

  public static async createNew(
    values: NewBackupInputs,
    projectId: string,
  ): Promise<void> {
    const response = await mittwaldApi.backupCreateProjectBackup.request({
      path: { projectId },
      requestBody: {
        description: values.description,
        expirationTime: values.expirationTime,
      },
    });

    assertStatus(response, 201);
  }

  public async requestDownload(values: DownloadRequestInputs): Promise<void> {
    const response = await mittwaldApi.backupCreateProjectBackupExport.request({
      path: { projectBackupId: this.id },
      requestBody: {
        format: values.format,
        password: values.password,
      },
    });

    assertStatus(response, 204);
  }

  public download(): void {
    this.data.export && window.open(this.data.export.downloadURL);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.backupDeleteProjectBackup.request({
      path: { projectBackupId: this.id },
    });

    assertStatus(response, 204);
  }

  public async updateDescription(
    values: UpdateDescriptionInputs,
  ): Promise<void> {
    const response =
      await mittwaldApi.backupUpdateProjectBackupDescription.request({
        path: { projectBackupId: this.id },
        requestBody: { description: values.description },
      });

    assertStatus(response, 204);
  }

  public async deleteDownload(): Promise<void> {
    const response = await mittwaldApi.backupDeleteProjectBackupExport.request({
      path: { projectBackupId: this.id },
    });

    assertStatus(response, 204);
  }
}

export default Backup;

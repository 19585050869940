import { MittwaldApi } from "../../api/Mittwald";
import Article from "../article/Article";
import { ArticleAttributeKey } from "../article/ArticleAttribute";
import Contract from "../contract/Contract";
import Order from "./Order";

export type OrderItemApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_OrderItem;

export class OrderItem {
  public readonly data: OrderItemApiData;
  public readonly id: string;
  public readonly order: Order;

  private constructor(order: Order, data: OrderItemApiData) {
    this.data = Object.freeze(data);
    this.id = data.orderItemId;
    this.order = order;
  }

  public static fromApiData(order: Order, data: OrderItemApiData): OrderItem {
    return new OrderItem(order, data);
  }

  public referencesContract(contract: Contract): boolean {
    return this.data.reference?.contractItemId === contract.baseItem.id;
  }

  public referencesArticle(article: Article): boolean {
    return this.data.articleId === article.id;
  }

  public referencesOrderItem(orderItem: OrderItem): boolean {
    return this.data.reference?.orderItemId === orderItem.id;
  }

  public getAttributeValue(
    attribute: ArticleAttributeKey | string,
  ): string | undefined {
    return this.data.attributeConfiguration?.find((a) => a.key === attribute)
      ?.value;
  }
}

export default OrderItem;

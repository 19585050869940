import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import App from "./App";

export class AppList extends ListModel<App> {
  public static useLoadAll(): AppList {
    const apps = mittwaldApi.appListApps
      .getResource({})
      .useWatchData()
      .map((a) => App.fromApiData(a));

    return new AppList(apps);
  }
}

export default AppList;

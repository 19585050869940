import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import OrderResourceSelect from "@mittwald/flow-components/dist/components/OrderResourceSelect/OrderResourceSelect";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import React, { FC } from "react";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import {
  ArticleFactory,
  SpaceServerArticle,
} from "../../../../../../model/article";
import Server from "../../../../../../model/server/Server";
import SpaceServerArticleUI from "../../../../../../model/ui/article/SpaceServerArticleUI";
import { AdjustStorageFormFields } from "../../../types";

export interface MachineTypeSectionProps {
  server?: Server;
}

export const MachineTypeSection: FC<MachineTypeSectionProps> = (props) => {
  const { server } = props;

  const selectedStorageSize =
    useFormContext<AdjustStorageFormFields>().watch("storageSizeInBytes");

  const storageSizeChanged =
    !server || selectedStorageSize !== server.storage.bytes.bytes;

  const serverArticle = server?.useArticle();

  const spaceServerArticles: SpaceServerArticle[] =
    ArticleFactory.useLoadAllSpaceServerArticles();

  const promoteArticles = !server;

  const options = SpaceServerArticleUI.buildSelectBoxOptions(
    spaceServerArticles,
    promoteArticles,
    serverArticle,
  );

  return (
    <Section.Item headline="machineType">
      <OrderResourceSelect
        minOptionWidth={170}
        name="articleId"
        options={options}
        rules={{
          validate: {
            sameValue: (articleId: string) =>
              storageSizeChanged ||
              serverArticle === undefined ||
              articleId !== serverArticle.id,
          },
        }}
      />
      <TextWithIconLayout icon={iconInfoCircle}>
        <Text i18n="machineTypeAdditionalInfo" small squished />
      </TextWithIconLayout>
    </Section.Item>
  );
};

export default MachineTypeSection;

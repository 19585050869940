import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoMySqlDatabase,
  demoMySqlDatabaseUser,
  demoMySqlVersion,
  demoRedisDatabase,
  demoRedisVersion,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockDatabaseListMysqlVersions(
    {},
    { status: 200, content: [demoMySqlVersion] },
  );

  mittwaldApiRequestMocking.mockDatabaseGetMysqlUserPhpMyAdminUrl(
    { path: { mysqlUserId: "*" } },
    { status: 200, content: { url: "https://www.phpmyadmin.net/" } },
  );

  mittwaldApiRequestMocking.mockDatabaseUpdateMysqlUserPassword(
    { path: { mysqlUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseGetMysqlUser(
    { path: { mysqlUserId: "*" } },
    { status: 200, content: demoMySqlDatabaseUser },
  );

  mittwaldApiRequestMocking.mockDatabaseDeleteMysqlUser(
    { path: { mysqlUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseCreateMysqlUser(
    { path: { mysqlDatabaseId: "*" } },
    { status: 201 },
  );

  mittwaldApiRequestMocking.mockDatabaseUpdateMysqlUser(
    { path: { mysqlUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseListMysqlUsers(
    { path: { mysqlDatabaseId: "*" } },
    { status: 200, content: [demoMySqlDatabaseUser] },
  );

  mittwaldApiRequestMocking.mockDatabaseCreateMysqlDatabase(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoMySqlDatabase.id } },
  );

  mittwaldApiRequestMocking.mockDatabaseListMysqlDatabases(
    { path: { projectId: "*" } },
    { status: 200, content: [demoMySqlDatabase] },
  );

  mittwaldApiRequestMocking.mockDatabaseDeleteMysqlDatabase(
    { path: { mysqlDatabaseId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseUpdateMysqlDatabaseDescription(
    { path: { mysqlDatabaseId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseGetMysqlDatabase(
    { path: { mysqlDatabaseId: "*" } },
    { status: 200, content: demoMySqlDatabase },
  );

  mittwaldApiRequestMocking.mockDatabaseListRedisVersions(
    {},
    { status: 200, content: [demoRedisVersion] },
  );

  mittwaldApiRequestMocking.mockDatabaseCreateRedisDatabase(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoRedisDatabase.id } },
  );

  mittwaldApiRequestMocking.mockDatabaseListRedisDatabases(
    { path: { projectId: "*" } },
    { status: 200, content: [demoRedisDatabase] },
  );

  mittwaldApiRequestMocking.mockDatabaseDeleteRedisDatabase(
    { path: { redisDatabaseId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseUpdateRedisDatabaseDescription(
    { path: { redisDatabaseId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockDatabaseGetRedisDatabase(
    { path: { redisDatabaseId: "*" } },
    { status: 200, content: demoRedisDatabase },
  );
};

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import User from "../user/User";
import Customer from "./Customer";
import CustomerMembershipList from "./CustomerMembershipList";
import { CustomerRole, CustomerRoleName } from "./CustomerRole";

export type CustomerMembershipApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_CustomerMembership;

export interface UpdateMemberInputs {
  role: CustomerRoleName;
  expiresAt?: string;
}

export class CustomerMembership {
  public readonly data: CustomerMembershipApiData;
  public readonly id: string;
  public readonly role: CustomerRole;
  public readonly customer: Customer;
  public readonly userId: string;

  private constructor(customer: Customer, data: CustomerMembershipApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.role = new CustomerRole(data.role);
    this.customer = customer;
    this.userId = data.userId;
  }

  public static fromApiData(
    customer: Customer,
    data: CustomerMembershipApiData,
  ): CustomerMembership {
    return new CustomerMembership(customer, data);
  }

  public static useLoad(
    membershipId: string,
    customerId: string,
  ): CustomerMembership {
    const membership = mittwaldApi.customerGetCustomerMembership
      .getResource({
        path: {
          customerMembershipId: membershipId,
        },
      })
      .useWatchData();

    const customer = Customer.useLoadById(customerId);

    return new CustomerMembership(customer, membership);
  }

  public useIsMe(): boolean {
    return User.useMe().id === this.userId;
  }

  public useUser(): User {
    return User.useLoadById(this.data.userId);
  }

  public useCustomer(): Customer {
    return Customer.useLoadById(this.data.customerId);
  }

  public useCheckUserIsNotLastOwnerOfCustomer = (): boolean => {
    const members = CustomerMembershipList.useLoadByCustomer(
      this.customer,
    ).useItems();

    const member = members.find((m) => m.data.id === this.data.id);
    return (
      !member ||
      !member.role.is("owner") ||
      members.filter((m) => m.role.is("owner")).length > 1
    );
  };

  public async leaveCustomer(): Promise<void> {
    const response = await mittwaldApi.customerLeaveCustomer.request({
      path: {
        customerId: this.customer.id,
      },
    });

    assertStatus(response, 204);
  }

  public async removeMember(): Promise<void> {
    const response = await mittwaldApi.customerDeleteCustomerMembership.request(
      {
        path: {
          customerMembershipId: this.id,
        },
      },
    );

    assertStatus(response, 204);
  }

  public async updateMember(values: UpdateMemberInputs): Promise<void> {
    const response = await mittwaldApi.customerUpdateCustomerMembership.request(
      {
        path: {
          customerMembershipId: this.id,
        },
        requestBody: {
          role: values.role,
          expiresAt: values.expiresAt,
        },
      },
    );

    assertStatus(response, 204);
  }
}

export default CustomerMembership;

import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import User from "../user/User";
import Server from "./Server";

export class ServerList extends ListModel<Server> {
  public static useLoad(): ServerList {
    return new ServerList(
      mittwaldApi.projectListServers
        .getResource({})
        .useWatchData()
        .map((s) => Server.fromApiData(s)),
    );
  }

  public static useTryLoadByCustomerId(customerId?: string): ServerList {
    return new ServerList(
      mittwaldApi.projectListServers
        .getResource(customerId ? { query: { customerId } } : null)
        .useWatchData()
        ?.map((p) => Server.fromApiData(p)) ?? [],
    );
  }

  public static useLoadByOptionalCustomerId(customerId?: string): ServerList {
    const userId = User.useMe().id;

    return new ServerList(
      mittwaldApi.projectListServers
        .getResource({ query: customerId ? { customerId } : { userId } })
        .useWatchData()
        .map((s) => Server.fromApiData(s)),
    );
  }

  public getSelectOptions(optional?: boolean): SelectOptions {
    const options: SelectOptions = this.items
      .sort((a, b) => a.data.description.localeCompare(b.data.description))
      .map((server) => {
        return {
          value: server.data.id,
          label: { text: server.data.description },
        };
      });

    if (optional) {
      options.unshift({ label: "nothingSelected", value: undefined });
    }

    return options;
  }
}

export default ServerList;

import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { Cronjob } from "./Cronjob";

export class CronjobList extends ListModel<Cronjob> {
  public static useLoadAllByProjectId(projectId: string): CronjobList {
    const cronjobs = mittwaldApi.cronjobListCronjobs
      .getResource({ path: { projectId } })
      .useWatchData()
      .map((c) => Cronjob.fromApiData(c));

    return new CronjobList(cronjobs);
  }

  public static useLoadAllByPathParam(): CronjobList {
    const { projectId } = usePathParams("projectId");
    return CronjobList.useLoadAllByProjectId(projectId);
  }
}

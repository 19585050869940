import { ConversationApiSchema } from "../../model/support/Conversation";
import { ConversationCategoryApiSchema } from "../../model/support/ConversationCategory";
import { ConversationMessageApiSchema } from "../../model/support/ConversationMessage";
import {
  demoDate,
  demoName,
  demoReference,
  demoShortId,
} from "../staticDemoData";

export const demoConversationCategory: ConversationCategoryApiSchema = {
  categoryId: "1",
  name: "emails",
  referenceType: ["project"],
};

const demoUser = {
  userId: "1",
  clearName: demoName,
};

export const demoConversation: ConversationApiSchema = {
  category: demoConversationCategory,
  conversationId: "1",
  createdAt: demoDate,
  createdBy: demoUser,
  lastMessageAt: demoDate,
  lastMessageBy: demoUser,
  relatedTo: demoReference,
  relations: [demoReference],
  shortId: demoShortId,
  status: "open",
  title: "Demo Ticket",
  visibility: "shared",
  mainUser: demoUser,
};

export const demoConversationMessage: ConversationMessageApiSchema = {
  conversationId: "1",
  createdAt: demoDate,
  createdBy: demoUser,
  messageContent: "Hallo!",
  messageId: "1",
  type: "MESSAGE",
};

import { faCubes } from "@fortawesome/pro-regular-svg-icons/faCubes";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import { Registry } from "./Registry";

export class RegistryModelRelation extends ModelRelation {
  public static type = new ModelRelationType("container", "registry", faCubes);

  public constructor(registryId: string) {
    super(registryId, RegistryModelRelation.type);
  }
  public useRegistry(): Registry {
    return Registry.useLoadById(this.id);
  }
  public useOptionalRegistry(): Registry | undefined {
    return Registry.useTryLoadById(this.id);
  }
}

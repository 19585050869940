import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoProject,
  demoProjectInvite,
  demoProjectMembership,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockProjectLeaveProject(
    { path: { projectId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockProjectUpdateProjectMembership(
    { path: { projectMembershipId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockProjectDeleteProjectMembership(
    { path: { projectMembershipId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockProjectGetProjectInvite(
    { path: { projectInviteId: "*" } },
    () => ({ status: 200, content: demoProjectInvite }),
  );

  mittwaldApiRequestMocking.mockProjectGetProjectMembership(
    { path: { projectMembershipId: "*" } },
    () => ({ status: 200, content: demoProjectMembership }),
  );

  mittwaldApiRequestMocking.mockProjectListMembershipsForProject(
    { path: { projectId: "*" } },
    () => ({ status: 200, content: [demoProjectMembership] }),
  );

  mittwaldApiRequestMocking.mockProjectGetSelfMembershipForProject(
    { path: { projectId: "*" } },
    () => ({ status: 200, content: demoProjectMembership }),
  );

  mittwaldApiRequestMocking.mockProjectListProjectMemberships({}, () => ({
    status: 200,
    content: [demoProjectMembership],
  }));

  mittwaldApiRequestMocking.mockProjectAcceptProjectInvite(
    { path: { projectInviteId: "*" } },
    () => ({ status: 204 }),
  );

  mittwaldApiRequestMocking.mockProjectDeclineProjectInvite(
    { path: { projectInviteId: "*" } },
    () => ({ status: 204 }),
  );

  mittwaldApiRequestMocking.mockProjectDeleteProjectInvite(
    { path: { projectInviteId: "*" } },
    () => ({ status: 204 }),
  );

  mittwaldApiRequestMocking.mockProjectListProjectInvites({}, () => {
    return { status: 200, content: [demoProjectInvite] };
  });

  mittwaldApiRequestMocking.mockProjectListInvitesForProject(
    { path: { projectId: "*" } },
    () => ({ status: 200, content: [demoProjectInvite] }),
  );

  mittwaldApiRequestMocking.mockProjectCreateProjectInvite(
    { path: { projectId: "*" } },
    () => ({ status: 201 }),
  );

  mittwaldApiRequestMocking.mockPageinsightsListPerformanceDataForProject(
    { path: { projectId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockOrderListProjectOrders(
    { path: { projectId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockProjectFileSystemGetDirectories(
    { path: { projectId: "*" } },
    { status: 200, content: { items: [] } },
  );

  mittwaldApiRequestMocking.mockProjectUpdateProjectDescription(
    { path: { projectId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockProjectListProjects(
    {},
    { status: 200, content: [demoProject] },
  );

  mittwaldApiRequestMocking.mockProjectGetProject(
    { path: { projectId: "*" } },
    { status: 200, content: demoProject },
  );

  mittwaldApiRequestMocking.mockProjectRequestProjectAvatarUpload(
    { path: { projectId: "*" } },
    { status: 200, content: { refId: "1" } },
  );

  mittwaldApiRequestMocking.mockProjectDeleteProjectAvatar(
    { path: { projectId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockProjectCreateProject(
    { path: { serverId: "*" } },
    { status: 201 },
  );

  mittwaldApiRequestMocking.mockProjectDeleteProject(
    { path: { projectId: "*" } },
    { status: 200 },
  );
};

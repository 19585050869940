import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import Domain, { DomainOrder } from "./Domain";
import Ingress from "./Ingress";

export type DomainOrderOrIngress = Ingress | DomainOrder | Domain;

export class DomainList extends ListModel<Domain> {
  public static useLoadAllByProjectId(projectId?: string): DomainList {
    const domains = mittwaldApi.domainListDomains
      .getResource(projectId ? { query: { projectId } } : {})
      .useWatchData();

    const domainsMemo = useMemo(
      () => domains.map((d) => Domain.fromApiData(d)),
      [domains],
    );

    return new DomainList(domainsMemo);
  }

  public static useTryLoadAllByProjectId(projectId?: string): DomainList {
    const domains = mittwaldApi.domainListDomains
      .getResource(projectId ? { query: { projectId } } : {})
      .useWatchData({ optional: true });

    const domainsMemo = useMemo(
      () => (domains || []).map((d) => Domain.fromApiData(d)),
      [domains],
    );

    return new DomainList(domainsMemo);
  }
}

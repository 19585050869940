import { faBallotCheck } from "@fortawesome/pro-regular-svg-icons/faBallotCheck";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import { ConditionsCheckbox } from "../../../../../../components/ConditionsCheckbox/ConditionsCheckbox";
import {
  ProSpaceArticle,
  SpaceServerArticle,
} from "../../../../../../model/article";
import ContractItem from "../../../../../../model/contract/ContractItem";
import Customer from "../../../../../../model/customer/Customer";
import { InvoiceSettings } from "../../../../../../model/customer/InvoiceSettings";
import Bytes from "../../../../../../model/misc/Bytes";
import VirtualPageViewTracking from "../../../../../../widgets/components/tracking/components/VirtualPageViewTracking";
import { PromoCode } from "../../../../../../widgets/utils/promoCode";
import TariffChangeExecutionDateInfo from "../../AdjustServerWizard/components/TariffChangeExecutionDateInfo";
import { CustomerInfoBox } from "./CustomerInfoBox";
import { OrderOverviewBox } from "./OrderOverviewBox";

export interface OverviewStepContentProps {
  customerId: string;
  selectedArticle: SpaceServerArticle | ProSpaceArticle;
  contractItem?: ContractItem;
  freeTrial?: boolean;
  storageSize: Bytes;
  isWidget?: boolean;
  trackingId?: string;
  promotion?: PromoCode;
}

const CustomerEmailInformation: FC<{ customerId: string }> = ({
  customerId,
}) => {
  const customer = Customer.useLoadById(customerId);
  const customerEmail = customer.contact?.data.emailAddress;
  const invoiceSettings = InvoiceSettings.useTryLoadByCustomer(customer);
  const recipientEmail = invoiceSettings?.data.recipient?.emailAddress;

  return (
    <Text
      i18n={{
        id: "description",
        values: { email: recipientEmail || customerEmail },
      }}
    />
  );
};

export const OverviewStep: FC<OverviewStepContentProps> = (props) => {
  const {
    customerId,
    selectedArticle,
    contractItem,
    freeTrial,
    storageSize,
    isWidget,
    trackingId,
    promotion,
  } = props;

  return (
    <TranslationProvider name="serverOverviewStep" type="section">
      <WizardStep id="overview" indicatorText="overviewStep">
        <Suspense loadingView={<LoadingView _height={250} />}>
          {trackingId && (
            <VirtualPageViewTracking
              identifier={trackingId}
              name="checkout/overview"
            />
          )}
          <Section.Layout>
            <Section.Item headline="checkOrder" headlineIcon={faBallotCheck}>
              <CustomerEmailInformation customerId={customerId} />
            </Section.Item>
            <Section.Item>
              <ColumnLayout medium={isWidget ? undefined : [1, 1]}>
                <CustomerInfoBox customerId={customerId} isWidget={isWidget} />
                {!isWidget && (
                  <OrderOverviewBox
                    freeTrial={freeTrial}
                    promotion={promotion}
                    selectedArticle={selectedArticle}
                    storageSize={storageSize}
                  />
                )}
              </ColumnLayout>

              {contractItem && !contractItem.freeTrialUntil && (
                <Text>
                  <TariffChangeExecutionDateInfo
                    article={selectedArticle}
                    contractItem={contractItem}
                    storageSize={storageSize}
                  />
                </Text>
              )}
              {contractItem && contractItem.freeTrialUntil && (
                <Text i18n="endFreeTrial" />
              )}
              <ConditionsCheckbox marginTop="none" showPHPHint />
            </Section.Item>
          </Section.Layout>
        </Suspense>
      </WizardStep>
    </TranslationProvider>
  );
};

export default OverviewStep;

import { Class } from "type-fest";
import getClass from "../../../lib/getClass";
import AclRootModelResolver from "./AclRootModelResolver";
import { customerRoles, projectRoles, Role } from "./Role";

export class AclChecker<M> {
  public model: M;
  private readonly modelClass: Class<M>;

  public constructor(model: M) {
    this.model = model;
    this.modelClass = getClass(model);
  }

  public static useCheckIsAuthenticated: () => boolean = () => false;

  public useCheckHasAccess(rolesWithAccess: Role[]): boolean {
    const isAuthenticated = AclChecker.useCheckIsAuthenticated();

    const rolesReferencingOrganisation = rolesWithAccess.some((r) =>
      customerRoles.includes(r),
    );

    const rolesReferencingProject = rolesWithAccess.some((r) =>
      projectRoles.includes(r),
    );

    const myProjectRole = rolesReferencingProject
      ? AclRootModelResolver.get(this.modelClass)
          .useProject(this.model)
          ?.useMyRole().name
      : undefined;

    const myOrganisationRole = rolesReferencingOrganisation
      ? AclRootModelResolver.get(this.modelClass).useCustomer(this.model)
          ?.myRole
      : undefined;

    const checkMyRole = (role: Role): boolean =>
      role === Role.anybody ||
      (role === Role.authenticated && isAuthenticated) ||
      (role === Role.customerAccountant &&
        myOrganisationRole === "accountant") ||
      (role === Role.customerMember && myOrganisationRole === "member") ||
      (role === Role.customerOwner && myOrganisationRole === "owner") ||
      (role === Role.emailAdmin && myProjectRole === "emailadmin") ||
      (role === Role.projectDeveloper && myProjectRole === "external") ||
      (role === Role.projectAdmin && myProjectRole === "owner");

    return rolesWithAccess.some(checkMyRole);
  }
}

export default AclChecker;

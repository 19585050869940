import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { openFileInNewTab } from "../../lib/openFileInNewTab";
import MoneyValue from "../misc/MoneyValue";

export type InvoiceApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_Invoice;

export type InvoiceItemApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_InvoiceItem;

export class Invoice {
  public readonly id: string;
  public readonly data: InvoiceApiData;
  public readonly totalGross: MoneyValue;
  public readonly totalNet: MoneyValue;
  public readonly amountPaid: MoneyValue;
  public readonly remainingAmount?: MoneyValue;
  public readonly credit?: MoneyValue;
  public readonly date: DateTime;
  public readonly paymentTerm: DateTime;
  public readonly isOverdue: boolean;
  public readonly isOverpaid: boolean;
  public readonly isPaid: boolean;
  public readonly isPartiallyPaid: boolean;
  public readonly isOpen: boolean;
  public readonly isOrHasCancellation: boolean;
  public readonly isCancellationOrCorrection: boolean;
  public readonly status:
    | "overpaid"
    | "hasCancellation"
    | "isCancellation"
    | "open"
    | "overdue"
    | "partiallyPaid"
    | "paid";

  private constructor(data: InvoiceApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.totalGross = new MoneyValue(data.totalGross);
    this.totalNet = new MoneyValue(data.totalNet);
    this.amountPaid = new MoneyValue(data.amountPaid);
    this.credit =
      data.totalGross < 0
        ? new MoneyValue(-data.totalGross)
        : data.amountPaid > data.totalGross
          ? new MoneyValue(data.amountPaid - data.totalGross)
          : undefined;
    this.remainingAmount =
      data.totalGross > 0 && data.amountPaid < data.totalGross
        ? new MoneyValue(data.totalGross - data.amountPaid)
        : undefined;
    this.date = DateTime.fromISO(data.date);
    this.paymentTerm = this.date.plus({ day: 14 });

    this.isOrHasCancellation =
      !!data.cancellation || data.invoiceType === "CANCELLATION";
    this.isOverdue =
      data.status !== "PAID" &&
      this.paymentTerm < DateTime.local() &&
      !!this.remainingAmount &&
      !this.isOrHasCancellation;
    this.isOverpaid =
      (data.status === "OVERPAID" || !!this.credit) &&
      !this.isOrHasCancellation;
    this.isPaid = data.status === "PAID" && !this.isOrHasCancellation;
    this.isPartiallyPaid =
      data.status === "PARTIALLY_PAID" && !this.isOrHasCancellation;
    this.isOpen =
      (data.status === "CONFIRMED" || data.status === "NEW") &&
      !this.isOrHasCancellation;
    this.isCancellationOrCorrection =
      data.invoiceType === "CORRECTION" || data.invoiceType === "CANCELLATION";
    this.status =
      data.invoiceType === "CANCELLATION"
        ? "isCancellation"
        : data.cancellation
          ? "hasCancellation"
          : this.isOverdue
            ? "overdue"
            : this.isOverpaid
              ? "overpaid"
              : this.isPartiallyPaid
                ? "partiallyPaid"
                : this.isOpen
                  ? "open"
                  : "paid";
  }

  public static fromApiData(data: InvoiceApiData): Invoice {
    return new Invoice(data);
  }

  public static useLoadByInvoiceId(invoiceId: string): Invoice {
    const data = mittwaldApi.invoiceDetail
      .getResource({
        path: {
          invoiceId,
        },
        query: {},
      })
      .useWatchData();

    return new Invoice(data);
  }

  public static useTryLoadByInvoiceId(invoiceId?: string): Invoice | undefined {
    const data = mittwaldApi.invoiceDetail
      .getResource(
        invoiceId
          ? {
              path: {
                invoiceId,
              },
              query: {},
            }
          : null,
      )
      .useWatchData({
        optional: true,
      });

    return data ? new Invoice(data) : undefined;
  }

  public static async loadInvoiceFileContent(
    fileId: string,
    invoiceId: string,
    customerId: string,
  ): Promise<string | undefined> {
    const tokenResponse = await mittwaldApi.invoiceGetFileAccessToken.request({
      path: {
        customerId,
        invoiceId,
      },
    });

    assertStatus(tokenResponse, 200);

    const fileResponse = await mittwaldApi.fileGetFile.request({
      path: { fileId },
      header: {
        Accept: "text/plain;base64",
        Token: tokenResponse.content.accessToken,
        "x-access-token": undefined,
      },
    });
    assertStatus(fileResponse, 200);

    return fileResponse.content;
  }

  public static async downloadInvoice(invoice: Invoice): Promise<void> {
    const invoiceContent = await this.loadInvoiceFileContent(
      invoice.data.pdfId,
      invoice.id,
      invoice.data.customerId,
    );

    openFileInNewTab({
      id: invoice.data.pdfId,
      content: invoiceContent,
      name: invoice.data.invoiceNumber,
      type: "application/pdf",
    });
  }
}

export default Invoice;

import { AppApiData } from "../../model/app/App";
import { AppVersionApiData } from "../../model/app/AppVersion";
import { SystemSoftwareApiData } from "../../model/app/SystemSoftware";
import { SystemSoftwareVersionApiData } from "../../model/app/SystemSoftwareVersion";
import { demoShortId } from "../staticDemoData";

export const demoApp: AppApiData = {
  id: "1",
  name: "WordPress",
  tags: ["CMS"],
};

export const demoAppVersion: AppVersionApiData = {
  externalVersion: "6.0.1",
  internalVersion: "6.0.1",
  id: "1",
  appId: "1",
  docRoot: "",
  docRootUserEditable: false,
  userInputs: [
    {
      name: "site_title",
      dataType: "text",
      defaultValue: "Wordpress",
      validationSchema: '{ "type": "string", "minLength": 1 }',
      positionMeta: {
        step: "common",
        index: 1,
      },
      lifecycleConstraint: "installation",
      required: true,
    },
  ],
};

export const demoSystemSoftware: SystemSoftwareApiData = {
  id: "1",
  name: "PHP",
  tags: [],
};

export const demoSystemSoftwareVersion: SystemSoftwareVersionApiData = {
  externalVersion: "8.1",
  internalVersion: "8.1",
  id: "1",
};

export const demoAppInstallation = {
  appId: "1",
  appVersion: {
    desired: "1",
    current: "1",
  },
  description: "Demo Wordpress",
  id: "1",
  installationPath: "/",
  linkedDatabases: [
    {
      databaseId: "1",
      databaseUserIds: ["1"],
      purpose: "primary",
      kind: "mysql",
    },
  ],
  projectId: "1",
  shortId: demoShortId,
  systemSoftware: [
    {
      systemSoftwareId: "1",
      systemSoftwareVersion: {
        desired: "1",
      },
      updatePolicy: "patchLevel",
    },
  ],
  updatePolicy: "patchLevel",
  userInputs: [
    {
      name: "site_title",
      value: "Demo Wordpress",
    },
  ],
};

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { Class } from "type-fest";
import { mittwaldApi } from "../../api/Mittwald";
import isNonEmptyArray, { NonEmptyArray } from "../../lib/isNonEmptyArray";
import {
  Article,
  ArticleApiData,
  ArticleId,
  ArticleTemplateName,
  SpaceServerArticle,
  StorageArticle,
  ProSpaceArticle,
} from "./";

export type ArticleClasses = Class<
  SpaceServerArticle | StorageArticle | Article
>;

export class ArticleFactory {
  public static useLoadById(id: string | ArticleId): Article {
    const data = mittwaldApi.articleGetArticle
      .getResource({
        path: {
          articleId: id,
        },
      })
      .useWatchData();

    return this.fromData(data);
  }

  public static useLoadAllByTemplate(
    name: string | ArticleTemplateName,
  ): NonEmptyArray<Article> {
    const all = mittwaldApi.articleListArticles
      .getResource({
        query: {
          templateNames: [name],
          orderable: ["full", "deprecated"],
        },
      })
      .useWatchData()
      .map((a) => this.fromData(a));

    invariant(isNonEmptyArray(all), "Articles by template must not be empty");
    return all;
  }

  public static useLoadAllSpaceServerArticles(): NonEmptyArray<SpaceServerArticle> {
    const all = mittwaldApi.articleListArticles
      .getResource({
        query: {
          templateNames: [ArticleTemplateName.spaceServerHosting],
          orderable: ["full", "deprecated"],
        },
      })
      .useWatchData()
      .map((a) => new SpaceServerArticle(a));

    invariant(isNonEmptyArray(all), "Articles by template must not be empty");
    return all;
  }

  public static useLoadAllProSpaceArticles(): NonEmptyArray<ProSpaceArticle> {
    const all = mittwaldApi.articleListArticles
      .getResource({
        query: {
          templateNames: [ArticleTemplateName.proSpaceHosting],
          orderable: ["full", "deprecated"],
        },
      })
      .useWatchData()
      .map((a) => new ProSpaceArticle(a));

    invariant(isNonEmptyArray(all), "Articles by template must not be empty");
    return all;
  }

  public static useLoadAll(): Article[] {
    return mittwaldApi.articleListArticles
      .getResource({
        query: {
          orderable: ["full", "deprecated"],
        },
      })
      .useWatchData()
      .map((a) => this.fromData(a));
  }

  public static async loadById(id: string | ArticleId): Promise<Article> {
    const response = await mittwaldApi.articleGetArticle.request({
      path: {
        articleId: id,
      },
    });

    assertStatus(response, 200);
    return this.fromData(response.content);
  }

  private static fromData(apiData: ArticleApiData): Article {
    let article = new Article(apiData);

    if (apiData.template.name === ArticleTemplateName.spaceServerHosting) {
      article = new SpaceServerArticle(apiData);
    } else if (apiData.template.name === ArticleTemplateName.proSpaceHosting) {
      article = new ProSpaceArticle(apiData);
    } else if (
      apiData.articleId === ArticleId.spaceServerHostingStorage ||
      apiData.articleId === ArticleId.proSpaceHostingStorage
    ) {
      article = new StorageArticle(apiData);
    }

    return article;
  }
}

export default ArticleFactory;

import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { AnyProject, Project } from "./";

export class ProjectList extends ListModel<AnyProject> {
  public static useLoad(): ProjectList {
    return new ProjectList(
      mittwaldApi.projectListProjects
        .getResource({})
        .useWatchData()
        .map((p) => Project.fromProjectListItemApiData(p)),
    );
  }

  public static useLoadByCustomerId(customerId?: string): ProjectList {
    return new ProjectList(
      mittwaldApi.projectListProjects
        .getResource({ query: { customerId } })
        .useWatchData()
        .map((p) => Project.fromProjectListItemApiData(p)),
    );
  }

  public static useLoadByServerId(serverId?: string): ProjectList {
    return new ProjectList(
      mittwaldApi.projectListProjects
        .getResource({ query: { serverId: serverId } })
        .useWatchData()
        .map((p) => Project.fromProjectListItemApiData(p)),
    );
  }

  public getSelectOptions(optional?: boolean): SelectOptions {
    const options: SelectOptions = this.items
      .sort((a, b) => a.data.description.localeCompare(b.data.description))
      .map((project) => {
        return {
          value: project.data.id,
          label: { text: project.data.description },
        };
      });

    if (optional) {
      options.unshift({ label: "nothingSelected", value: undefined });
    }

    return options;
  }
}

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { statusTypeProps } from "@mittwald/flow-components/dist/lib/statusType";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { CheckPageStateResult } from "@mittwald/flow-lib/dist/pages/state";
import { DateTime } from "luxon";
import { MittwaldApi, mittwaldApi } from "../../../api/Mittwald";
import { checkHostnameMatchesWildcards } from "../../../pages/app/domain/ssl/utils/checkHostnameMatchesWildcards";
import { AnyProject, Project } from "../../project";
import Domain from "../Domain";
import Ingress from "../Ingress";

export type SSLCertificateApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_Certificate;
export type SSLCertificateContact =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_Contact;

export type SSLCertificateCheckReplaceChanges =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceChanges;
export type SSLCertificateErrors =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CertificateError;

export type SSLCertificateCreateRequestResponse =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CertificateRequestCreateResponse;

export type SSLCertificateCheckReplaceFieldChanges =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceFieldChange
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceSliceChange
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceDateChange;

export const isSimpleChangeField = (
  object: any,
): object is
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceFieldChange
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceDateChange => {
  return "newValue" in object && "oldValue" in object;
};

export const isSliceChangeField = (
  object: any,
): object is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ssl_CheckReplaceSliceChange => {
  return (
    "values" in object || "removedValues" in object || "addedValues" in object
  );
};

export type CheckReplaceResult =
  | {
      isReplaceable: true;
      diff: SSLCertificateCheckReplaceChanges;
    }
  | {
      isReplaceable: false;
      reason: SSLCertificateErrors["message"];
    };

export interface CertificateCompatibleIngress {
  status: "linked" | "notLinked";
  ingress: () => Ingress | Domain;
}

export type SSLCertificateStateStatusType = Omit<CheckPageStateResult, "null"> &
  keyof typeof statusTypeProps;

export interface SSLCertificateStateDefinition<
  T extends I18nDefinition = string,
> {
  type: SSLCertificateStateStatusType;
  title?: T;
}

export type SSLCertificateLinkedState =
  | SSLCertificateStateDefinition<"notLinked" | I18nDefinition>
  | undefined;

export type SSLCertificateGeneralState =
  | SSLCertificateStateDefinition<
      "expiredCertificate" | "expirationWarning" | I18nDefinition
    >
  | undefined;

export type SSLCertificateState =
  | SSLCertificateGeneralState
  | SSLCertificateLinkedState;

export class SSLCertificate {
  public readonly data: SSLCertificateApiData;
  public readonly id: string;
  public readonly name: string;
  public readonly validFrom: DateTime;
  public readonly validTo: DateTime;
  public readonly dnsNames: string[];
  public readonly commonName?: string;
  public readonly issuer?: string;
  public readonly contact?: SSLCertificateContact;
  public readonly certificate: string;

  private constructor(data: SSLCertificateApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;

    this.validFrom = DateTime.fromISO(data.validFrom);
    this.validTo = DateTime.fromISO(data.validTo);

    this.commonName = data.commonName;
    this.dnsNames = data.dnsNames ?? [];

    this.name = this.commonName ?? this.dnsNames[0] ?? "";
    this.issuer = data.issuer;
    this.certificate = data.certificate;

    this.contact = data.contact;
  }

  public static fromApiData = (data: SSLCertificateApiData): SSLCertificate => {
    return new SSLCertificate(data);
  };

  public static useLoadByPathParam(): SSLCertificate {
    const { certificateId } = usePathParams("certificateId");
    return SSLCertificate.useLoadById(certificateId);
  }

  public static useLoadById(certificateId: string): SSLCertificate {
    const data = mittwaldApi.sslGetCertificate
      .getResource({ path: { certificateId: certificateId } })
      .useWatchData();
    return SSLCertificate.fromApiData(data);
  }

  public static useTryLoadById(
    certificateId: string,
  ): SSLCertificate | undefined {
    const data = mittwaldApi.sslGetCertificate
      .getResource({ path: { certificateId: certificateId } })
      .useWatchData({ optional: true });
    return data ? SSLCertificate.fromApiData(data) : undefined;
  }

  public useProject(): AnyProject {
    return Project.useLoadById(this.data.projectId);
  }

  public useCompatibleIngresses(): CertificateCompatibleIngress[] {
    const ingresses = mittwaldApi.ingressListIngressesCompatibleWithCertificate
      .getResource({
        requestBody: {
          certificate: this.data.certificate,
          projectId: this.data.projectId,
        },
      })
      .useWatchData();

    return ingresses.map((ingressApiData) => {
      return {
        ingress: () => Ingress.fromApiData(ingressApiData),
        status:
          "certificateId" in ingressApiData.tls &&
          this.id === ingressApiData.tls.certificateId
            ? "linked"
            : "notLinked",
      };
    });
  }

  public async getCompatibleIngresses(
    projectId: string,
  ): Promise<CertificateCompatibleIngress[]> {
    const response =
      await mittwaldApi.ingressListIngressesCompatibleWithCertificate.request({
        requestBody: {
          certificate: this.data.certificate,
          projectId: projectId,
        },
      });

    assertStatus(response, 200);

    return response.content.map((ingressApiData) => {
      return {
        ingress: () => Ingress.fromApiData(ingressApiData),
        status:
          "certificateId" in ingressApiData.tls &&
          this.id === ingressApiData.tls.certificateId
            ? "linked"
            : "notLinked",
      };
    });
  }

  public useCurrentLinkedState(): SSLCertificateLinkedState {
    const compatibleIngresses = this.useCompatibleIngresses();
    if (
      compatibleIngresses.length >= 1 &&
      compatibleIngresses.filter((i) => i.status === "linked").length === 0
    ) {
      return {
        type: "info",
        title: "notLinked",
      };
    }
  }

  public getCurrentGeneralState(): SSLCertificateGeneralState {
    if (this.validTo.diffNow("days").days <= 0) {
      return {
        type: "error",
        title: "expiredCertificate",
      };
    }

    if (this.validTo.diffNow("months").months <= 1) {
      return {
        type: "warning",
        title: "expirationWarning",
      };
    }
  }

  public useCurrentState(): SSLCertificateState {
    const generalState = this.getCurrentGeneralState();
    if (generalState) {
      return generalState;
    }

    const linkedState = this.useCurrentLinkedState();
    if (linkedState) {
      return linkedState;
    }
  }

  public createCertificate(): void {}

  public async deleteCertificate(): Promise<void> {
    const response = await mittwaldApi.sslDeleteCertificate.request({
      path: { certificateId: this.id },
    });

    assertStatus(response, 204);
  }

  public async checkEditCertificate(
    certificate: string,
    privateKey?: string,
  ): Promise<CheckReplaceResult> {
    const response = await mittwaldApi.sslCheckReplaceCertificate.request({
      path: { certificateId: this.id },
      requestBody: {
        certificate,
        privateKey,
      },
    });

    if (response.status === 200) {
      if (response.content.isReplaceable) {
        return {
          isReplaceable: true,
          diff: response.content.changes ?? {},
        };
      }

      if (response.content.errors && response.content.errors[0]) {
        return {
          isReplaceable: false,
          reason: response.content.errors[0].message,
        };
      }
    }

    return {
      isReplaceable: false,
      reason: "unknown",
    };
  }

  public async editCertificate(
    certificate: string,
    privateKey: string,
  ): Promise<void> {
    const response = await mittwaldApi.sslReplaceCertificate.request({
      path: { certificateId: this.id },
      requestBody: {
        certificate,
        privateKey,
      },
    });

    assertStatus(response, 204);
  }

  public static async createOrderRequest(
    projectId: string,
    certificate: string,
    privateKey: string,
  ): Promise<SSLCertificateCreateRequestResponse> {
    const response = await mittwaldApi.sslCreateCertificateRequest.request({
      requestBody: {
        certificate,
        projectId,
        privateKey,
      },
    });

    assertStatus(response, 201);
    return response.content;
  }

  public static async order(
    orderRequestId: string,
    projectId: string,
  ): Promise<{
    orderId: string;
  }> {
    const response = await mittwaldApi.orderCreateOrder.request({
      requestBody: {
        orderType: "externalCertificate",
        orderData: {
          certificateRequestId: orderRequestId,
          projectId,
        },
      },
    });

    assertStatus(response, 201);
    return response.content;
  }

  public checkMatchesHostname(hostname: string): boolean {
    return checkHostnameMatchesWildcards(hostname, [
      ...this.dnsNames,
      this.commonName,
    ]);
  }
}

export default SSLCertificate;

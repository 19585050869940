import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { MittwaldApi } from "../../../api/Mittwald";
import ModelRelationType from "./ModelRelationType";

export type ModelRelationApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Messaging_AggregateReference;

export abstract class ModelRelation {
  public readonly type: ModelRelationType;
  public readonly id: string;

  protected constructor(id: string, type: ModelRelationType) {
    this.id = id;
    this.type = type;
  }

  public toText(): I18nDefinition {
    return {
      id: "itemNotFound",
      values: { item: `${this.type.aggregate}@${this.id}` },
    };
  }
}

export default ModelRelation;

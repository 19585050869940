import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Person from "./Person";

export type ProfileApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Signup_Profile &
    Omit<MittwaldApi.Components.Schemas.De_Mittwald_V1_User_User, "person">;

export interface DeleteProfileInputs {
  password: string;
  currentWeekday: string;
  multiFactorCode?: string;
}

export class Profile {
  public readonly data: ProfileApiSchema;
  public readonly userId: string | undefined;
  public readonly person: Person | undefined;

  private constructor(data: ProfileApiSchema) {
    this.data = Object.freeze(data);
    this.userId = data.userId;

    this.person = Person.fromApiData(
      data.person ?? { firstName: "", lastName: "" },
    );
  }

  public static fromApiData(data: ProfileApiSchema): Profile {
    return new Profile(data);
  }

  public static useLoadById(id: string): Profile {
    const data = mittwaldApi.userGetUser
      .getResource({
        path: {
          userId: id,
        },
      })
      .useWatchData();

    return Profile.fromApiData(data);
  }

  public static useMe(): Profile {
    const data = mittwaldApi.userGetUser
      .getResource({ path: { userId: "self" } })
      .useWatchData();
    return Profile.fromApiData(data);
  }

  public equals(other: Profile): boolean {
    return (
      this.userId !== undefined &&
      other.userId !== undefined &&
      other.userId === this.userId
    );
  }

  public static async delete(
    values: DeleteProfileInputs,
  ): Promise<409 | 400 | void> {
    const response = await mittwaldApi.userDeleteUser.request({
      requestBody: {
        password: values.password,
        multiFactorCode: values.multiFactorCode,
      },
    });

    if (response.status === 409 || response.status === 400) {
      return response.status;
    }

    assertStatus(response, 200);
  }
}

export default Profile;

import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoExtensionInstance,
  demoExtensions,
  demoContributor,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockExtensionListExtensions(
    {},
    { status: 200, content: demoExtensions },
  );

  mittwaldApiRequestMocking.mockExtensionListExtensionInstances({}, (req) => ({
    status: 200,
    content: [demoExtensionInstance(req.query.context)],
  }));

  mittwaldApiRequestMocking.mockExtensionGetExtension(
    { path: { extensionId: "*" } },
    { status: 200, content: demoExtensions[0] },
  );

  mittwaldApiRequestMocking.mockExtensionCreateExtensionInstance({}, (req) => {
    console.log(req.requestBody);
    return { status: 201, content: { id: "1" } };
  });

  mittwaldApiRequestMocking.mockExtensionDeleteExtensionInstance(
    { path: { extensionInstanceId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockExtensionEnableExtensionInstance(
    { path: { extensionInstanceId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockExtensionDisableExtensionInstance(
    { path: { extensionInstanceId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockExtensionGetExtensionInstance(
    { path: { extensionInstanceId: "*" } },
    { status: 200, content: demoExtensionInstance("project") },
  );

  mittwaldApiRequestMocking.mockExtensionGetContributor(
    { path: { contributorId: "*" } },
    {
      status: 200,
      content: demoContributor,
    },
  );

  mittwaldApiRequestMocking.mockExtensionListContributors(
    {},
    { status: 200, content: [demoContributor] },
  );
};

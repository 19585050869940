/**
 * Normalize a string for search purposes.
 */
const forFilter = (term: string): string => term.toLowerCase().trim();

/**
 * Whether the first string includes the seconds string.
 */
export const includeFilter = (first?: string, second?: string): boolean =>
  first && second ? forFilter(first).includes(forFilter(second)) : false;

import { useAction } from "@mittwald/flow-components/dist/lib/actions/actionFactory";
import {
  ActionTriggerOptions,
  ActionTriggerResult,
  BaseAction,
} from "@mittwald/flow-components/dist/lib/actions/BaseAction";
import { ChainedAction } from "@mittwald/flow-components/dist/lib/actions/ChainedAction";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { InstanceOf } from "../../lib/InstanceOf";
import { Role } from "./acl/Role";
import {
  ModelAction,
  ModelActionConfigWithArgs,
  ModelMethod,
} from "./ModelAction";
import { ModelActionBuilderRegistry } from "./ModelActionBuilderRegistry";

class AltAction extends ChainedAction {
  private readonly altAction: BaseAction;

  public constructor(anyAltAction: AnyAction, baseAction: BaseAction) {
    const altAction = useAction(anyAltAction);
    super([altAction], {
      accessCheck: baseAction.options.accessCheck,
    });
    this.altAction = altAction;
  }

  protected triggerImplementation(
    options: ActionTriggerOptions,
  ): ActionTriggerResult {
    return this.altAction.triggerWithOptions(options);
  }
}

type AltActionFactory<
  TModel,
  TInstance extends InstanceOf<TModel>,
  TMethod extends ModelMethod<TInstance>,
> = (modelAction: ModelAction<TInstance, TMethod>) => AnyAction;

export interface ModelActionBuilderConfig<
  TModel,
  TInstance extends InstanceOf<TModel>,
  TMethod extends ModelMethod<TInstance>,
> {
  model: TModel | TModel[];
  method: TMethod;
  roles: Role[];
  isAvailable?: (model: TInstance) => boolean;
  altAction?: AltActionFactory<TModel, TInstance, TMethod>;
}

type MethodArgs<
  TModel,
  TMethod extends ModelMethod<TModel>,
> = TModel[TMethod] extends (...args: infer TArgs) => any ? TArgs | [] : never;

export class ModelActionBuilder<
  TModel,
  TInstance extends InstanceOf<TModel>,
  TMethod extends ModelMethod<TInstance>,
> {
  private readonly config: Readonly<
    ModelActionBuilderConfig<TModel, TInstance, TMethod>
  >;

  public constructor(
    config: ModelActionBuilderConfig<TModel, TInstance, TMethod>,
  ) {
    this.config = Object.freeze(config);
  }

  public build(
    model: TInstance | undefined,
    ...args: MethodArgs<TInstance, TMethod>
  ): BaseAction {
    const baseAction = this.buildBase(model, ...args);

    if (this.config.altAction) {
      return new AltAction(this.config.altAction(baseAction), baseAction);
    }

    return baseAction;
  }

  public buildBase(
    model: TInstance | undefined,
    ...args: MethodArgs<TInstance, TMethod>
  ): ModelAction<TInstance, TMethod> {
    const { method, roles, isAvailable = () => true } = this.config;

    if (model === undefined) {
      return new ModelAction<any, any>({
        model: undefined,
        isAvailable: false,
        roles: [],
        method: "undefined",
      });
    }

    const config = {
      model,
      method,
      roles,
      isAvailable: isAvailable(model),
      args,
    } as any as ModelActionConfigWithArgs<TInstance, TMethod>;

    return new ModelAction(config);
  }

  public static build<TInstance, TMethod extends ModelMethod<TInstance>>(
    model: TInstance | undefined,
    method: TMethod,
    ...args: MethodArgs<TInstance, TMethod>
  ): BaseAction {
    if (model === undefined) {
      return new ModelAction<any, any>({
        model: undefined,
        isAvailable: false,
        roles: [],
        method: "undefined",
      });
    }

    return ModelActionBuilderRegistry.get(model, method).build(model, ...args);
  }

  public static buildBase<TModel, TMethod extends ModelMethod<TModel>>(
    model: TModel,
    method: TMethod,
    ...args: MethodArgs<TModel, TMethod>
  ): ModelAction<TModel, TMethod> {
    return ModelActionBuilderRegistry.get(model, method).buildBase(
      model,
      ...args,
    );
  }
}

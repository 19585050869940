import { FC, useEffect } from "react";
import TrackingAction from "../TrackingAction";
import { VirtualPageViewTrackingProps, WidgetTrackingTypes } from "../types";

export const VirtualPageViewTracking: FC<VirtualPageViewTrackingProps> = (
  props,
) => {
  const { name, identifier } = props;
  const trackingAction = new TrackingAction(
    identifier,
  ).ofType<WidgetTrackingTypes>();

  useEffect(() => {
    void trackingAction.triggerAnalytics("virtualPageView", {
      name,
    });
  }, []);

  return null;
};

export default VirtualPageViewTracking;

import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import ListModel from "../misc/ListModel";
import { ContainerImageConfigExposedPort } from "./Container";
import { ContainerPort, ContainerPortFormData } from "./ContainerPort";

export class ContainerPortList extends ListModel<ContainerPort> {
  public static fromApiData(data: string[]): ContainerPortList {
    return new ContainerPortList(data.map((p) => ContainerPort.fromApiData(p)));
  }

  public static fromFormData(data: ContainerPortFormData[]): ContainerPortList {
    return new ContainerPortList(
      data.map((p) => ContainerPort.fromFormData(p)),
    );
  }

  public static fromImageMeta(
    data: ContainerImageConfigExposedPort[],
  ): ContainerPortList {
    return new ContainerPortList(
      data.map((p) => ContainerPort.fromApiData(p.port)),
    );
  }

  public getSelectOptions(externalOnly?: boolean): SelectOptions {
    return this.items.map((p) => {
      const portString = p.toString(externalOnly);
      return { value: portString, label: { text: portString } };
    });
  }

  public asFormData(): ContainerPortFormData[] {
    return this.items.map((p) => p.asFormData());
  }

  public asStringArray(): string[] {
    return this.items.map((p) => p.toString());
  }
}

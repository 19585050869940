import Box from "@mittwald/flow-components/dist/components/Box";
import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.base.m};
  padding-block: ${spacing.base.m};
`;

export default Container;

import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import setLastEventRequestHeader from "@mittwald/flow-app-utils/dist/api/setLastEventRequestHeader";
import {
  ingressListIngressesCompatibleWithCertificate,
  MittwaldApiClient,
} from "../Mittwald";
import defaultOptions from "./defaultOptions";
import { setAccessToken } from "./hooks/setAccessToken";
import { waitForTokenRefresh } from "./hooks/waitForTokenRefresh";

export const mittwaldApiHttpClient = new KyClient({
  ky: {
    ...defaultOptions(),
    prefixUrl: import.meta.env.VITE_APP_API_URI,
    hooks: {
      beforeRequest: [
        waitForTokenRefresh,
        (request) =>
          setLastEventRequestHeader(request, (request) => {
            return (
              request.method === "GET" ||
              new URL(request.url).pathname ===
                ingressListIngressesCompatibleWithCertificate.path
            );
          }),
        setAccessToken,
      ],
    },
  },
});

export const mittwaldApiClient = new MittwaldApiClient(mittwaldApiHttpClient);
MittwaldApiClient.setInstance(mittwaldApiClient);

export default mittwaldApiClient;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Contract from "./Contract";
import TerminationBase from "./TerminationBase";

export type ContractTerminationApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_Termination;

export class ContractTermination extends TerminationBase {
  public readonly contract: Contract;

  private constructor(contract: Contract, data: ContractTerminationApiData) {
    super(
      DateTime.fromISO(data.scheduledAtDate),
      DateTime.fromISO(data.targetDate),
      data.reason,
    );
    this.contract = contract;
  }

  public static fromApiData(
    contract: Contract,
    data: ContractTerminationApiData,
  ): ContractTermination {
    return new ContractTermination(contract, data);
  }

  public async cancel(): Promise<void> {
    const response =
      await mittwaldApi.contractCancelContractTermination.request({
        path: {
          contractId: this.contract.id,
        },
      });

    assertStatus(response, 200);
  }
}

export default ContractTermination;

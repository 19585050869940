import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePromise } from "@mittwald/react-use-promise";
import { DateTime } from "luxon";
import semverCompare from "semver-compare";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import UserInputList from "../misc/userInput/UserInputList";

export type SystemSoftwareVersionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_SystemSoftwareVersion;

export interface FeePeriod {
  feeValidFrom?: string;
  feeValidUntil?: string;
  monthlyPrice: number;
}

export class SystemSoftwareVersion {
  public readonly data: SystemSoftwareVersionApiData;
  public readonly userInputs: UserInputList;

  private constructor(data: SystemSoftwareVersionApiData) {
    this.data = Object.freeze(data);
    this.userInputs = UserInputList.fromSoftwareVersionData(this, data);
  }

  public static fromApiData(
    systemSoftwareName: string,
    data: SystemSoftwareVersionApiData,
  ): SystemSoftwareVersion {
    return new SystemSoftwareVersion(data);
  }

  public static useLoadById(
    systemSoftwareName: string,
    systemSoftwareId: string,
    systemSoftwareVersionId: string,
  ): SystemSoftwareVersion {
    const response = usePromise(
      mittwaldApi.appGetSystemsoftwareversion.request,
      [{ path: { systemSoftwareVersionId, systemSoftwareId } }],
      {
        autoRefresh: { hours: 1 },
      },
    );

    assertStatus(response, 200);

    return new SystemSoftwareVersion(response.content);
  }

  public static useTryLoadById(
    systemSoftwareId?: string,
    systemSoftwareVersionId?: string,
  ): SystemSoftwareVersion | undefined {
    const data = mittwaldApi.appGetSystemsoftwareversion
      .getResource(
        systemSoftwareId && systemSoftwareVersionId
          ? {
              path: { systemSoftwareId, systemSoftwareVersionId },
            }
          : null,
      )
      .useWatchData();

    return data ? new SystemSoftwareVersion(data) : undefined;
  }

  public static async requestVersion(
    systemSoftwareName: string,
    systemSoftwareId: string,
    systemSoftwareVersionId: string,
  ): Promise<SystemSoftwareVersion> {
    const versionResponse =
      await mittwaldApi.appGetSystemsoftwareversion.request({
        path: {
          systemSoftwareVersionId,
          systemSoftwareId,
        },
      });

    assertStatus(versionResponse, 200);

    return SystemSoftwareVersion.fromApiData(
      systemSoftwareName,
      versionResponse.content,
    );
  }

  public compare(other: SystemSoftwareVersion): 0 | 1 | -1 {
    return semverCompare(this.data.internalVersion, other.data.internalVersion);
  }

  public checkCurrentFee(): FeePeriod | undefined {
    if (this.data.fee && "periods" in this.data.fee) {
      const period = this.data.fee.periods.find(
        (period) =>
          period.feeValidFrom &&
          DateTime.fromISO(period.feeValidFrom) <= DateTime.now() &&
          (!period.feeValidUntil ||
            DateTime.fromISO(period.feeValidUntil) > DateTime.now()),
      );
      if (period) {
        return { ...period, monthlyPrice: period.monthlyPrice / 100 };
      }
    }
  }

  public checkImminentFee(): FeePeriod | undefined {
    if (
      this.data.fee &&
      "periods" in this.data.fee &&
      !this.checkCurrentFee()
    ) {
      const period = this.data.fee.periods.find(
        (period) =>
          period.feeValidFrom &&
          DateTime.fromISO(period.feeValidFrom) > DateTime.now() &&
          DateTime.fromISO(period.feeValidFrom) <
            DateTime.now().plus({ month: 6 }),
      );

      if (period) {
        return { ...period, monthlyPrice: period.monthlyPrice / 100 };
      }
    }

    return undefined;
  }

  public checkImminentExpiryDate(): string | undefined {
    if (
      this.data.expiryDate &&
      DateTime.fromISO(this.data.expiryDate) < DateTime.now().plus({ month: 6 })
    ) {
      return this.data.expiryDate;
    }

    return undefined;
  }
}

export default SystemSoftwareVersion;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { AnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { SalutationApiSchema } from "../misc/Salutation";
import Profile from "../signup/Profile";

export type UserApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_User_User;

export interface UpdatePersonalInformationInputs {
  person: {
    firstName: string;
    lastName: string;
    title: SalutationApiSchema;
  };
}

export interface AddPhoneNumberInputs {
  phoneNumber: string;
}

export class User {
  public readonly data: UserApiSchema;
  public readonly id: string;
  public readonly email: string | undefined;
  public readonly fullName: string;

  private constructor(data: UserApiSchema) {
    this.data = Object.freeze(data);
    this.id = data.userId;
    this.email = data.email;
    this.fullName = `${data.person.firstName} ${data.person.lastName}`;
  }

  public static fromApiData(data: UserApiSchema): User {
    return new User(data);
  }

  public static useLoadById(id: string): User {
    const data = mittwaldApi.userGetUser
      .getResource({
        path: {
          userId: id,
        },
      })
      .useWatchData();

    return User.fromApiData(data);
  }

  public static useMe(): User {
    const data = mittwaldApi.userGetUser
      .getResource({ path: { userId: "self" } })
      .useWatchData();

    return User.fromApiData(data);
  }

  public static async me(): Promise<User> {
    const data = await mittwaldApi.userGetUser.request({
      path: { userId: "self" },
    });
    assertStatus(data, 200);
    return User.fromApiData(data.content);
  }

  public static async getById(id: string): Promise<User> {
    const data = await mittwaldApi.userGetUser.request({
      path: { userId: id },
    });

    assertStatus(data, 200);

    return User.fromApiData(data.content);
  }

  public useIsMe(): boolean {
    return User.useMe().equals(this);
  }

  public useProfile(): Profile {
    return Profile.useLoadById(this.id);
  }

  public equals(other: User): boolean {
    return this.id === other.id;
  }

  public static tryUseMe(): User | undefined {
    const data = mittwaldApi.userGetUser
      .getResource({ path: { userId: "self" } })
      .useWatchData({
        throwOnError: false,
        optional: true,
      });

    if (data) {
      return User.fromApiData(data);
    }
  }

  public verifyPhoneNumber = async (
    phoneNumber: string,
    code: string,
    rejectionAnimation: AnimationController,
  ): Promise<void | false> => {
    const response = await mittwaldApi.userVerifyPhoneNumber.request({
      path: {
        userId: this.id,
      },
      requestBody: {
        phoneNumber,
        code,
      },
    });

    if (response.status === 400) {
      rejectionAnimation.start();
      return false;
    }

    assertStatus(response, 204);
  };

  public async updatePersonalInformation(
    values: UpdatePersonalInformationInputs,
  ): Promise<void> {
    const response = await mittwaldApi.userUpdatePersonalInformation.request({
      path: {
        userId: this.id,
      },
      requestBody: {
        person: {
          firstName: values.person.firstName,
          lastName: values.person.lastName,
          title: values.person.title,
        },
      },
    });

    assertStatus(response, 204);
  }

  public async addPhoneNumber(values: AddPhoneNumberInputs): Promise<void> {
    const response = await mittwaldApi.userAddPhoneNumber.request({
      path: {
        userId: this.id,
      },
      requestBody: {
        phoneNumber: values.phoneNumber,
      },
    });

    assertStatus(response, 204);
  }

  public async deletePhoneNumber(): Promise<void> {
    const response = await mittwaldApi.userRemovePhoneNumber.request({
      path: {
        userId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public async removeAvatar(): Promise<void> {
    const response = await mittwaldApi.userRemoveAvatar.request({
      path: { userId: this.id },
    });

    assertStatus(response, 204);
  }

  public async requestAvatarUpload(): Promise<string> {
    const response = await mittwaldApi.userRequestAvatarUpload.request({
      path: {
        userId: this.id,
      },
    });

    assertStatus(response, 200);

    return response.content.refId;
  }
}

export default User;

import { MittwaldApi } from "../../../api/Mittwald";

type ApiSchema = NonNullable<
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_UserInput["positionMeta"]
>;

export class UserInputPositionMeta {
  public readonly section?: string;
  public readonly step?: string;
  public readonly index?: number;

  private constructor(index?: number, step?: string, section?: string) {
    this.section = section;
    this.step = step;
    this.index = index;
  }
  public static fromApiSchema(data: ApiSchema): UserInputPositionMeta {
    const section = "section" in data ? data.section : undefined;
    const step = "step" in data ? data.step : undefined;
    return new UserInputPositionMeta(data.index, step, section);
  }
}

export default UserInputPositionMeta;

import { useOptionalFormContext } from "@mittwald/flow-components/dist/components/Form";
import {
  HookSourcedValue,
  useUnpackedValue,
} from "@mittwald/flow-lib/dist/hooks/useUnpackedValue";
import React, { FC, PropsWithChildren, useEffect } from "react";

export const DisableForm: FC<
  PropsWithChildren<{ disable?: HookSourcedValue<boolean> }>
> = (props) => {
  const { children } = props;
  const disable = useUnpackedValue(props.disable) ?? true;
  const form = useOptionalFormContext();

  useEffect(() => {
    if (!form || !disable) {
      return;
    }
    form.disableSubmit(true);

    return () => {
      form.disableSubmit(false);
    };
  }, [form, disable]);

  return <>{children}</>;
};

export default DisableForm;

import getClass from "../../../lib/getClass";
import { InstanceOf } from "../../../lib/InstanceOf";
import Customer from "../../customer/Customer";
import Project from "../../project/Project";
import ProjectBase from "../../project/ProjectBase";

type ParentModelResolver<M, R> = (model: InstanceOf<M>) => R | undefined;

export class AclRootModelResolver<T> {
  private readonly model: T;
  private readonly useParent: ParentModelResolver<T, any>;

  public static readonly all = new Map<any, AclRootModelResolver<any>>();

  private static readonly fallbackRootModelResolver =
    new AclRootModelResolver<any>(undefined, () => {
      const project = Project.useTryLoadByPathParam();
      const customer = Customer.useTryLoadByPathParam();
      return customer ?? project;
    });

  private constructor(model: T, useParent: ParentModelResolver<T, any>) {
    this.model = model;
    this.useParent = useParent;
  }

  public useProject(modelInstance: InstanceOf<T>): ProjectBase | undefined {
    if (modelInstance instanceof ProjectBase) {
      return modelInstance;
    }

    const parent = this.useParent(modelInstance);

    if (parent) {
      if (parent instanceof ProjectBase) {
        return parent;
      }

      return AclRootModelResolver.get(getClass(parent)).useProject(parent);
    }
  }

  public useCustomer(modelInstance: InstanceOf<T>): Customer | undefined {
    if (modelInstance instanceof Customer) {
      return modelInstance;
    }

    const parent = this.useParent(modelInstance);

    if (parent) {
      if (parent instanceof Customer) {
        return parent;
      }

      return AclRootModelResolver.get(getClass(parent)).useCustomer(parent);
    }
  }

  public static register<T>(
    model: T,
    useParent: ParentModelResolver<T, any>,
  ): void {
    this.all.set(model, new AclRootModelResolver<T>(model, useParent));
  }

  public static get<T>(model: T): AclRootModelResolver<T> {
    const result = this.all.get(getClass(model));

    if (!result) {
      return this.fallbackRootModelResolver;
    }

    return result;
  }
}

export default AclRootModelResolver;

import { MittwaldApi } from "../../api/Mittwald";
import CustomerModelRelation from "../customer/CustomerModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import ProjectModelRelation from "../project/ProjectModelRelation";
import ServerModelRelation from "../server/ServerModelRelation";

export type ConversationCategoryApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_Category;

export enum ConversationCategoryName {
  general = "general",
  project = "project",
  server = "placementgroup",
  customer = "customer",
}

export type ConversationCategoryNameLoose = ConversationCategoryName | string;

export enum ConversationSubCategoryName {
  general = "general",
  other = "other",
  articles = "articles",
  invoices = "invoices",
  workload = "workload",
  apps = "apps",
  domains = "domains",
  emails = "emails",
  databases = "databases",
  ssh = "ssh",
}

export type ConversationSubCategoryNameLoose =
  | ConversationSubCategoryName
  | string;

export class ConversationCategory {
  public readonly id: string;
  public readonly name: ConversationCategoryNameLoose;
  public readonly data: ConversationCategoryApiSchema;
  public readonly supportsRelation: boolean;

  private constructor(data: ConversationCategoryApiSchema) {
    this.id = data.categoryId;
    this.name = data.name;
    this.data = Object.freeze(data);
    this.supportsRelation =
      this.getModelRelationType() !== ModelRelationType.unknown;
  }

  public static fromApiData(
    data: ConversationCategoryApiSchema,
  ): ConversationCategory {
    return new ConversationCategory(data);
  }

  public compare(other: ConversationCategory, sortOrder: string[]): number {
    const currentIndex = sortOrder.includes(this.name)
      ? sortOrder.indexOf(this.name)
      : Number.MAX_SAFE_INTEGER;
    const otherIndex = sortOrder.includes(other.name)
      ? sortOrder.indexOf(other.name)
      : Number.MAX_SAFE_INTEGER;

    return currentIndex === otherIndex ? 0 : currentIndex < otherIndex ? -1 : 1;
  }

  public getModelRelationType(): ModelRelationType {
    switch (this.name) {
      case ConversationCategoryName.server:
        return ServerModelRelation.type;
      case ConversationCategoryName.project:
        return ProjectModelRelation.type;
      case ConversationCategoryName.customer:
        return CustomerModelRelation.type;
    }

    return ModelRelationType.unknown;
  }
}

export default ConversationCategory;

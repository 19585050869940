import { MittwaldApi } from "../../api/Mittwald";
import UserInputRecordList from "../misc/userInput/UserInputRecordList";

type ApiSchema = MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_Domain;

export class DomainHandles {
  public readonly admin?: UserInputRecordList;
  public readonly owner?: UserInputRecordList;
  public readonly desiredAdmin?: UserInputRecordList;
  public readonly desiredOwner?: UserInputRecordList;
  public readonly adminIsOwner: boolean;

  private constructor(
    owner?: UserInputRecordList,
    admin?: UserInputRecordList,
    desiredOwner?: UserInputRecordList,
    desiredAdmin?: UserInputRecordList,
  ) {
    this.admin = admin;
    this.owner = owner;
    this.desiredOwner = desiredOwner;
    this.desiredAdmin = desiredAdmin;
    this.adminIsOwner = admin === undefined || admin.isEmpty();
  }

  public static fromApiData(data: ApiSchema): DomainHandles {
    const owner = data.handles.ownerC.current.handleFields
      ? UserInputRecordList.fromApiData(
          data.handles.ownerC.current.handleFields,
        )
      : undefined;

    const admin = data.handles.adminC?.current.handleFields
      ? UserInputRecordList.fromApiData(
          data.handles.adminC.current.handleFields,
        )
      : undefined;

    const desiredOwner = data.handles.ownerC.desired?.handleFields
      ? UserInputRecordList.fromApiData(
          data.handles.ownerC.desired.handleFields,
        )
      : undefined;

    const desiredAdmin = data.handles.adminC?.desired?.handleFields
      ? UserInputRecordList.fromApiData(
          data.handles.adminC.desired.handleFields,
        )
      : undefined;

    return new DomainHandles(owner, admin, desiredOwner, desiredAdmin);
  }
}

export default DomainHandles;

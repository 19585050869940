import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { ProjectRoleName } from "./";

export type ProjectInviteApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_ProjectInvite;

export interface ProjectInviteCreateInputs {
  message?: string;
  role: ProjectRoleName;
  mailAddress: string;
  membershipExpiresAt?: string;
}

export class ProjectInvite {
  public readonly data: ProjectInviteApiData;
  public readonly id: string;
  public readonly invitedTo: string;

  private constructor(data: ProjectInviteApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.invitedTo = data.projectDescription;
  }

  public static fromApiData(data: ProjectInviteApiData): ProjectInvite {
    return new ProjectInvite(data);
  }

  public static useTryLoadById(id: string): ProjectInvite | undefined {
    const invite = mittwaldApi.projectGetProjectInvite
      .getResource({
        path: {
          projectInviteId: id,
        },
      })
      .useWatchData({ optional: true });

    return invite && "projectId" in invite
      ? new ProjectInvite(invite)
      : undefined;
  }

  public static async createNew(
    values: ProjectInviteCreateInputs,
    projectId: string,
  ): Promise<string | "inviteExists" | "memberExists"> {
    const response = await mittwaldApi.projectCreateProjectInvite.request({
      path: { projectId },
      requestBody: {
        role: values.role,
        membershipExpiresAt: values.membershipExpiresAt,
        message: values.message,
        mailAddress: values.mailAddress,
      },
    });

    if (response.status === 409) {
      if (
        response.content.message ===
        "an invite for the given mail-address already exists"
      ) {
        return "inviteExists";
      }

      if (response.content.message === "user is already member of project") {
        return "memberExists";
      }
    }

    assertStatus(response, 201);

    return response.content.id;
  }

  public async acceptInvite(): Promise<void> {
    const response = await mittwaldApi.projectAcceptProjectInvite.request({
      path: { projectInviteId: this.id },
      requestBody: {},
    });

    assertStatus(response, 204);
  }

  public async declineInvite(): Promise<void> {
    const response = await mittwaldApi.projectDeclineProjectInvite.request({
      path: { projectInviteId: this.id },
    });

    assertStatus(response, 204);
  }

  public async revokeInvite(): Promise<void> {
    const response = await mittwaldApi.projectDeleteProjectInvite.request({
      path: {
        projectInviteId: this.id,
      },
    });

    assertStatus(response, 204);
  }
}

export default ProjectInvite;

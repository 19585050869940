import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { ArticleTemplateName } from "../../model/article";
import {
  demoDomainArticles,
  demoProSpaceArticles,
  demoServerArticles,
  demoStorageArticle,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockArticleGetArticle(
    { path: { articleId: "*" } },
    (req) => {
      return {
        status: 200,
        content: req.path.articleId.includes("Storage")
          ? demoStorageArticle
          : demoServerArticles.find(
              (article) => article.articleId === req.path.articleId,
            ),
      };
    },
  );

  mittwaldApiRequestMocking.mockArticleListArticles({}, (req) => ({
    status: 200,
    content: req.query.templateNames?.includes(
      ArticleTemplateName.spaceServerHosting,
    )
      ? demoServerArticles
      : req.query.templateNames?.includes(ArticleTemplateName.proSpaceHosting)
        ? demoProSpaceArticles
        : demoDomainArticles,
  }));
};

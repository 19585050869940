import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import Bytes from "../misc/Bytes";
import { Database, DatabaseTypes } from "./Database";

export type RedisDatabaseApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Database_RedisDatabase;

export const maxMemoryPolicySelectOptions: SelectOptions<string> = [
  { label: { text: "noeviction" }, value: "noeviction" },
  { label: { text: "allkeys-lru" }, value: "allkeys-lru" },
  { label: { text: "allkeys-lfu" }, value: "allkeys-lfu" },
  { label: { text: "volatile-lru" }, value: "volatile-lru" },
  { label: { text: "volatile-lfu" }, value: "volatile-lfu" },
  { label: { text: "allkeys-random" }, value: "allkeys-random" },
  { label: { text: "volatile-random" }, value: "volatile-random" },
  { label: { text: "volatile-ttl" }, value: "volatile-ttl" },
];

export interface NewRedisInputs {
  description: string;
  version: string;
  type: DatabaseTypes.redis;
  projectId: string;
}

export interface RedisConfigInputs {
  maxMemory: number;
  maxMemoryPolicy: string;
  persistent: boolean;
}

export class RedisDatabase extends Database {
  public readonly data: RedisDatabaseApiData;
  public readonly description: string;
  public readonly type: DatabaseTypes.redis;
  public readonly connectionString: string;
  public readonly maxMemory?: Bytes;
  public readonly maxMemoryPolicy?: string;
  public readonly persistent?: boolean;

  private constructor(data: RedisDatabaseApiData) {
    super(data);
    this.data = Object.freeze(data);
    this.description = data.description;
    this.type = DatabaseTypes.redis;
    this.connectionString = `redis://${data.hostname}:${data.port}`;
    this.maxMemory = data.configuration?.maxMemory
      ? Bytes.of(parseInt(data.configuration.maxMemory))
      : undefined;
    this.maxMemoryPolicy = data.configuration?.maxMemoryPolicy;
    this.persistent = data.configuration?.persistent;
  }

  public static fromApiData(data: RedisDatabaseApiData): RedisDatabase {
    return new RedisDatabase(data);
  }

  public static useLoadById(id: string): RedisDatabase {
    const data = mittwaldApi.databaseGetRedisDatabase
      .getResource({ path: { redisDatabaseId: id } })
      .useWatchData();

    return RedisDatabase.fromApiData(data);
  }

  public static useLoadByPathParam(): RedisDatabase {
    const { databaseId } = usePathParams("databaseId");

    return RedisDatabase.useLoadById(databaseId);
  }

  public static async createNew(values: NewRedisInputs): Promise<void> {
    const response = await mittwaldApi.databaseCreateRedisDatabase.request({
      path: { projectId: values.projectId },
      requestBody: {
        description: values.description,
        version: values.version,
      },
    });

    assertStatus(response, 201);
  }

  public async updateDescription(description: string): Promise<void> {
    const response =
      await mittwaldApi.databaseUpdateRedisDatabaseDescription.request({
        path: { redisDatabaseId: this.id },
        requestBody: { description },
      });

    assertStatus(response, 204);
  }

  public async updateConfiguration(values: RedisConfigInputs): Promise<void> {
    const { maxMemory, maxMemoryPolicy, persistent } = values;
    const response =
      await mittwaldApi.databaseUpdateRedisDatabaseConfiguration.request({
        path: { redisDatabaseId: this.id },
        requestBody: {
          configuration: {
            maxMemory: `${maxMemory}Mi`,
            maxMemoryPolicy,
            persistent,
          },
        },
      });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.databaseDeleteRedisDatabase.request({
      path: { redisDatabaseId: this.id },
    });

    assertStatus(response, 204);
  }
}

export default RedisDatabase;

import { DateTime, Duration } from "luxon";
import { mittwaldApi } from "../../api/Mittwald";
import sessionStore from "../../store/session";
import { Signup } from "./Signup";

const LOCK_KEY = "mStudio.refreshAccessToken";

export class TokenRefresh {
  private static refreshTimeout: number | undefined;

  public static async startAutoRefresh(): Promise<void> {
    if (!sessionStore.expires) {
      return;
    }

    if (TokenRefresh.refreshTimeout) {
      window.clearTimeout(TokenRefresh.refreshTimeout);
    }

    const expiryWithOffset = DateTime.fromJSDate(sessionStore.expires).minus({
      minutes: 5,
    });

    const now = DateTime.now();

    if (expiryWithOffset < now) {
      await TokenRefresh.refresh();
      return;
    }

    const timeout = expiryWithOffset.diff(now);

    const maxSupportedTimeout = Duration.fromObject({ days: 20 });

    if (timeout > maxSupportedTimeout) {
      return;
    }

    TokenRefresh.refreshTimeout = window.setTimeout(() => {
      void TokenRefresh.refresh();
    }, timeout.toMillis());
  }

  public static async waitForRefreshedAccessToken(
    request: Request,
  ): Promise<void> {
    const isRefreshRequest =
      request.method === "PUT" &&
      request.url.endsWith(mittwaldApi.userRefreshSession.descriptor.path);

    const isLogoutRequest =
      request.method === "PUT" &&
      request.url.endsWith(mittwaldApi.userLogout.descriptor.path);

    // Prevent deadlock
    if (isLogoutRequest || isRefreshRequest) {
      return;
    }

    // shared "read"
    await navigator.locks.request(LOCK_KEY, { mode: "shared" }, () => true);
  }

  private static async refresh(): Promise<void> {
    // exclusive refresh (write)
    await navigator.locks.request(
      LOCK_KEY,
      { ifAvailable: true, mode: "exclusive" },
      async (lock) => {
        // lock is null if lock is already held (not available)
        if (lock === null || !sessionStore.refreshToken) {
          return;
        }

        const response = await mittwaldApi.userRefreshSession.request({
          requestBody: {
            refreshToken: sessionStore.refreshToken,
          },
        });

        if (response.status !== 200) {
          await Signup.logout();
          return;
        }

        sessionStore.setAuthentication({
          expires: response.content.expiresAt,
          ...response.content,
        });
      },
    );
  }
}

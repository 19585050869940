import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ContractItem from "./ContractItem";
import TerminationBase from "./TerminationBase";

export type ContractItemTerminationApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_Termination;

export class ContractItemTermination extends TerminationBase {
  public readonly contractItem: ContractItem;

  private constructor(
    contractItem: ContractItem,
    data: ContractItemTerminationApiData,
  ) {
    super(
      DateTime.fromISO(data.scheduledAtDate),
      DateTime.fromISO(data.targetDate),
      data.reason,
    );
    this.contractItem = contractItem;
  }

  public static fromApiData(
    contractItem: ContractItem,
    data: ContractItemTerminationApiData,
  ): ContractItemTermination {
    return new ContractItemTermination(contractItem, data);
  }

  public async cancel(): Promise<void> {
    const response =
      await mittwaldApi.contractCancelContractItemTermination.request({
        path: {
          contractId: this.contractItem.contract.id,
          contractItemId: this.contractItem.id,
        },
      });

    assertStatus(response, 200);
  }
}

export default ContractItemTermination;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { Ingress, IngressTargetApiData } from "./Ingress";

export type IngressPathApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_Path;

export class IngressPath {
  public readonly data: IngressPathApiData;
  public readonly path: string;
  public readonly target: IngressTargetApiData;
  public readonly ingress: Ingress;
  public readonly url: URL;
  public static readonly pathRegex = /(^([a-z0-9._-]+\/?)*[a-z0-9]$)|(^\/$)/;

  private constructor(data: IngressPathApiData, ingress: Ingress) {
    this.data = Object.freeze(data);
    this.path = data.path;
    this.target = data.target;
    this.ingress = ingress;

    this.url = this.ingress.url;
    this.url.pathname = this.path;
  }

  public static fromApiData = (
    data: IngressPathApiData,
    ingress: Ingress,
  ): IngressPath => {
    return new IngressPath(data, ingress);
  };

  public stringify = (removeTrailingSlash: boolean): string => {
    return this.path.endsWith("/") && removeTrailingSlash
      ? this.path.slice(0, -1)
      : this.path;
  };

  public async delete(index: number): Promise<void> {
    const paths = this.ingress.data.paths;
    paths.splice(index, 1);
    const response = await mittwaldApi.ingressUpdateIngressPaths.request({
      path: { ingressId: this.ingress.id },
      requestBody: paths,
    });

    assertStatus(response, 204);
  }

  public static trimSlashes(value: string): string {
    if (value.startsWith("/")) {
      value = value.substring(1);
    }
    if (value.endsWith("/")) {
      value = value.substring(0, value.length - 1);
    }

    return value;
  }
}

import { faPlanetMoon } from "@fortawesome/pro-regular-svg-icons/faPlanetMoon";
import { faPlanetRinged } from "@fortawesome/pro-regular-svg-icons/faPlanetRinged";
import { OrderResourceSelectBoxOptions } from "@mittwald/flow-components/dist/components/OrderResourceSelect/components";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React from "react";
import App, { AppNames } from "../../app/App";
import { AppInstallationList } from "../../app/AppInstallationList";
import {
  ProSpaceArticle,
  ProSpaceArticleTag,
  ProSpaceResourceTag,
} from "../../article";
import { RedisDatabaseList } from "../../database/RedisDatabaseList";
import ServerList from "../../server/ServerList";
import SpaceServerArticleUI from "./SpaceServerArticleUI";

const proSpaceResourceTags: ProSpaceResourceTag[] = [
  ProSpaceResourceTag.balanced,
  ProSpaceResourceTag.ram,
  ProSpaceResourceTag.cpu,
];

export class ProSpaceArticleUI {
  public static readonly proSpaceLiteIcon = faPlanetMoon;
  public static readonly proSpaceIcon = faPlanetRinged;

  public static buildResourceSelectBoxOptions(
    currentArticle?: ProSpaceArticle,
  ): SelectBoxOptions {
    return proSpaceResourceTags.map((tag) => ({
      value: tag,
      title: tag,
      marked: tag === currentArticle?.resourceTag,
    }));
  }

  public static useArticleFilterSelectBoxOptions(
    currentArticle?: ProSpaceArticle,
    projectId?: string,
    isDisabled?: boolean,
  ): OrderResourceSelectBoxOptions {
    const redisDatabasesExist =
      !!projectId &&
      !RedisDatabaseList.useTryLoadAllByProjectId(projectId).isEmpty;

    const nodeAppId = !!projectId && App.useId(AppNames.node);

    const nodeApps =
      !!projectId &&
      AppInstallationList.useTryLoadAllByProjectId(projectId)?.items.filter(
        (i) => i.data.appId === nodeAppId,
      );

    const nodeAppsExist = nodeApps && nodeApps.length > 0;
    const disabledProSpaceLite =
      (isDisabled &&
        currentArticle?.articleTag === ProSpaceArticleTag.proSpace) ||
      currentArticle?.articleTag === ProSpaceArticleTag.proSpaceDedicated;
    const disabledProSpace =
      isDisabled &&
      currentArticle?.articleTag === ProSpaceArticleTag.proSpaceLite;

    return [
      {
        value: ProSpaceArticleTag.proSpace,
        title: ProSpaceArticleTag.proSpace,
        logo: ProSpaceArticleUI.proSpaceIcon,
        marked:
          currentArticle?.articleTag &&
          currentArticle.articleTag !== ProSpaceArticleTag.proSpaceLite,
        highlightText: "recommended",
        disabled: disabledProSpace,
      },
      {
        value: ProSpaceArticleTag.proSpaceLite,
        title: (
          <Text
            i18n={ProSpaceArticleTag.proSpaceLite}
            style={{ fontWeight: "normal" }}
          />
        ),
        logo: ProSpaceArticleUI.proSpaceLiteIcon,
        marked: currentArticle?.articleTag === ProSpaceArticleTag.proSpaceLite,
        disabled: redisDatabasesExist || nodeAppsExist || disabledProSpaceLite,
        tooltip:
          nodeAppsExist && redisDatabasesExist
            ? "redisDatabasesAndNodeAppsExist"
            : redisDatabasesExist
              ? "redisDatabasesExist"
              : nodeAppsExist
                ? "nodeAppsExist"
                : undefined,
      },
    ];
  }

  public static useArticleTypeOptions(customerId?: string): SelectBoxOptions {
    const serversAvailable =
      !ServerList.useLoadByOptionalCustomerId(customerId).isEmpty;

    return [
      {
        value: "proSpace",
        title: "proSpace",
        icon: ProSpaceArticleUI.proSpaceIcon,
        description: "proSpaceDescription",
        additionalInfo: { i18n: "proSpacePrice" },
      },
      {
        value: "spaceServer",
        title: "spaceServer",
        icon: SpaceServerArticleUI.icon,
        description: "spaceServerDescription",
        additionalInfo: { i18n: "spaceServerInclusive" },
        disabled: !serversAvailable,
      },
    ];
  }

  public static buildArticleSelectBoxOptions(
    articles: ProSpaceArticle[],
    currentArticle?: ProSpaceArticle,
  ): OrderResourceSelectBoxOptions {
    return articles
      .sort((l, r) => l.machineType.compare(r.machineType))
      .map((article) => ({
        value: article.id,
        title: { text: article.machineType.getDescription("cpu") },
        description: { text: article.machineType.getDescription("memory") },
        marked: currentArticle?.isEqual(article),
      }));
  }
}

export default ProSpaceArticleUI;

import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { I18nTextDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation/types";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { InvoiceSettings } from "../../../../model/customer/InvoiceSettings";

export const ReturnDebitNoteBanner: FC<{
  textLink: {
    title: I18nTextDefinition;
    action: AnyAction;
  };
  invoiceSettings?: InvoiceSettings;
}> = (props) => {
  const { textLink, invoiceSettings } = props;

  if (!invoiceSettings?.data.status?.length) {
    return null;
  }

  const isStatusReturnDebitNote = invoiceSettings.data.status.some(
    (invoiceSettingStatus) => invoiceSettingStatus.type === "returnDebitNote",
  );

  if (isStatusReturnDebitNote) {
    return (
      <Banner
        description={{
          id: "returnDebitNoteBannerDescription",
        }}
        headline="returnDebitNoteBanner"
        textLink={textLink}
        warning
      />
    );
  }

  return (
    <Banner
      description={{
        id: "returnDebitNoteWaitForInvoicePaymentBannerDescription",
        values: {
          debitPaymentStopUntil:
            invoiceSettings.data.debitPaymentStopUntil &&
            DateTime.fromISO(
              invoiceSettings.data.debitPaymentStopUntil,
            ).toFormat("dd.MM.yyyy"),
        },
      }}
      headline="returnDebitNoteWaitForInvoicePaymentBanner"
      info
    />
  );
};

export default ReturnDebitNoteBanner;

import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import ModelRelation from "../../misc/modelRelation/ModelRelation";
import ModelRelationType from "../../misc/modelRelation/ModelRelationType";
import SSLCertificate from "./SSLCertificate";

export class SSLCertificateModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "ssl",
    "certificate",
    iconFileCertificate,
  );

  public constructor(certificateId: string) {
    super(certificateId, SSLCertificateModelRelation.type);
  }

  public useSSLCertificate(): SSLCertificate {
    return SSLCertificate.useLoadById(this.id);
  }

  public useOptionalSSLCertificate(): SSLCertificate | undefined {
    return SSLCertificate.useTryLoadById(this.id);
  }
}

export default SSLCertificateModelRelation;

import { assert } from "@sindresorhus/is";
import { Schema, validate } from "jsonschema";
import { MittwaldApi } from "../../../api/Mittwald";
import ApiDataError from "../../../errors/ApiDataError";
import { AppUserInputApiData } from "../../app/AppUserInput";
import { DomainHandleUserInputData } from "../../domain/DomainHandleUserInput";
import UserInputDataSource from "./UserInputDataSource";
import UserInputPositionMeta from "./UserInputPositionMeta";

export type UserInputApiData = AppUserInputApiData | DomainHandleUserInputData;

export type AppInstallationLifecycle =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppInstallationLifecycle;

export type UserInputFormatTypes =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_UserInputFormat;

export enum UserInputName {
  domain = "host",
}

export interface UserInputFilterOptions {
  section?: string;
  step?: string;
  isCommonStep?: boolean;
  lifeCycle?: AppInstallationLifecycle;
}

export abstract class UserInput {
  public readonly data: UserInputApiData;
  public readonly dataType?: string | string[];
  public readonly name: string;
  public readonly schema: Schema;
  public readonly format: UserInputFormatTypes | undefined;
  public readonly positionMeta?: UserInputPositionMeta;
  public readonly dataSource?: UserInputDataSource;
  public readonly rules?: UserInputDataSource;
  public readonly required?: boolean = false;

  protected constructor(data: UserInputApiData) {
    this.data = Object.freeze(data);
    this.required = data.required;
    this.dataType = "dataType" in data ? data.dataType : data.schema.type;
    this.name = data.name;
    this.schema =
      "validationSchema" in data
        ? UserInput.parseSchema(data.validationSchema)
        : data.schema;
    this.format = data.format ?? undefined;

    this.positionMeta =
      "positionMeta" in data && data.positionMeta
        ? UserInputPositionMeta.fromApiSchema(data.positionMeta)
        : undefined;
    this.dataSource =
      "dataSource" in data && data.dataSource
        ? new UserInputDataSource(this, data.dataSource)
        : undefined;
  }

  private static parseSchema(schemaString: string): Schema {
    try {
      const schema = JSON.parse(schemaString);
      validate({}, schema);
      return schema as Schema;
    } catch (error) {
      assert.error(error);
      throw new ApiDataError(
        `User input validation schema is invalid: ${error.message}`,
      );
    }
  }

  public compare(other: UserInput): number {
    const otherIndex = other.positionMeta?.index ?? Number.MAX_SAFE_INTEGER;
    const thisIndex = this.positionMeta?.index ?? Number.MAX_SAFE_INTEGER;
    return otherIndex === thisIndex ? 0 : thisIndex < otherIndex ? -1 : 1;
  }

  public matches(filter: UserInputFilterOptions): boolean {
    const { section, step, lifeCycle } = filter;

    const stepMatches = step === undefined || this.positionMeta?.step === step;

    const sectionMatches =
      section === undefined || this.positionMeta?.section === section;

    const lifeCycleMatches =
      lifeCycle === undefined ||
      ("lifecycleConstraint" in this.data &&
        this.data.lifecycleConstraint === lifeCycle);

    return stepMatches && sectionMatches && lifeCycleMatches;
  }
}

export default UserInput;

import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CompanyInput: FC<Props> = (props) => {
  return (
    <TextField
      autoComplete="organization"
      disabled={props.disabled}
      label="company"
      name={props.name ?? "company"}
      rules={{ required: !!props.required, validate: () => true }}
    />
  );
};

export default CompanyInput;

import MetricsQueryResponse from "../metrics/MetricsQueryResponse";
import { Metrics, MetricsRequestOptions } from "../misc/Metrics";
import { SpaceServerProject } from "./";

export class SpaceServerProjectMetrics {
  public readonly project: SpaceServerProject;
  private readonly projectShortId: string;
  private readonly serverShortId?: string;

  private constructor(project: SpaceServerProject) {
    this.project = project;
    this.projectShortId = project.shortId;
    this.serverShortId = project.data.serverShortId;
  }

  public static of(project: SpaceServerProject): SpaceServerProjectMetrics {
    return new SpaceServerProjectMetrics(project);
  }

  public useCpuUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    return Metrics.executeRequest(
      `
      SUM (cloudhosting_project_usage_cpu_seconds_total_rate:5m{project="${this.projectShortId}"}) / 
      SUM (cloudhosting_placementgroup_limits_cpu_seconds:5m{placement_group="${this.serverShortId}"})
      `,
      options,
    );
  }

  public useMemoryUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    return Metrics.executeRequest(
      `
      SUM (cloudhosting_project_usage_memory_bytes:5m:max{project="${this.projectShortId}"}) / 
      SUM (cloudhosting_placementgroup_limits_memory_bytes:5m{placement_group="${this.serverShortId}"})
      `,
      options,
    );
  }
}

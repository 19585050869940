import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoBackup, demoBackupSchedule } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockBackupGetProjectBackup(
    { path: { projectBackupId: "*" } },
    { status: 200, content: demoBackup },
  );

  mittwaldApiRequestMocking.mockBackupListProjectBackups(
    { path: { projectId: "*" } },
    { status: 200, content: [demoBackup] },
  );

  mittwaldApiRequestMocking.mockBackupCreateProjectBackup(
    { path: { projectId: "*" } },
    { status: 201, content: demoBackup },
  );

  mittwaldApiRequestMocking.mockBackupCreateProjectBackupExport(
    { path: { projectBackupId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockBackupDeleteProjectBackup(
    { path: { projectBackupId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockBackupUpdateProjectBackupDescription(
    { path: { projectBackupId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockBackupListProjectBackupSchedules(
    { path: { projectId: "*" } },
    { status: 200, content: [demoBackupSchedule] },
  );

  mittwaldApiRequestMocking.mockBackupCreateProjectBackupSchedule(
    { path: { projectId: "*" } },
    { status: 201, content: demoBackupSchedule },
  );

  mittwaldApiRequestMocking.mockBackupGetProjectBackupSchedule(
    { path: { projectBackupScheduleId: "*" } },
    { status: 200, content: demoBackupSchedule },
  );

  mittwaldApiRequestMocking.mockBackupUpdateProjectBackupSchedule(
    { path: { projectBackupScheduleId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockBackupDeleteProjectBackupSchedule(
    { path: { projectBackupScheduleId: "*" } },
    { status: 204 },
  );
};

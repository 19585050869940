import { iconServer } from "@mittwald/flow-icons/dist/server";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Server from "./Server";
import ModelRelation from "../misc/modelRelation/ModelRelation";

export class ServerModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "project",
    "placementgroup",
    iconServer,
  );

  public constructor(serverId: string) {
    super(serverId, ServerModelRelation.type);
  }

  public useServer(): Server {
    return Server.useLoadById(this.id);
  }

  public useOptionalServer(): Server | undefined {
    return Server.useTryLoadById(this.id);
  }
}

export default ServerModelRelation;

import { MittwaldApi } from "../../api/Mittwald";

export type TldApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_TopLevel;

export type TldTransferAuthenticationType =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_TransferAuthentication
  | undefined;

export class Tld {
  public readonly data: TldApiData;
  public readonly name: string;
  public readonly isICANN: boolean;
  public readonly transferAuthType: TldTransferAuthenticationType;

  private constructor(data: TldApiData) {
    this.data = Object.freeze(data);
    this.name = data.tld;
    this.isICANN = data.irtp;
    this.transferAuthType = data.transferAuthentication;
  }

  public static fromApiData = (data: TldApiData): Tld => {
    return new Tld(data);
  };
}

export default Tld;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";

export type RegistryApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_Registry;
export type RegistryCredentials =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_SetRegistryCredentials;

type RegistryLoginType = "password" | "token" | "anonymous";

export interface UpdateRegistryInputs {
  description: string;
  url: string;
}

export interface RegistryCredentialsInputs {
  loginType: RegistryLoginType;
  username: string;
  password: string;
  token: string;
}

export interface CreateRegistryInputs extends RegistryCredentialsInputs {
  description: string;
  url: string;
}

export const accessTokenDefaultUser = "oauth2accesstoken";

export class Registry {
  public readonly data: RegistryApiData;
  public readonly id: string;
  public readonly description: string;
  public readonly url: string;
  public readonly username?: string;
  public readonly loginType: RegistryLoginType;

  private constructor(data: RegistryApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.description = data.description;
    this.url = data.uri;
    this.username = data.credentials?.username;
    this.loginType = data.credentials
      ? data.credentials.username === accessTokenDefaultUser
        ? "token"
        : "password"
      : "anonymous";
  }

  public static fromApiData(data: RegistryApiData): Registry {
    return new Registry(data);
  }

  public static useLoadById(id: string): Registry {
    const registry = mittwaldApi.containerGetRegistry
      .getResource({ path: { registryId: id } })
      .useWatchData();

    return new Registry(registry);
  }

  public static useTryLoadById(id?: string): Registry | undefined {
    if (!id) {
      return;
    }

    const registry = mittwaldApi.containerGetRegistry
      .getResource({ path: { registryId: id } })
      .useWatchData({ optional: true });

    return registry ? new Registry(registry) : undefined;
  }

  public async update(values: UpdateRegistryInputs): Promise<void> {
    const response = await mittwaldApi.containerUpdateRegistry.request({
      path: { registryId: this.id },
      requestBody: {
        uri: values.url,
        description: values.description,
      },
    });

    assertStatus(response, 204);
  }

  public async updateCredentials(
    values: RegistryCredentialsInputs,
  ): Promise<void | string> {
    const response = await mittwaldApi.containerUpdateRegistry.request({
      path: { registryId: this.id },
      requestBody: {
        credentials: Registry.formValuesToCredentials(values),
      },
    });

    if (
      response.status === 400 &&
      response.content.message === "registry credentials are invalid"
    ) {
      return "invalidCredentials";
    }

    assertStatus(response, 204);
  }

  public validateCredentials(): boolean | undefined {
    if (this.loginType === "anonymous") {
      return;
    }

    return mittwaldApi.containerValidateRegistryCredentials
      .getResource({ path: { registryId: this.id } })
      .useWatchData().valid;
  }

  private static formValuesToCredentials(
    values: RegistryCredentialsInputs,
  ): RegistryCredentials | undefined {
    switch (values.loginType) {
      case "anonymous":
        return undefined;
      case "token":
        return { username: accessTokenDefaultUser, password: values.token };
      case "password":
        return {
          username: values.username,
          password: values.password,
        };
    }
  }

  public static async createNew(
    values: CreateRegistryInputs,
    projectId: string,
  ): Promise<string> {
    const response = await mittwaldApi.containerCreateRegistry.request({
      path: { projectId },
      requestBody: {
        uri: values.url,
        description: values.description,
        credentials: this.formValuesToCredentials(values),
      },
    });

    assertStatus(response, 201);
    return response.content.id;
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.containerDeleteRegistry.request({
      path: { registryId: this.id },
    });

    assertStatus(response, 204);
  }

  public static async validateUri(uri: string): Promise<boolean> {
    const response =
      await mittwaldApi.containerValidateContainerRegistryUri.request({
        requestBody: { registryUri: uri },
      });

    if (response.status !== 200) {
      return false;
    }
    return response.content.valid;
  }
}

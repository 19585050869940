import { MittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import AppInstallation from "./AppInstallation";
import InstalledSystemSoftware from "./InstalledSystemSoftware";
import SystemSoftware from "./SystemSoftware";

type ApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_InstalledSystemSoftware;

export class InstalledSystemSoftwareList extends ListModel<InstalledSystemSoftware> {
  public static fromApiData(
    data: ApiSchema[],
    appInstallation: AppInstallation,
  ): InstalledSystemSoftwareList {
    return new InstalledSystemSoftwareList(
      data.map((item) =>
        InstalledSystemSoftware.fromApiData(item, appInstallation),
      ),
    );
  }

  public useFindByName(name: string): InstalledSystemSoftware | undefined {
    const systemSoftware = SystemSoftware.useLoadAll().find(
      (s) => s.name === name,
    );

    return this.items.find(
      (i) => i.data.systemSoftwareId === systemSoftware?.id,
    );
  }
}

export default InstalledSystemSoftwareList;

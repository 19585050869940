import { Avatar } from "@mittwald/flow-components/dist/components/Avatar";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Customer from "../../../../model/customer/Customer";
import CustomerContactInfos from "./CustomerContactInfos";

interface CustomerInfoBadgeProps {
  customerId: string;
  showContact?: boolean;
}

export const CustomerInfoBadge: FC<CustomerInfoBadgeProps> = (props) => {
  const { customerId, showContact } = props;

  const customer = Customer.useLoadById(customerId);

  return (
    <Box _flexDirection="row" _itemGap="m">
      <Avatar _size="m" initials={customer.name} />
      <Box _flexDirection="column">
        <Text i18n={{ text: customer.name }} strong />
        {!showContact && customer.contact?.definedName && (
          <Text
            i18n={{
              text: customer.contact.definedName,
            }}
          />
        )}

        {showContact && customer.contact && (
          <CustomerContactInfos contact={customer.contact} />
        )}
      </Box>
    </Box>
  );
};

export default CustomerInfoBadge;

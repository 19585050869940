import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoNotification } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockNotificationsListNotifications(
    {},
    { status: 200, content: [demoNotification] },
  );

  mittwaldApiRequestMocking.mockNotificationsCountUnreadNotifications(
    {},
    {
      status: 200,
      content: { error: 0, warning: 0, info: 1, success: 0, total: 1 },
    },
  );

  mittwaldApiRequestMocking.mockNotificationsReadAllNotifications(
    {},
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockNotificationsReadNotification(
    { path: { notificationId: "*" } },
    { status: 200 },
  );
};

import Bytes from "../misc/Bytes";
import {
  ArticleModifier,
  ArticleModifierApiData,
  ArticleFactory,
  StorageArticle,
} from "./";

export class StorageArticleModifier extends ArticleModifier {
  public static fromApiData(data: ArticleModifierApiData): ArticleModifier {
    return new StorageArticleModifier(data.articleId, data.maxArticleCount);
  }

  public useBytes(): Bytes {
    return ArticleFactory.useLoadById(this.articleId).asType(StorageArticle)
      .bytes;
  }
}

export default StorageArticleModifier;

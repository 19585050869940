import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Customer from "./Customer";
import CustomerContact, { CustomerContactInputs } from "./CustomerContact";

export type InvoiceSettingsApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_InvoiceSettings;

export type InvoicePaymentSettingsApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_PaymentSettings;

export type InvoicePaymentMethod =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Invoice_PaymentSettings["method"];

export interface InvoiceSettingsPaymentInput {
  accountHolder: string;
  iban: string;
  bic: string;
  confirmDebit: boolean;
  method: InvoicePaymentMethod;
}

export interface InvoiceSettingsInputs {
  invoiceRecipient: "contractPartner" | "other";
  invoicePeriod: number;
  paymentSettings: InvoiceSettingsPaymentInput;
  recipient: CustomerContactInputs;
  additionalEmailRecipient?: string;
}

export class InvoiceSettings {
  public readonly data: InvoiceSettingsApiData;
  public readonly customer: Customer;
  public readonly contact?: CustomerContact;

  private constructor(data: InvoiceSettingsApiData, customer: Customer) {
    this.data = Object.freeze(data);
    this.customer = customer;
    this.contact = data.recipient
      ? CustomerContact.fromApiData(data.recipient)
      : undefined;
  }

  public static fromApiData(
    data: InvoiceSettingsApiData,
    customer: Customer,
  ): InvoiceSettings {
    return new InvoiceSettings(data, customer);
  }

  public static useTryLoadByCustomer(
    customer: Customer,
  ): InvoiceSettings | undefined {
    const data = mittwaldApi.invoiceGetDetailOfInvoiceSettings
      .getResource({
        path: {
          customerId: customer.id,
        },
      })
      .useWatchData({ optional: true });

    return data ? InvoiceSettings.fromApiData(data, customer) : undefined;
  }

  public update(values: InvoiceSettingsInputs): Promise<void> {
    return InvoiceSettings.update(values, this.customer.id);
  }

  public static async update(
    values: InvoiceSettingsInputs,
    customerId: string,
  ): Promise<void> {
    const {
      invoiceRecipient,
      invoicePeriod,
      paymentSettings,
      recipient,
      additionalEmailRecipient,
    } = values;

    const response = await mittwaldApi.invoiceUpdateInvoiceSettings.request({
      path: {
        customerId,
      },
      requestBody: {
        recipient:
          invoiceRecipient === "other"
            ? {
                ...recipient,
                phoneNumbers: [recipient.phoneNumber],
              }
            : undefined,
        printedInvoices: false,
        recipientSameAsOwner: invoiceRecipient === "contractPartner",
        invoicePeriod: invoicePeriod,
        paymentSettings:
          paymentSettings.method === "debit"
            ? {
                accountHolder: paymentSettings.accountHolder,
                bic: paymentSettings.bic,
                iban: paymentSettings.iban.split(" ").join("").toUpperCase(),
                method: "debit",
              }
            : { method: "invoice" },
        additionalEmailRecipients: additionalEmailRecipient
          ? [additionalEmailRecipient]
          : undefined,
      },
    });

    assertStatus(response, 200);
  }
}

import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { Container, ContainerApiData } from "./Container";
import { Volume } from "./Volume";

export class ContainerList extends ListModel<Container> {
  public static fromApiData(data: ContainerApiData[]): ContainerList {
    return new ContainerList(data.map((d) => Container.fromApiData(d)));
  }

  public static useLoadAllByProjectId(projectId: string): ContainerList {
    const containers = mittwaldApi.containerListServices
      .getResource({ path: { projectId } })
      .useWatchData();
    return ContainerList.fromApiData(containers);
  }

  public static useLoadByVolume(volume: Volume): ContainerList {
    const allContainers = ContainerList.useLoadAllByProjectId(volume.stackId);
    const filteredContainers = allContainers.items.filter(
      (c) =>
        !!c.pendingState.volumes.items.find((s) => {
          return s.type === "volume" ? s.volume === volume.name : false;
        }) ||
        !!c.deployedState.volumes.items.find((s) => {
          return s.type === "volume" ? s.volume === volume.name : false;
        }),
    );
    return ContainerList.fromApiData(filteredContainers.map((c) => c.data));
  }

  public filter(filterFn: (container: Container) => boolean): ContainerList {
    return new ContainerList(this.items.filter((c) => filterFn(c)));
  }

  public checkIfServiceNameIsUnique(serviceName: string): boolean {
    return !this.items.find((c) => c.serviceName === serviceName);
  }

  public getSelectOptions(): SelectOptions {
    return this.items.map((c) => {
      return { value: c.id, label: { text: c.description } };
    });
  }
}

import invariant from "invariant";
import slugify from "slugify";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import AppVersion from "./AppVersion";
import SystemSoftwareVersionList from "./SystemSoftwareVersionList";

export type SystemSoftwareApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_SystemSoftware;

export enum SystemSoftwareNames {
  rediscli = "redis-cli",
  nano = "nano",
  curl = "curl",
  wpcli = "wp-cli",
  git = "git",
  composer = "composer",
  ghostscript = "gs",
  imagemagick = "im",
  zip = "zip",
  php = "php",
  mysql = "mysql",
  webp = "webp",
  node = "node",
  graphicsmagick = "gm",
  python = "python",
  midnightCommander = "mc",
  perl = "perl",
  pdftools = "pdftools",
}

export class SystemSoftware {
  public readonly data: SystemSoftwareApiData;
  public readonly name: string;
  public readonly id: string;
  public readonly slug: string;

  private constructor(data: SystemSoftwareApiData) {
    this.data = Object.freeze(data);
    this.name = data.name;
    this.id = data.id;
    this.slug = slugify(data.name, {
      lower: true,
    });
  }

  public static useLoadById(id: string): SystemSoftware {
    const data = mittwaldApi.appGetSystemsoftware
      .getResource({ path: { systemSoftwareId: id } })
      .useWatchData();
    return new SystemSoftware(data);
  }

  public static useTryLoadById(id?: string): SystemSoftware | undefined {
    const data = mittwaldApi.appGetSystemsoftware
      .getResource(id ? { path: { systemSoftwareId: id } } : null)
      .useWatchData();
    return data ? new SystemSoftware(data) : undefined;
  }

  public static useLoadAll(): SystemSoftware[] {
    const data = mittwaldApi.appListSystemsoftwares
      .getResource({})
      .useWatchData();
    return data.map((a) => new SystemSoftware(a));
  }

  public useVersionsInRange(appVersion: AppVersion): SystemSoftwareVersionList {
    return SystemSoftwareVersionList.useLoadBySystemSoftwareAndAppVersion(
      this.name,
      this.id,
      appVersion,
    );
  }

  public static usePhp(): SystemSoftware {
    const systemSoftwareList = SystemSoftware.useLoadAll();
    const php = systemSoftwareList.find(
      (s) => s.name === SystemSoftwareNames.php,
    );

    invariant(
      php,
      "PHP system software could not be found in system software list",
    );

    return php;
  }
}

export default SystemSoftware;

import iconBackup from "@mittwald/flow-icons/dist/backup";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Backup from "./Backup";

export class BackupModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "backup",
    "projectbackup",
    iconBackup,
  );

  public constructor(backupId: string) {
    super(backupId, BackupModelRelation.type);
  }

  public useBackup(): Backup {
    return Backup.useLoadById(this.id);
  }

  public useOptionalBackup(): Backup | undefined {
    return Backup.useTryLoadById(this.id);
  }
}

import { RejectionAnimation } from "@mittwald/flow-components/dist/components/RejectionAnimation";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { AnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";

export interface Props {
  rejectionAnimation: AnimationController;
  label?: I18nDefinition;
  name?: string;
  maxLength?: number;
  preventEnterPress?: boolean;
  autoComplete?: string;
}

export const CodeInput: FC<Props> = (props) => {
  const {
    rejectionAnimation,
    label,
    name = "multiFactorCode",
    maxLength = 6,
    preventEnterPress = false,
    autoComplete,
  } = props;

  return (
    <RejectionAnimation animationController={rejectionAnimation}>
      <TextField
        autoComplete={autoComplete}
        autoFocus
        label={label}
        name={name}
        rules={{ required: true, maxLength }}
        type="number"
        onKeyPress={(event) => {
          if (event.key === "Enter" && preventEnterPress) {
            event.preventDefault();
          }
        }}
      />
    </RejectionAnimation>
  );
};

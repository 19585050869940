import AppInstallation from "../app/AppInstallation";
import {
  MySqlDatabase,
  MySqlDatabaseApiData,
  NewMySqlDatabaseInputs,
} from "./MySqlDatabase";
import RedisDatabase, {
  NewRedisInputs,
  RedisDatabaseApiData,
} from "./RedisDatabase";

type DatabaseApiData = MySqlDatabaseApiData | RedisDatabaseApiData;

export type Finalizers = DatabaseApiData["finalizers"];

export type AnyDatabase = MySqlDatabase | RedisDatabase;

export type NewDatabaseInputs = NewMySqlDatabaseInputs | NewRedisInputs;

export enum DatabaseTypes {
  mySql = "MySQL",
  redis = "Redis",
}

export abstract class Database {
  public readonly id: string;
  public readonly linkedAppInstallationId?: string;
  public readonly hasFinalizers: boolean;

  public constructor(data: DatabaseApiData) {
    this.id = data.id;
    this.linkedAppInstallationId = this.getIdOfFirstFinalizer(data.finalizers);
    this.hasFinalizers = !!data.finalizers && data.finalizers.length > 0;
  }

  public static createNewDatabase(): void {}

  public abstract delete(): Promise<void>;

  public abstract updateDescription(description: string): Promise<void>;

  public getIdOfFirstFinalizer(finalizers: Finalizers): string | undefined {
    const firstFinalizer = finalizers?.[0];

    if (!firstFinalizer) {
      return undefined;
    }

    return firstFinalizer.slice(firstFinalizer.lastIndexOf(":") + 1);
  }

  public useIsPrimary(): boolean {
    return AppInstallation.useLinkedDatabase(this)?.purpose === "primary";
  }
}

import { MittwaldApi } from "../../api/Mittwald";

export type CronjobUrlDestinationApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Cronjob_CronjobUrl;

export class CronjobUrlDestination {
  public readonly data: CronjobUrlDestinationApiData;

  private constructor(data: CronjobUrlDestinationApiData) {
    this.data = Object.freeze(data);
  }

  public static fromApiData(
    data: CronjobUrlDestinationApiData,
  ): CronjobUrlDestination {
    return new CronjobUrlDestination(data);
  }
}

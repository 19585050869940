import slugify from "slugify";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import { AppActions } from "./AppInstallation";
import AppList from "./AppList";
import AppVersionList from "./AppVersionList";

export type AppApiData = MittwaldApi.Components.Schemas.De_Mittwald_V1_App_App;

export enum AppNames {
  joomla = "Joomla!",
  php = "PHP",
  shopware6 = "Shopware 6",
  shopware5 = "Shopware 5",
  typo3 = "TYPO3",
  bookstack = "BookStack",
  wordpress = "WordPress",
  matomo = "Matomo",
  contao = "Contao",
  node = "Node.js",
  staticapp = "Static Files",
  nextcloud = "Nextcloud",
  magento = "Magento",
  grav = "Grav",
  drupal = "Drupal",
  neos = "NEOS",
  moodle = "Moodle",
  prestashop = "PrestaShop",
  humhub = "HumHub",
  python = "Python",
}

export class App {
  public readonly data: AppApiData;
  public readonly name: string;
  public readonly id: string;
  public readonly slug: string;
  public readonly canStart: boolean;
  public readonly canStop: boolean;
  public readonly canRestart: boolean;
  public readonly isCustomApp: boolean;

  private constructor(data: AppApiData) {
    this.data = Object.freeze(data);
    this.name = data.name;
    this.id = data.id;
    this.slug = slugify(data.name, {
      lower: true,
    });
    this.canStart =
      data.actionCapabilities?.includes(AppActions.start) ?? false;
    this.canStop = data.actionCapabilities?.includes(AppActions.stop) ?? false;
    this.canRestart =
      data.actionCapabilities?.includes(AppActions.restart) ?? false;
    this.isCustomApp =
      data.name === AppNames.node ||
      data.name === AppNames.php ||
      data.name === AppNames.staticapp ||
      data.name === AppNames.python;
  }

  public static fromApiData(data: AppApiData): App {
    return new App(data);
  }

  public static useLoadById(id: string): App {
    const data = mittwaldApi.appGetApp
      .getResource({ path: { appId: id } })
      .useWatchData();
    return new App(data);
  }

  public static useTryLoadById(id?: string): App | undefined {
    const data = mittwaldApi.appGetApp
      .getResource(id ? { path: { appId: id } } : null)
      .useWatchData({ optional: true });
    return data ? new App(data) : undefined;
  }

  public static useId(appName: AppNames): string | undefined {
    return AppList.useLoadAll().items.find((i) => i.name === appName)?.id;
  }

  public useVersions(onlyRecommended?: boolean): AppVersionList {
    return AppVersionList.useLoadByApp(this, onlyRecommended);
  }

  public useLaterVersions(currentVersionId: string): AppVersionList {
    return AppVersionList.useLoadLaterVersions(currentVersionId, this);
  }

  public static useEmptyVersions(): AppVersionList {
    mittwaldApi.appListAppversions.getResource(null).useWatchData();
    return AppVersionList.empty();
  }
}

export default App;

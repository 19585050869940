import { ContainerEnvVariable } from "./Container";
import { ContainerVolumeRelationFormData } from "./ContainerVolumeRelation";
import { Volume } from "./Volume";

export class ContainerUtils {
  public static splitAndMapVolumeFormValuesToNewAndExisting(
    values: ContainerVolumeRelationFormData[],
    serviceName: string,
  ): [ContainerVolumeRelationFormData[], ContainerVolumeRelationFormData[]] {
    const volumesToBeCreated = values
      .filter((v) => v.volume === "createNewVolume" && v.type === "volume")
      .map((v) => ({
        ...v,
        volume: Volume.generateNewVolumeName(
          `${serviceName}${v.containerPath.toLowerCase().split("/").join("-")}`,
        ),
      }));

    const existingVolumesAndProjectPaths = values.filter(
      (v) => v.volume !== "createNewVolume",
    );

    return [volumesToBeCreated, existingVolumesAndProjectPaths];
  }

  public static mapEnvFormValuesToApiValues(values: ContainerEnvVariable[]): {
    [a: string]: string;
  } {
    return values.reduce((prev, curr) => {
      return Object.assign(prev, { [curr.key]: curr.value });
    }, {});
  }

  public static mapEnvApiValuesToModelValues(envValues: {
    [a: string]: string;
  }): ContainerEnvVariable[] {
    const envs = [];
    for (const [key, value] of Object.entries(envValues)) {
      envs.push({ key, value: value });
    }
    return envs;
  }

  public static mapEnvEntriesToText(values: ContainerEnvVariable[]): string {
    return values
      .map((env: ContainerEnvVariable) =>
        env.key || env.value
          ? `${env.key ? env.key : ""}=${env.value ? env.value : ""}`
          : "",
      )
      .join("\n");
  }

  public static mapEnvTextToEntries(
    fileContent: string,
  ): ContainerEnvVariable[] {
    return fileContent
      .split("\n")
      .filter((line) => line !== "")
      .map((line) => {
        const [key, value] = line.split(/=(.*)/s);
        return {
          key,
          value: value?.replace(/['"]/g, ""),
        } as ContainerEnvVariable;
      });
  }
}

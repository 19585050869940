import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import semverCompare from "semver-compare";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import UserInputList from "../misc/userInput/UserInputList";
import App from "./App";

export type AppVersionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppVersion;

export class AppVersion {
  public readonly data: AppVersionApiData;

  public readonly name: string;
  public readonly app: App;
  public readonly userInputs: UserInputList;
  public readonly isDocRootEditable: boolean;
  public readonly defaultDocRootPath: string;

  private constructor(app: App, data: AppVersionApiData) {
    this.data = Object.freeze(data);
    this.app = app;
    this.name = `${app.name} ${data.externalVersion}`;
    this.userInputs = UserInputList.fromAppVersionData(this, data);
    this.isDocRootEditable = data.docRootUserEditable;
    this.defaultDocRootPath = data.docRoot;
  }

  public static fromApiData(app: App, data: AppVersionApiData): AppVersion {
    return new AppVersion(app, data);
  }

  public static useLoadById(app: App, id: string): AppVersion {
    const data = mittwaldApi.appGetAppversion
      .getResource({
        path: {
          appVersionId: id,
          appId: app.id,
        },
      })
      .useWatchData();

    return AppVersion.fromApiData(app, data);
  }

  public static async requestVersion(
    app: App,
    appVersionId: string,
  ): Promise<AppVersion> {
    const versionResponse = await mittwaldApi.appGetAppversion.request({
      path: { appVersionId, appId: app.id },
    });

    assertStatus(versionResponse, 200);

    return AppVersion.fromApiData(app, versionResponse.content);
  }

  public compare(other: AppVersion): 0 | 1 | -1 {
    return semverCompare(this.data.internalVersion, other.data.internalVersion);
  }
}

export default AppVersion;

import { Project } from "../project/Project";
import { Server } from "../server/Server";
import ContractItem from "./ContractItem";

export class ContractItemReference {
  public readonly contractItem: ContractItem;
  public readonly shortId: string | undefined;
  public readonly description: string;

  private constructor(
    contractItem: ContractItem,
    shortId: string | undefined,
    description: string,
  ) {
    this.contractItem = contractItem;
    this.shortId = shortId;
    this.description = description;
  }

  public static useOf(contractItem: ContractItem): ContractItemReference {
    const ref = contractItem.reference;

    if (ref && contractItem.isProSpace) {
      const projectData = Project.useLoadById(ref.id);
      return new ContractItemReference(
        contractItem,
        projectData.data.shortId,
        projectData.data.description,
      );
    } else if (ref && contractItem.isSpaceServer) {
      const serverData = Server.useLoadById(ref.id);
      return new ContractItemReference(
        contractItem,
        serverData.data.shortId,
        serverData.data.description,
      );
    } else {
      return new ContractItemReference(
        contractItem,
        undefined,
        contractItem.description,
      );
    }
  }
}

export default ContractItemReference;

import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { FormController } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { ValueControllerInstance } from "@mittwald/flow-components/dist/hooks/useValueController";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import { CustomerList } from "../../../../../../model/customer/CustomerList";
import CustomerListUI from "../../../../../../model/ui/customer/CustomerListUI";
import VirtualPageViewTracking from "../../../../../../widgets/components/tracking/components/VirtualPageViewTracking";
import { CustomerStepFields } from "../../../types";

interface Props {
  customerModeController: ValueControllerInstance<string>;
  trackingId?: string;
  form: FormController<CustomerStepFields>;
  noReturn?: boolean;
}

export const CustomerStep: FC<Props> = ({
  customerModeController,
  trackingId,
  form,
  noReturn,
}) => {
  const customerList = CustomerList.tryUseAll();
  const customerListUI = CustomerListUI.of(customerList);
  const customerSelectOptions = customerListUI.useSelectOptions();
  const existingCustomersAvailable = customerSelectOptions.length > 0;
  const customerMode = customerModeController.watch();

  return (
    <TranslationProvider name="customerStepContent" type="component">
      <WizardStep
        form={form}
        id="customer"
        indicatorText="customerStep"
        noReturn={noReturn}
      >
        <Suspense loadingView={<LoadingView _height={250} />}>
          {trackingId && (
            <VirtualPageViewTracking
              identifier={trackingId}
              name="checkout/organisation"
            />
          )}
          <Section.Item headline="customer">
            <Text i18n="customerDescription" />
            <SelectBox.View
              controller={customerModeController}
              options={[
                {
                  value: "existing",
                  title: "existingCustomer",
                  disabled: !existingCustomersAvailable,
                },
                { value: "new", title: "newCustomer" },
              ]}
            />
            <ColumnLayout medium={[1, 1]}>
              {customerMode === "new" && (
                <TextField
                  autoFocus
                  label="name"
                  name="name"
                  rules={{ required: true }}
                />
              )}
              {customerMode === "existing" && (
                <Select
                  label="customer"
                  name="id"
                  options={customerSelectOptions}
                  placeholder="customer"
                  rules={{
                    required: true,
                  }}
                />
              )}
            </ColumnLayout>
          </Section.Item>
        </Suspense>
      </WizardStep>
    </TranslationProvider>
  );
};

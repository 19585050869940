import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Customer from "../../../../../model/customer/Customer";
import { InvoiceSettings } from "../../../../../model/customer/InvoiceSettings";
import InvoiceSettingsUI from "../../../../../model/ui/customer/InvoiceSettingsUI";
import ReturnDebitNoteBanner from "../../../components/ReturnDebitNoteBanner/ReturnDebitNoteBanner";
import ReactivateDebit from "../../modals/ReactivateDebit/ReactivateDebit";
import DebitForm from "./DebitForm";
import { InvoiceSettingsInputsWithStringInvoicePeriod } from "./PaymentSettingsPage";

interface Props {
  isWizardStep?: boolean;
  customer?: Customer;
  isDisabled?: boolean;
}

export const PaymentMethodSection: FC<Props> = (props) => {
  const { isWizardStep, customer, isDisabled } = props;

  const form = useFormContext<InvoiceSettingsInputsWithStringInvoicePeriod>();
  const watchedPaymentMethod = form.watch("paymentSettings.method");

  const invoiceSettings =
    customer && InvoiceSettings.useTryLoadByCustomer(customer);

  return (
    <Section.Item headline="paymentMethodSettings">
      {customer && watchedPaymentMethod === "debit" && !isDisabled && (
        <ReturnDebitNoteBanner
          invoiceSettings={invoiceSettings}
          textLink={{
            title: "returnDebitNoteBannerInvoicesTextLink",
            action: () =>
              showModal(
                "reactivateDebit",
                <ReactivateDebit
                  customer={customer}
                  paymentSettingsForm={form}
                />,
              ),
          }}
        />
      )}
      <SelectBox
        disabled={isDisabled}
        name="paymentSettings.method"
        options={
          invoiceSettings
            ? InvoiceSettingsUI.of(invoiceSettings).getPaymentMethodOptions()
            : InvoiceSettingsUI.paymentMethodOptions
        }
      />
      {watchedPaymentMethod === "invoice" && <Text i18n="invoiceInfo" />}
      {isWizardStep && <Text i18n="paymentMethodWizardStepDescription" />}
      {watchedPaymentMethod === "debit" && (
        <>
          <Text i18n="DebitInfo" />
          <DebitForm isDisabled={isDisabled} />
        </>
      )}
    </Section.Item>
  );
};

export default PaymentMethodSection;

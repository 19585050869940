import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import { MittwaldApiClient } from "../Mittwald";
import defaultOptions from "./defaultOptions";
import { setAccessToken } from "./hooks/setAccessToken";
import { waitForTokenRefresh } from "./hooks/waitForTokenRefresh";

export const mittwaldApiHttpClientWithoutLastEventHeader = new KyClient({
  ky: {
    ...defaultOptions(),
    prefixUrl: import.meta.env.VITE_APP_API_URI,
    hooks: {
      beforeRequest: [waitForTokenRefresh, setAccessToken],
    },
  },
});

export const mittwaldApiClientWithoutLastEventHeader = new MittwaldApiClient(
  mittwaldApiHttpClientWithoutLastEventHeader,
);

export default mittwaldApiClientWithoutLastEventHeader;

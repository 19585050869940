import { MittwaldApi } from "../../api/Mittwald";
import User from "../user/User";
import Conversation from "./Conversation";
import ConversationItem from "./ConversationItem";

export type ConversationMessageApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_Message;

export interface ConversationFile {
  id: string;
  name: string;
  status: "uploaded";
  type: string;
}

export class ConversationMessage extends ConversationItem {
  public readonly id: string;
  public readonly data: ConversationMessageApiSchema;
  public readonly userId: string | undefined;
  public readonly content: string;
  public readonly files: ConversationFile[];

  private constructor(
    conversation: Conversation,
    index: number,
    data: ConversationMessageApiSchema,
  ) {
    super(conversation, index, data);
    this.id = data.messageId;
    this.userId = data.createdBy?.userId;
    this.content = data.messageContent ?? "";
    this.files =
      data.files?.filter(
        (f): f is ConversationFile => f.status === "uploaded",
      ) ?? [];
    this.data = Object.freeze(data);
  }

  public static fromApiData(
    conversation: Conversation,
    index: number,
    data: ConversationMessageApiSchema,
  ): ConversationMessage {
    return new ConversationMessage(conversation, index, data);
  }

  public useNextMessage(): ConversationMessage | undefined {
    const messageItems = this.conversation
      .useItems()
      .filter((i) => i instanceof ConversationMessage) as ConversationMessage[];
    const newIndex = messageItems.findIndex((i) => i.id === this.id);

    return messageItems[newIndex + 1];
  }

  public useNext(): ConversationMessage | undefined {
    const nextItem = super.useNext();

    if (nextItem && nextItem instanceof ConversationMessage) {
      return nextItem;
    }
  }

  public usePrev(): ConversationMessage | undefined {
    const prevItem = super.usePrev();

    if (prevItem && prevItem instanceof ConversationMessage) {
      return prevItem;
    }
  }

  public usePrevMessage(): ConversationMessage | undefined {
    const messageItems = this.conversation
      .useItems()
      .filter((i) => i instanceof ConversationMessage) as ConversationMessage[];
    const newIndex = messageItems.findIndex((i) => i.id === this.id);

    return messageItems[newIndex - 1];
  }

  public useIsFromLoggedInUser(): boolean {
    return User.useMe().id === this.userId;
  }
}

export default ConversationMessage;

import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoCronjob, demoCronjobExecution } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockCronjobListExecutions(
    { path: { cronjobId: "*" } },
    { status: 200, content: [demoCronjobExecution] },
  );

  mittwaldApiRequestMocking.mockCronjobGetExecution(
    { path: { cronjobId: "*", executionId: "*" } },
    { status: 200, content: demoCronjobExecution },
  );

  mittwaldApiRequestMocking.mockCronjobListCronjobs(
    { path: { projectId: "*" } },
    { status: 200, content: [demoCronjob] },
  );

  mittwaldApiRequestMocking.mockCronjobGetCronjob(
    { path: { cronjobId: "*" } },
    { status: 200, content: demoCronjob },
  );

  mittwaldApiRequestMocking.mockCronjobDeleteCronjob(
    { path: { cronjobId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockCronjobUpdateCronjob(
    { path: { cronjobId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockCronjobCreateExecution(
    { path: { cronjobId: "*" } },
    { status: 201, content: demoCronjob.id },
  );

  mittwaldApiRequestMocking.mockCronjobCreateCronjob(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoCronjob.id } },
  );
};

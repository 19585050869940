import { types } from "mobx-state-tree";
import { allSalutations, SalutationApiSchema } from "../model/misc/Salutation";

export interface ProfileInformation {
  person: {
    title: SalutationApiSchema;
    firstName: string;
    lastName: string;
  };
  email: string;
  password: string;
}

const model = types
  .model({
    profileInformation: types.maybe(
      types.model({
        person: types.model({
          title: types.enumeration("title", allSalutations),
          firstName: types.string,
          lastName: types.string,
        }),
        email: types.string,
        password: types.string,
      }),
    ),
    userId: types.maybe(types.string),
    isEmailInvite: types.boolean,
  })
  .actions((self) => ({
    setProfileInformation: (profileInformation: ProfileInformation) => {
      self.profileInformation = profileInformation;
    },
    areProfileInformationSet: () => !!self.profileInformation,
    setUserId: (userId: string) => {
      self.userId = userId;
    },
    setEmail: (email: string) => {
      if (self.profileInformation) {
        self.profileInformation.email = email;
      }
    },
    setIsEmailInvite: (itIs: boolean) => {
      self.isEmailInvite = itIs;
    },
    clearProfileInformation: () => {
      self.profileInformation = undefined;
      self.userId = undefined;
    },
  }));

export const registerStore = model.create({
  userId: undefined,
  profileInformation: undefined,
  isEmailInvite: false,
});

export default registerStore;

import invariant from "invariant";
import getName from "../../lib/getName";
import { InstanceOf } from "../../lib/InstanceOf";
import { ModelMethod } from "./ModelAction";
import {
  ModelActionBuilder,
  ModelActionBuilderConfig,
} from "./ModelActionBuilder";

type InstanceOrClass<T> = T & InstanceOf<T>;

export class ModelActionBuilderRegistry {
  private static readonly registry = new Map<
    any,
    Map<string, ModelActionBuilder<any, any, any>>
  >();

  public static register<
    TModel,
    TInstance extends InstanceOrClass<TModel>,
    TMethod extends ModelMethod<TInstance>,
  >(
    config: ModelActionBuilderConfig<TModel, TInstance, TMethod>,
  ): typeof ModelActionBuilderRegistry {
    const models = !Array.isArray(config.model) ? [config.model] : config.model;

    for (const model of models) {
      const builder = new ModelActionBuilder(config);

      const modelName = getName(model);
      const modelEntries =
        ModelActionBuilderRegistry.registry.get(modelName) ??
        new Map<string, ModelActionBuilder<any, any, any>>();

      modelEntries.set(config.method, builder);
      ModelActionBuilderRegistry.registry.set(modelName, modelEntries);
    }

    return ModelActionBuilderRegistry;
  }

  public static get<
    TModel,
    TInstance extends InstanceOrClass<TModel>,
    TMethod extends ModelMethod<TInstance>,
  >(
    model: TInstance,
    method: TMethod,
  ): ModelActionBuilder<TModel, TInstance, TMethod> {
    const modelName = getName(model);
    const result: ModelActionBuilder<TModel, TInstance, TMethod> | undefined =
      ModelActionBuilderRegistry.registry.get(modelName)?.get(method);

    invariant(
      result instanceof ModelActionBuilder,
      `Could not get model method ${method}`,
    );

    return result;
  }
}

import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { arrayAccessor } from "@mittwald/awesome-node-utils/misc/ArrayAccessor";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { statusTypeProps } from "@mittwald/flow-components/dist/lib/statusType";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconIngress } from "@mittwald/flow-icons/dist/ingress";
import { iconSubdomain } from "@mittwald/flow-icons/dist/subdomain";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { CheckPageStateResult } from "@mittwald/flow-lib/dist/pages/state";
import { NotFoundResourceLoadingError } from "@mittwald/flow-lib/dist/resources/ResourceLoadingError";
import {
  isDomain,
  isSubdomain as checkIsSubdomain,
} from "@mittwald/flow-lib/dist/validation/domain";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { EmailAddressList } from "../mail/EmailAddressList";
import { Project, ProjectMembership } from "../project";
import DomainUI, { DomainTypes } from "../ui/domain/DomainUI";
import Domain, { SubdomainInputs, VHostInputs } from "./Domain";
import DomainName from "./DomainName";
import { IngressList } from "./IngressList";
import { IngressPath, IngressPathApiData } from "./IngressPath";

export type IngressApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_Ingress;

export type IngressTargetApiData =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetDirectory
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetUrl
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetUseDefaultPage
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetInstallation
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetContainer;

export type IngressStateStatusType = Omit<CheckPageStateResult, "null"> &
  keyof typeof statusTypeProps;

export interface IngressStateDefinition<T extends I18nDefinition = string> {
  type: IngressStateStatusType;
  title?: T;
}

export type IngressDnsState = IngressStateDefinition<"notVerified"> | undefined;
export type IngressDomainState =
  | IngressStateDefinition<"failedOwnerChange">
  | undefined;
export type IngressGeneralState =
  | IngressStateDefinition<
      | "sslRequestInProgress"
      | "sslRequestDeadlineExceeded"
      | "authCodeMismatch"
      | "dnsValidationError"
      | I18nDefinition
    >
  | undefined;
export type IngressState =
  | IngressDomainState
  | IngressDnsState
  | IngressGeneralState;

export interface VerifyDomainError {
  errorMessage?: string;
  expectedTxtRecord?: string;
}

export interface SSLAcme {
  type: "acme";
  hasSSL: boolean;
  requestDeadline?: DateTime;
}

export interface SSLExternalCertificate {
  type: "externalSSL";
  hasSSL: boolean;
  certificateId: string;
}

export class Ingress {
  public readonly data: IngressApiData;
  public readonly id: string;
  public readonly hostname: string;
  public readonly isDefault: boolean;
  public readonly isSubdomain: boolean;
  public readonly isEnabled: boolean;
  public readonly baseTarget: IngressTargetApiData | undefined;
  public readonly ipv4Adresses: string[];
  public readonly name: DomainName;
  public readonly domain?: Domain;
  public readonly isDeleted: boolean;
  public readonly isInternalDomainWithInternalNameServers: boolean;
  public readonly projectId: string;
  public readonly ownershipVerified: boolean;
  public readonly verificationTxtRecord?: string;
  public readonly ssl: SSLAcme | SSLExternalCertificate;
  public readonly url: URL;
  public readonly dnsValidationErrors: IngressApiData["dnsValidationErrors"];

  private constructor(data: IngressApiData, domain?: Domain) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.hostname = data.hostname;
    this.isDefault = data.isDefault;
    this.isEnabled = data.isEnabled;
    this.isSubdomain = checkIsSubdomain(data.hostname) === true;
    this.baseTarget = data.paths.find((p) => p.path == "/")?.target;
    this.ipv4Adresses = data.ips.v4;
    this.projectId = data.projectId;
    this.name = new DomainName(data.hostname);
    this.domain = domain;
    this.isDeleted = domain ? domain.data.deleted : false;
    this.isInternalDomainWithInternalNameServers =
      !!domain && domain.data.usesDefaultNameserver;
    this.ownershipVerified = data.ownership.verified;
    this.verificationTxtRecord = data.ownership.txtRecord;
    this.dnsValidationErrors = data.dnsValidationErrors;

    if ("acme" in data.tls) {
      this.ssl = {
        type: "acme",
        hasSSL: data.tls.isCreated,
      };

      if (data.tls.requestDeadline) {
        this.ssl.requestDeadline = DateTime.fromISO(data.tls.requestDeadline);
      }
      if (
        this.dnsValidationErrors.some(
          (e) => e === "ERROR_ACME_CERTIFICATE_REQUEST_DEADLINE_EXCEEDED",
        )
      ) {
        this.ssl.requestDeadline = DateTime.now().minus({ minute: 5 });
      }
    } else {
      // remove acme errors when external ssl
      this.dnsValidationErrors = this.dnsValidationErrors.filter(
        (e) => e !== "ERROR_ACME_CERTIFICATE_REQUEST_DEADLINE_EXCEEDED",
      );

      this.ssl = {
        type: "externalSSL",
        hasSSL: !!data.tls.certificateId,
        certificateId: data.tls.certificateId,
      };
    }

    const protocol = this.ssl.hasSSL ? "https" : "http";
    this.url = new URL(`${protocol}://${this.data.hostname}`);
  }

  public static fromApiData = (
    data: IngressApiData,
    domain?: Domain,
  ): Ingress => {
    return new Ingress(data, domain);
  };

  public static useLoadById(ingressId: string): Ingress {
    const data = mittwaldApi.ingressGetIngress
      .getResource({ path: { ingressId } })
      .useWatchData();
    const domain = Domain.useTryLoadByHostname(data.hostname, data.projectId);

    return Ingress.fromApiData(
      data,
      domain && !domain.data.deleted ? domain : undefined,
    );
  }

  public static useTryLoadById(ingressId: string): Ingress | undefined {
    const data = mittwaldApi.ingressGetIngress
      .getResource({ path: { ingressId } })
      .useWatchData({ optional: true });

    const domain = Domain.useTryLoadByHostname(data?.hostname, data?.projectId);

    return (
      data &&
      Ingress.fromApiData(data, domain?.data.deleted ? undefined : domain)
    );
  }

  public static useLoadByPathParam(): Ingress {
    const { ingressId } = usePathParams("ingressId");
    return Ingress.useLoadById(ingressId);
  }

  public static useLoadDefaultForProject = (
    projectId: string,
  ): Ingress | undefined => {
    return IngressList.useLoadAllByProjectId(projectId).items.find(
      (i) => i.data.isDefault,
    );
  };

  public async delete(): Promise<void> {
    const response = await mittwaldApi.ingressDeleteIngress.request({
      path: { ingressId: this.id },
    });

    assertStatus(response, 204);
  }

  public getPaths(): IngressPath[] {
    return this.data.paths.map((p) => IngressPath.fromApiData(p, this));
  }

  public getTarget(): IngressTargetApiData {
    return arrayAccessor(this.getPaths()).first.target;
  }

  public mainPathTargetIsUrl(): boolean {
    const mainPath = this.data.paths.find((p) => p.path === "/");
    return !!mainPath && "url" in mainPath.target;
  }

  public mainPathTargetIsApp(): boolean {
    const mainPath = this.data.paths.find((p) => p.path === "/");
    return !!mainPath && "installationId" in mainPath.target;
  }

  public getPathByIndex(index: number): IngressPath {
    const foundPath = this.getPaths()[index];

    if (foundPath === undefined) {
      throw new NotFoundResourceLoadingError();
    }

    return foundPath;
  }

  public isVerified(): boolean {
    if (this.isDefault) {
      return true;
    }
    return this.ownershipVerified;
  }

  public hasError(): boolean {
    return (
      this.dnsValidationErrors.length > 0 ||
      (this.domain != undefined && this.domain.processes.hasFailedProcess())
    );
  }

  public useEmailAddressesExist(): boolean {
    const emailAddresses = EmailAddressList.useLoadAllByProjectId(
      this.data.projectId,
    ).useItems();

    return emailAddresses.some(
      (address) => address.getDomainPart() === this.hostname,
    );
  }

  public useSubdomainsExist(): boolean {
    const ingresses = IngressList.useLoadAllByProjectId(
      this.data.projectId,
    ).useItems();
    return ingresses.some(
      (ingress) =>
        ingress.name.domain === this.hostname &&
        ingress.hostname !== this.hostname,
    );
  }

  public static async createNew(
    values: VHostInputs | SubdomainInputs,
    projectId: string,
  ): Promise<string> {
    const response = await mittwaldApi.ingressCreateIngress.request({
      requestBody: {
        hostname: DomainUI.normalizeDashes(
          values.domainType == DomainTypes.vHost
            ? values.hostname
            : values.subdomain,
        ),
        paths: [
          {
            path: "/",
            target: DomainUI.getTargetValue(values.targetType, values),
          },
        ],
        projectId,
      },
    });

    assertStatus(response, 201);

    return response.content.id;
  }

  public async updatePaths(paths: IngressPathApiData[]): Promise<void> {
    const response = await mittwaldApi.ingressUpdateIngressPaths.request({
      requestBody: paths,
      path: { ingressId: this.id },
    });

    assertStatus(response, 204);
  }

  public moveDomain(): void {}

  public moveIngress(): void {}

  public useIsAvailableForMove(): boolean {
    const project = Project.useLoadByPathParam();
    const currentRole = ProjectMembership.useLoadOwn(project.id);
    const isAvailable = Domain.useIsAvailable(this.hostname);

    if (
      this.isSubdomain ||
      !isDomain(this.hostname) ||
      this.domain ||
      isAvailable !== "unavailable"
    ) {
      return false;
    }

    return currentRole.role.is("owner");
  }

  public useMainIngress(): Ingress | undefined {
    return IngressList.useLoadAllWithDomainByProjectId(this.data.projectId)
      .useItems()
      .find((i) => i.hostname === this.name.domain);
  }

  public async verifyDomainOwnership(): Promise<void | VerifyDomainError> {
    const response = await mittwaldApi.ingressIngressVerifyOwnership.request({
      path: { ingressId: this.id },
    });
    if (response.status === 412) {
      return {
        errorMessage: "TxtRecordDoesNotMatch",
        expectedTxtRecord: this.verificationTxtRecord,
      };
    }

    assertStatus(response, 200);
  }

  public async setAcmeCertificate(enabled?: boolean): Promise<void> {
    const response = await mittwaldApi.ingressUpdateIngressTls.request({
      path: { ingressId: this.id },
      requestBody: {
        acme: enabled ?? true,
        isCreated: false,
        requestDeadline: undefined,
      },
    });

    assertStatus(response, 200);
  }

  public async requestAcmeCertificate(): Promise<void> {
    const response =
      await mittwaldApi.ingressRequestIngressAcmeCertificateIssuance.request({
        path: { ingressId: this.id },
      });
    assertStatus(response, 204);
  }

  public async setNewCertificate(certificateId: string): Promise<void> {
    const response = await mittwaldApi.ingressUpdateIngressTls.request({
      path: { ingressId: this.id },
      requestBody: {
        certificateId,
      },
    });

    assertStatus(response, 200);
  }

  public getSSLRequestStatus(): "running" | "exceeded" | undefined {
    if (this.ssl.hasSSL || this.ssl.type !== "acme") {
      return undefined;
    }

    if (this.ssl.requestDeadline) {
      return DateTime.now() > this.ssl.requestDeadline ? "exceeded" : "running";
    }

    return undefined;
  }

  public getIcon(): IconLookup {
    return this.domain
      ? iconDomain
      : this.isDefault
        ? faHome
        : this.isSubdomain
          ? iconSubdomain
          : iconIngress;
  }

  public getCurrentState(): IngressState {
    const ingressState = this.getCurrentGeneralState();
    if (ingressState) {
      return ingressState;
    }

    const dnsState = this.getCurrentDnsState();
    if (dnsState) {
      return dnsState;
    }

    const contactDetailsState = this.getCurrentContactDetailsState();
    if (contactDetailsState) {
      return contactDetailsState;
    }

    if (this.domain?.processes.hasFailedProcess()) {
      return {
        type: "error",
      };
    }
  }

  public getCurrentContactDetailsState(): IngressDomainState {
    if (this.domain?.processes.isOwnerContactChangeFailed()) {
      return {
        type: "error",
        title: "failedOwnerChange",
      };
    }

    return undefined;
  }

  public getCurrentDnsState(): IngressDnsState {
    if (!this.isDefault && !this.ownershipVerified) {
      return {
        type: "warning",
        title: "notVerified",
      };
    }

    return undefined;
  }

  public getCurrentGeneralState(): IngressGeneralState {
    if (this.domain?.processes.isAuthCodeMismatchError()) {
      return {
        type: "error",
        title: "authCodeMismatch",
      };
    }

    if (this.dnsValidationErrors.length >= 1) {
      return {
        type: "error",
        title: "dnsValidationError",
      };
    }

    const sslRequestStatus = this.getSSLRequestStatus();
    if (sslRequestStatus) {
      if (sslRequestStatus === "exceeded") {
        return {
          type: "error",
          title: "sslRequestDeadlineExceeded",
        };
      }

      return {
        type: "info",
        title: "sslRequestInProgress",
      };
    }
  }
}

export default Ingress;

import ServerModelRelation from "../server/ServerModelRelation";
import ContractItem from "./ContractItem";

export class ContractItemDependencies {
  public readonly projectCount: number;
  public readonly contractItem: ContractItem;
  public readonly domainCount: number;

  public readonly isEmpty: boolean;

  private constructor(
    contractItem: ContractItem,
    domainCount: number,
    projectCount: number,
  ) {
    this.contractItem = contractItem;
    this.domainCount = domainCount;
    this.projectCount = projectCount;
    this.isEmpty = projectCount === 0;
  }

  public static useOf(contractItem: ContractItem): ContractItemDependencies {
    const ref = contractItem.reference;

    const domainCount = contractItem.contract.additionalItems.filter(
      (i) => i.isDomain,
    ).length;

    const projectCount =
      ref instanceof ServerModelRelation
        ? ref.useOptionalServer()?.useProjects().items.length
        : undefined;

    return new ContractItemDependencies(
      contractItem,
      domainCount,
      projectCount ?? 0,
    );
  }
}

export default ContractItemDependencies;

import { iconProject } from "@mittwald/flow-icons/dist/project";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import { ProjectInvite } from "./";

export class ProjectInviteModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "membership",
    "projectinvite",
    iconProject,
  );

  public constructor(inviteId: string) {
    super(inviteId, ProjectInviteModelRelation.type);
  }

  public useOptionalInvite(): ProjectInvite | undefined {
    return ProjectInvite.useTryLoadById(this.id);
  }
}

export default ProjectInviteModelRelation;

import invariant from "invariant";
import isNonEmptyArray from "../../lib/isNonEmptyArray";
import Bytes from "../misc/Bytes";
import { Article, ArticleApiData, StorageArticleAttribute } from "./";

export class StorageArticle extends Article {
  public readonly bytes: Bytes;

  public constructor(data: ArticleApiData) {
    super(data);
    this.bytes = this.constructBytes();
  }

  private constructBytes(): Bytes {
    const storageAttributes = this.attributes.filter(
      (a): a is StorageArticleAttribute => a instanceof StorageArticleAttribute,
    );

    invariant(
      isNonEmptyArray(storageAttributes),
      "Storage attribute must not be empty",
    );

    return storageAttributes[0].bytes;
  }
}

export default StorageArticle;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import User from "../user/User";
import Customer from "./Customer";
import CustomerMembership, {
  CustomerMembershipApiData,
} from "./CustomerMembership";

export class CustomerMembershipList extends ListModel<CustomerMembership> {
  public static fromApiData(
    customer: Customer,
    data: CustomerMembershipApiData[],
  ): CustomerMembershipList {
    return new CustomerMembershipList(
      data.map((d) => CustomerMembership.fromApiData(customer, d)),
    );
  }

  public static useLoadByCustomer(customer: Customer): CustomerMembershipList {
    const data = mittwaldApi.customerListMembershipsForCustomer
      .getResource({
        path: {
          customerId: customer.id,
        },
      })
      .useWatchData();

    return CustomerMembershipList.fromApiData(customer, data);
  }

  public static async loadByCustomer(
    customer: Customer,
  ): Promise<CustomerMembershipList> {
    const response =
      await mittwaldApi.customerListMembershipsForCustomer.request({
        path: {
          customerId: customer.id,
        },
      });

    assertStatus(response, 200);

    return CustomerMembershipList.fromApiData(customer, response.content);
  }

  public findMyMembership(): CustomerMembership | undefined {
    const me = User.useMe();
    return this.items.find((m) => m.userId === me.id);
  }
}

export default CustomerMembershipList;

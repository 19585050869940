import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoApp,
  demoAppInstallation,
  demoAppVersion,
  demoSystemSoftware,
  demoSystemSoftwareVersion,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockAppListUpdateCandidatesForAppversion(
    { path: { appId: "*", baseAppVersionId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockAppListAppversions(
    { path: { appId: "*" } },
    { status: 200, content: [demoAppVersion] },
  );

  mittwaldApiRequestMocking.mockAppGetAppversion(
    { path: { appVersionId: "*", appId: "*" } },
    (req) => ({
      status: 200,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      content: req.path ? demoAppVersion : [demoAppVersion],
    }),
  );

  mittwaldApiRequestMocking.mockAppListApps(
    {},
    { status: 200, content: [demoApp] },
  );

  mittwaldApiRequestMocking.mockAppGetApp(
    { path: { appId: "*" } },
    { status: 200, content: demoApp },
  );

  mittwaldApiRequestMocking.mockAppGetSystemsoftwareversion(
    { path: { systemSoftwareId: "*", systemSoftwareVersionId: "*" } },
    { status: 200, content: demoSystemSoftwareVersion },
  );

  mittwaldApiRequestMocking.mockAppListSystemsoftwareversions(
    { path: { systemSoftwareId: "*" } },
    { status: 200, content: [demoSystemSoftwareVersion] },
  );

  mittwaldApiRequestMocking.mockAppListSystemsoftwares(
    {},
    { status: 200, content: [demoSystemSoftware] },
  );

  mittwaldApiRequestMocking.mockAppGetSystemsoftware(
    { path: { systemSoftwareId: "*" } },
    { status: 200, content: demoSystemSoftware },
  );

  mittwaldApiRequestMocking.mockAppLinkDatabase(
    { path: { appInstallationId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockAppUnlinkDatabase(
    { path: { appInstallationId: "*", databaseId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockAppListAppinstallations(
    { path: { projectId: "*" } },
    { status: 200, content: [demoAppInstallation] },
  );

  mittwaldApiRequestMocking.mockAppGetAppinstallation(
    { path: { appInstallationId: "*" } },
    { status: 200, content: demoAppInstallation },
  );

  mittwaldApiRequestMocking.mockAppUninstallAppinstallation(
    { path: { appInstallationId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockAppPatchAppinstallation(
    { path: { appInstallationId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockAppRequestAppinstallation(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoAppInstallation.id } },
  );
};

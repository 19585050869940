import { NotificationApiSchema } from "../../model/notification/Notification";
import { demoDate, demoReference } from "../staticDemoData";

export const demoNotification: NotificationApiSchema = {
  createdAt: demoDate,
  id: "1",
  read: false,
  reference: demoReference,
  severity: "success",
  type: "project.project.isReady",
};

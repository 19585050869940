import { DateTime } from "luxon";

export function strictToISO(date: DateTime): string;

// eslint-disable-next-line no-redeclare
export function strictToISO(date: undefined): undefined;

// eslint-disable-next-line no-redeclare
export function strictToISO(date: DateTime | undefined): string | undefined;

// eslint-disable-next-line no-redeclare
export function strictToISO(date?: DateTime | undefined): string | undefined {
  if (date === undefined) {
    return undefined;
  }

  const iso = date.toISO();
  if (iso === null) {
    throw new Error("Could not build ISO string");
  }
  return iso;
}

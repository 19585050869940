import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { DateTime } from "luxon";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import modelRelationFactory from "../misc/modelRelation/modelRelationFactory";
import MoneyValue from "../misc/MoneyValue";
import Contract, { ContractTerminateInput } from "./Contract";
import ContractItemArticle from "./ContractItemArticle";
import ContractItemDependencies from "./ContractItemDependencies";
import ContractItemReference from "./ContractItemReference";
import ContractItemTermination from "./ContractItemTermination";
import TariffChange from "./TariffChange";

export type ContractItemApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_ContractItem;

export class ContractItem {
  public readonly id: string;
  public readonly combinedContractId: string;
  public readonly description: string;
  public readonly contract: Contract;
  public readonly data: ContractItemApiData;
  public readonly activationDate?: DateTime;
  public readonly terminationScheduled: boolean;
  public readonly articles: ContractItemArticle[];
  public readonly baseArticle: ContractItemArticle | undefined;
  public readonly totalPrice: MoneyValue;
  public readonly totalYearlyPrice: MoneyValue;
  public readonly index: number;
  public readonly reference?: ModelRelation;
  public readonly termination?: ContractItemTermination;
  public readonly nextPossibleTerminationDate?: DateTime;
  public readonly nextPossibleUpgradeDate?: DateTime;
  public readonly nextPossibleDowngradeDate?: DateTime;
  public readonly tariffChange?: TariffChange;
  public readonly isTariffChangePossible: boolean;
  public readonly isCancelTariffChangePossible: boolean;
  public readonly isTerminationPossible: boolean;
  public readonly freeTrialUntil?: DateTime;
  public readonly isDomain: boolean;
  public readonly isSSLCertificate: boolean;
  public readonly isSpaceServer: boolean;
  public readonly isProSpace: boolean;

  private constructor(
    contract: Contract,
    index: number,
    data: ContractItemApiData,
  ) {
    this.id = data.itemId;
    this.combinedContractId = ContractItem.formatCombinedContractId(
      contract.id,
      data.itemId,
    );
    this.description = data.description;
    this.index = index;
    this.contract = contract;
    this.data = Object.freeze(data);
    this.totalPrice = MoneyValue.fromApiContractPrice(data.totalPrice);
    this.totalYearlyPrice = new MoneyValue(this.totalPrice.euroCent * 12);
    this.terminationScheduled = !!data.termination;
    const articles = data.articles.map((a) =>
      ContractItemArticle.fromApiData(this, a),
    );
    this.baseArticle = articles[0];
    this.articles = articles;
    this.reference = data.aggregateReference
      ? modelRelationFactory(data.aggregateReference)
      : undefined;
    this.termination = data.termination
      ? ContractItemTermination.fromApiData(this, data.termination)
      : undefined;
    this.activationDate = data.activationDate
      ? DateTime.fromISO(data.activationDate)
      : undefined;
    this.nextPossibleTerminationDate = data.nextPossibleTerminationDate
      ? DateTime.fromISO(data.nextPossibleTerminationDate)
      : undefined;
    this.nextPossibleUpgradeDate = data.nextPossibleUpgradeDate
      ? DateTime.fromISO(data.nextPossibleUpgradeDate)
      : undefined;
    this.nextPossibleDowngradeDate = data.nextPossibleDowngradeDate
      ? DateTime.fromISO(data.nextPossibleDowngradeDate)
      : undefined;
    this.tariffChange = data.tariffChange
      ? TariffChange.fromApiData(this, data.tariffChange)
      : undefined;
    this.isTariffChangePossible =
      !this.tariffChange &&
      (!!this.nextPossibleUpgradeDate || !!this.nextPossibleDowngradeDate);
    this.isCancelTariffChangePossible =
      !!this.tariffChange &&
      (!!this.nextPossibleUpgradeDate || !!this.nextPossibleDowngradeDate);
    this.freeTrialUntil =
      data.isInFreeTrial &&
      this.activationDate &&
      this.activationDate.diff(DateTime.now()).toMillis() > 0
        ? this.activationDate
        : undefined;
    this.isDomain = data.aggregateReference?.aggregate === "domain";
    this.isSSLCertificate =
      data.aggregateReference?.aggregate === "certificate";
    this.isSpaceServer =
      data.aggregateReference?.aggregate === "placementgroup";
    this.isProSpace = data.aggregateReference?.aggregate === "project";

    this.isTerminationPossible =
      this.nextPossibleTerminationDate !== undefined &&
      !this.terminationScheduled &&
      !this.tariffChange &&
      !this.isDomain &&
      !this.isSSLCertificate;
  }

  public static formatCombinedContractId(
    contractId: string,
    itemId: string,
  ): string {
    return `${itemId}@${contractId}`;
  }

  private static parseCombinedContractId(combinedContractId: string): {
    contractId: string;
    itemId: string;
  } {
    const parsed = /^(.+?)@(.+?)$/.exec(combinedContractId);

    invariant(
      parsed !== null && parsed[1] && parsed[2],
      "Combined contract ID is invalid",
    );

    return {
      contractId: parsed[2],
      itemId: parsed[1],
    };
  }

  public static fromApiData(
    contract: Contract,
    index: number,
    data: ContractItemApiData,
  ): ContractItem {
    return new ContractItem(contract, index, data);
  }

  public static useLoadByCombinedContractId(id: string): ContractItem {
    const { contractId, itemId } = ContractItem.parseCombinedContractId(id);
    return ContractItem.useLoadById(contractId, itemId);
  }

  public static useLoadById(contractId: string, itemId: string): ContractItem {
    const data = mittwaldApi.contractGetDetailOfContractItem
      .getResource({
        path: {
          contractId,
          contractItemId: itemId,
        },
      })
      .useWatchData();

    const contract = Contract.useLoad(contractId);
    const itemIndex = contract.allItems.findIndex((i) => i.id === itemId);
    return ContractItem.fromApiData(contract, itemIndex, data);
  }

  public useDependencies(): ContractItemDependencies {
    return ContractItemDependencies.useOf(this);
  }

  public async terminate(input?: ContractTerminateInput): Promise<void> {
    const response = await mittwaldApi.contractTerminateContractItem.request({
      path: {
        contractId: this.contract.id,
        contractItemId: this.id,
      },
      requestBody: input,
    });

    assertStatus(response, 201);
  }

  public async cancelTermination(): Promise<void> {
    if (this.termination) {
      await this.termination.cancel();
    }
  }

  public async cancelTariffChange(): Promise<void> {
    if (this.tariffChange) {
      await this.tariffChange.cancel();
    }
  }

  public useReference(): ContractItemReference {
    return ContractItemReference.useOf(this);
  }

  public useNextPossibleTariffChangeDate(
    price: MoneyValue,
  ): DateTime | undefined {
    const baseItemPrice = this.totalPrice;

    return price < baseItemPrice
      ? this.nextPossibleDowngradeDate
      : this.nextPossibleUpgradeDate;
  }

  public showTariffChangeDetails(): boolean {
    return !!this.tariffChange;
  }
}

export default ContractItem;

import { applySnapshot, IStateTreeNode, onSnapshot } from "mobx-state-tree";
import store2 from "store2";

const storage = store2.namespace("stream").session;

export const initBrowserSessionPersistence = (
  storageKey: string,
  store: IStateTreeNode,
): void => {
  onSnapshot(store, (newSnapshot) => storage.set(storageKey, newSnapshot));

  const snapshotOnLoad = storage.get(storageKey);

  if (snapshotOnLoad) {
    applySnapshot(store, snapshotOnLoad);
  }
};

export default initBrowserSessionPersistence;

export interface File {
  id: string;
  name: string;
  type: string;
  content?: string;
}

const b64toBlob = (
  b64Data: string,
  contentType?: string,
  sliceSize?: number,
): Blob => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const isFiletypeDownloadOnly = (type: string): boolean => {
  const targetTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/x-vnd.oasis.opendocument.text",
    "application/x-vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-outlook",
    "application/CDFV2-unknown",
    "message/rfc822",
  ];
  return targetTypes.includes(type);
};

export const openFileInNewTab = (file: File): void => {
  const fileContent = file.content;

  if (fileContent) {
    const objectUrl = window.URL.createObjectURL(
      new Blob([b64toBlob(fileContent)], { type: file.type }),
    );

    const link = document.createElement("a");
    link.href = objectUrl;
    if (isFiletypeDownloadOnly(file.type)) {
      link.setAttribute("download", file.name);
    }
    link.setAttribute("target", "_blank");
    link.click();
  }
};

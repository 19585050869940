import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { CustomerRoleName } from "./CustomerRole";

export type CustomerInviteApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_CustomerInvite;

export interface CustomerInviteCreateInputs {
  message?: string;
  role: CustomerRoleName;
  mailAddress: string;
  membershipExpiresAt?: string;
}

export class CustomerInvite {
  public readonly data: CustomerInviteApiData;
  public readonly id: string;
  public readonly invitedTo: string;

  private constructor(data: CustomerInviteApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.invitedTo = data.customerName;
  }

  public static fromApiData(data: CustomerInviteApiData): CustomerInvite {
    return new CustomerInvite(data);
  }

  public static useTryLoadById(id: string): CustomerInvite | undefined {
    const invite = mittwaldApi.customerGetCustomerInvite
      .getResource({
        path: {
          customerInviteId: id,
        },
      })
      .useWatchData({ optional: true });

    return invite ? CustomerInvite.fromApiData(invite) : undefined;
  }

  public async revokeInvite(): Promise<void> {
    const response = await mittwaldApi.customerDeleteCustomerInvite.request({
      path: {
        customerInviteId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public static async createNew(
    values: CustomerInviteCreateInputs,
    customerId: string,
  ): Promise<string | "inviteExists" | "memberExists"> {
    const response = await mittwaldApi.customerCreateCustomerInvite.request({
      path: { customerId },
      requestBody: {
        role: values.role,
        message: values.message,
        mailAddress: values.mailAddress,
        membershipExpiresAt: values.membershipExpiresAt,
      },
    });

    if (response.status === 409) {
      if (
        response.content.message ===
        "an invite for the given mail-address already exists"
      ) {
        return "inviteExists";
      }

      if (response.content.message === "user is already member of customer") {
        return "memberExists";
      }
    }

    assertStatus(response, 201);

    return response.content.id;
  }

  public async acceptInvite(): Promise<void> {
    const response = await mittwaldApi.customerAcceptCustomerInvite.request({
      path: { customerInviteId: this.id },
    });

    assertStatus(response, 204);
  }

  public async declineInvite(): Promise<void> {
    const response = await mittwaldApi.customerDeclineCustomerInvite.request({
      path: { customerInviteId: this.id },
    });

    assertStatus(response, 204);
  }
}

export default CustomerInvite;

import { ContractApiData } from "../../model/contract/Contract";
import { demoDate, demoReference, demoShortId } from "../staticDemoData";

export const demoContract: ContractApiData = {
  baseItem: {
    itemId: "1",
    totalPrice: {
      value: 4000,
      currency: "EUR",
    },
    isBaseItem: true,
    articles: [
      {
        id: "MT22-0001",
        name: "Space-Server",
        description: "Perfekt für den professionellen Hosting-Reseller",
        articleTemplateId: "1",
        amount: 1,
        unitPrice: {
          value: 4000,
          currency: "EUR",
        },
      },
    ],
    description: 'Space-Server s-123456: "Demo Server"',
    isActivated: true,
    orderDate: demoDate,
    activationDate: demoDate,
    invoicingPeriod: 3,
    contractPeriod: 1,
    aggregateReference: demoReference,
    orderId: "1",
    nextPossibleTerminationDate: demoDate,
    nextPossibleDowngradeDate: demoDate,
    nextPossibleUpgradeDate: demoDate,
    isInclusive: false,
    isInFreeTrial: false,
    freeTrialDays: 0,
  },
  contractId: "1",
  contractNumber: demoShortId,
  customerId: "1",
};

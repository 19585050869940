import { ExtensionApiData } from "../../model/marketplace/Extension";
import { ExtensionInstanceApiData } from "../../model/marketplace/ExtensionInstance";
import { ContributorApiData } from "../../model/marketplace/Contributor";

export const demoExtensions: ExtensionApiData[] = [
  {
    id: "1",
    name: "Rechnungs Bello",
    context: "project",
    description: "Beschreibung...",
    scopes: ["mail:write", "mail:read"],
    state: "enabled",
    tags: [],
    support: {},
    contributorId: "1",
    blocked: false,
    disabled: false,
    published: false,
  },
  {
    id: "2",
    name: "E-Mail Whitelabel",
    context: "project",
    description: "Beschreibung...",
    scopes: ["mail:write", "mail:read"],
    state: "enabled",
    tags: [],
    support: {},
    contributorId: "1",
    blocked: false,
    disabled: false,
    published: false,
  },
  {
    id: "3",
    name: "Noch eine Extension",
    context: "project",
    description: "Beschreibung...",
    scopes: ["mail:write", "mail:read"],
    state: "enabled",
    tags: [],
    support: {},
    contributorId: "1",
    blocked: false,
    disabled: false,
    published: false,
  },
];

export const demoExtensionInstance = (
  context: string,
): ExtensionInstanceApiData => ({
  id: "1",
  extensionId: "1",
  consentedScopes: ["mail:write", "mail:read"],
  disabled: false,
  aggregateReference: { aggregate: context, domain: context, id: "1" },
  pendingInstallation: false,
  pendingRemoval: false,
});

export const demoContributor: ContributorApiData = {
  id: "1",
  customerId: "1",
  name: "Weiß auf Schwarz",
  state: "enabled",
  description: "Beschreibung eines Contributors..",
};

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { CronjobCommandDestination } from "./CronjobCommandDestination";
import { CronjobExecution } from "./CronjobExecution";
import { CronjobUrlDestination } from "./CronjobUrlDestination";

export type CronjobApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Cronjob_Cronjob;

export interface UpdateCronjobInputs {
  interval?: string;
  intervalSelect?: string;
  time?: string;
  active?: boolean;
  description?: string;
  appId: string;
  timeout: string;
  email?: string;
}

interface CronjobCommandInputs {
  type: "command";
  parameters: string;
  interpreter: string;
  path: string;
}

interface CronjobUrlInputs {
  type: "url";
  url: string;
}

export interface CronjobDestinationInputs {
  destination: CronjobCommandInputs | CronjobUrlInputs;
}

export interface NewCronjobInputs extends CronjobDestinationInputs {
  description: string;
  email?: string;
  timeout: string;
  active: boolean;
  interval: string;
  appId: string;
}

export class Cronjob {
  public readonly id: string;
  public readonly data: CronjobApiData;
  public readonly description: string;
  public readonly isActive: boolean;
  public readonly destination:
    | CronjobUrlDestination
    | CronjobCommandDestination;
  public readonly latestExecution?: CronjobExecution;
  public readonly email?: string;

  private constructor(data: CronjobApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.description = data.description;
    this.isActive = data.active;
    this.destination =
      "url" in data.destination
        ? CronjobUrlDestination.fromApiData(data.destination)
        : CronjobCommandDestination.fromApiData(data.destination);
    this.latestExecution = data.latestExecution
      ? CronjobExecution.fromApiData(data.latestExecution, this)
      : undefined;
    this.email = data.email;
  }

  public static fromApiData(data: CronjobApiData): Cronjob {
    return new Cronjob(data);
  }

  public static useLoadById(cronjobId: string): Cronjob {
    const data = mittwaldApi.cronjobGetCronjob
      .getResource({ path: { cronjobId } })
      .useWatchData();

    return Cronjob.fromApiData(data);
  }

  public static useTryLoadById(cronjobId?: string): Cronjob | undefined {
    const data = mittwaldApi.cronjobGetCronjob
      .getResource(cronjobId ? { path: { cronjobId } } : null)
      .useWatchData({ optional: true });

    return data ? Cronjob.fromApiData(data) : undefined;
  }

  public static useLoadByPathParam(): Cronjob {
    const { cronjobId } = usePathParams("cronjobId");
    return Cronjob.useLoadById(cronjobId);
  }

  public static async createNew(
    values: NewCronjobInputs,
    projectId: string,
  ): Promise<string> {
    const response = await mittwaldApi.cronjobCreateCronjob.request({
      path: {
        projectId,
      },
      requestBody: {
        active: true,
        destination:
          values.destination.type === "command"
            ? {
                path: values.destination.path,
                interpreter: values.destination.interpreter,
                parameters: values.destination.parameters,
              }
            : { url: values.destination.url },
        interval: values.interval,
        description: values.description,
        appId: values.appId,
        email: values.email ? values.email : undefined,
        timeout: parseInt(values.timeout),
      },
    });

    assertStatus(response, 201);

    return response.content.id;
  }

  public async update(values: UpdateCronjobInputs): Promise<void> {
    const response = await mittwaldApi.cronjobUpdateCronjob.request({
      path: { cronjobId: this.id },
      requestBody: {
        interval: values.interval ?? this.data.interval,
        active: values.active === undefined ? this.data.active : values.active,
        description: values.description ?? this.description,
        email: values.email ?? this.email,
        timeout: values.timeout ? parseInt(values.timeout) : undefined,
      },
    });

    assertStatus(response, 204);
  }

  public async updateAppId(values: UpdateCronjobInputs): Promise<void> {
    const response = await mittwaldApi.cronjobUpdateCronjobAppId.request({
      path: { cronjobId: this.id },
      requestBody: { appId: values.appId },
    });

    assertStatus(response, 204);
  }

  public async updateDestination(
    values: CronjobDestinationInputs,
  ): Promise<void> {
    const response = await mittwaldApi.cronjobUpdateCronjob.request({
      path: { cronjobId: this.id },
      requestBody: {
        destination:
          values.destination.type === "command"
            ? {
                path: values.destination.path,
                interpreter: values.destination.interpreter,
                parameters: values.destination.parameters,
              }
            : { url: values.destination.url },
      },
    });

    assertStatus(response, 204);
  }

  public async triggerCronjob(): Promise<void> {
    await mittwaldApi.cronjobCreateExecution.request({
      path: {
        cronjobId: this.id,
      },
    });
  }

  public async delete(): Promise<void> {
    await mittwaldApi.cronjobDeleteCronjob.request({
      path: {
        cronjobId: this.id,
      },
    });
  }

  public async toggleActive(): Promise<void> {
    await mittwaldApi.cronjobUpdateCronjob.request({
      path: {
        cronjobId: this.id,
      },
      requestBody: {
        active: !this.isActive,
      },
    });
  }
}

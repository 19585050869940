import Bytes from "../misc/Bytes";
import Server from "./Server";

export interface StorageUpdateInput {
  size: Bytes;
}

export class Storage {
  public readonly server: Server;
  public readonly bytes: Bytes;

  private constructor(server: Server) {
    this.server = server;
    this.bytes = Bytes.parse(server.data.storage);
  }

  public static fromServer(sever: Server): Storage {
    return new Storage(sever);
  }
}

export default Storage;

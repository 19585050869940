import { MittwaldApi } from "../../api/Mittwald";
import UserInput from "../misc/userInput/UserInput";
import SystemSoftwareVersion from "./SystemSoftwareVersion";

export type UserInputApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_UserInput;

export class SystemSoftwareUserInput extends UserInput {
  public readonly systemSoftwareVersion: SystemSoftwareVersion;

  private constructor(
    systemSoftwareVersion: SystemSoftwareVersion,
    data: UserInputApiData,
  ) {
    super(data);
    this.systemSoftwareVersion = systemSoftwareVersion;
  }

  public static fromApiData(
    systemSoftwareVersion: SystemSoftwareVersion,
    data: UserInputApiData,
  ): SystemSoftwareUserInput {
    return new SystemSoftwareUserInput(systemSoftwareVersion, data);
  }
}

export default SystemSoftwareUserInput;

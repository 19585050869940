import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoDeliveryBox,
  demoMailAddress,
  demoProjectSettings,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockMailListDeliveryBoxes(
    { path: { projectId: "*" } },
    { status: 200, content: [demoDeliveryBox] },
  );

  mittwaldApiRequestMocking.mockMailGetDeliveryBox(
    { path: { deliveryBoxId: "*" } },
    { status: 200, content: demoDeliveryBox },
  );

  mittwaldApiRequestMocking.mockMailCreateDeliverybox(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoDeliveryBox.id } },
  );

  mittwaldApiRequestMocking.mockMailUpdateDeliveryBoxDescription(
    { path: { deliveryBoxId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateDeliveryBoxPassword(
    { path: { deliveryBoxId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailDeleteDeliveryBox(
    { path: { deliveryBoxId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailListMailAddresses(
    { path: { projectId: "*" } },
    { status: 200, content: [demoMailAddress] },
  );

  mittwaldApiRequestMocking.mockMailGetMailAddress(
    { path: { mailAddressId: "*" } },
    { status: 200, content: demoMailAddress },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressCatchAll(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailCreateMailAddress(
    { path: { projectId: "*" } },
    { status: 201, content: { id: demoMailAddress.id } },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressPassword(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailDeleteMailAddress(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressForwardAddresses(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressSpamProtection(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressAutoresponder(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressAddress(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailUpdateMailAddressQuota(
    { path: { mailAddressId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockMailListProjectMailSettings(
    { path: { projectId: "*" } },
    { status: 200, content: demoProjectSettings },
  );

  mittwaldApiRequestMocking.mockMailUpdateProjectMailSetting(
    { path: { projectId: "*", mailSetting: "whitelist" } },
    { status: 200 },
  );
};

import {
  RAMArticleAttribute,
  GenericArticleAttribute,
  ArticleAttribute,
  ArticleAttributeApiData,
  StorageArticleAttribute,
} from "./";

export const articleAttributeFactory = (
  apiData: ArticleAttributeApiData,
): ArticleAttribute => {
  if (apiData.key === StorageArticleAttribute.key) {
    return new StorageArticleAttribute(apiData);
  }
  if (apiData.key === RAMArticleAttribute.key) {
    return new RAMArticleAttribute(apiData);
  }
  return new GenericArticleAttribute(apiData);
};

export default articleAttributeFactory;

import { MittwaldApi } from "../../api/Mittwald";
import { ArticleModifier, StorageArticleModifier } from "./";

export const articleModifierFactory = (
  apiData: MittwaldApi.Components.Schemas.De_Mittwald_V1_Article_ReadableModifierArticleOptions,
): ArticleModifier => {
  // @todo Identifying storage article by looking at the ID seems to be flaky
  if (apiData.articleId.endsWith("-Storage")) {
    return StorageArticleModifier.fromApiData(apiData);
  }

  return ArticleModifier.fromApiData(apiData);
};

export default articleModifierFactory;

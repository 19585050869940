import is from "@sindresorhus/is";
import { Class } from "type-fest";

export const getClass = <T>(something: T): Class<T> =>
  (is.class_(something)
    ? something
    : is.object(something)
      ? something.constructor
      : something) as Class<T>;

export default getClass;

import { DateTime } from "luxon";
import { DnsZoneApiData } from "../../model/dns/DnsZone";
import { DomainApiData } from "../../model/domain/Domain";
import { IngressApiData } from "../../model/domain/Ingress";
import { TldApiData } from "../../model/domain/Tld";
import { demoName, demoUrl } from "../staticDemoData";

export const demoIngress: IngressApiData = {
  id: "1",
  hostname: demoUrl,
  projectId: "1",
  isDefault: false,
  isEnabled: true,
  paths: [
    {
      path: "/",
      target: { installationId: "1" },
    },
  ],
  dnsValidationErrors: [],
  tls: {
    acme: true,
    isCreated: false,
    requestDeadline: DateTime.now().toISO(),
  },
  ips: { v4: ["11.111.111.11"] },
  ownership: {
    txtRecord: "a",
    verified: true,
  },
};

export const demoDefaultIngress: IngressApiData = {
  id: "2",
  hostname: "default.de",
  projectId: "1",
  isDefault: true,
  isEnabled: true,
  paths: [
    {
      path: "/",
      target: { useDefaultPage: true },
    },
  ],
  dnsValidationErrors: [],
  tls: { acme: true, isCreated: true },
  ips: { v4: ["11.111.111.11"] },
  ownership: {
    txtRecord: "a",
    verified: true,
  },
};

const demoHandleFields = [
  {
    name: "name",
    value: demoName,
  },
];

export const demoDomain: DomainApiData = {
  connected: true,
  deleted: false,
  domain: demoUrl,
  domainId: "1",
  handles: {
    ownerC: {
      current: {
        handleRef: "DENIC-123456",
        handleFields: demoHandleFields,
      },
      desired: {
        handleFields: demoHandleFields,
      },
    },
    adminC: {
      current: {
        handleRef: "DENIC-123456",
        handleFields: demoHandleFields,
      },
      desired: {
        handleFields: demoHandleFields,
      },
    },
  },
  transferInAuthCode: undefined,
  nameservers: [],
  processes: [],
  projectId: "1",
  usesDefaultNameserver: false,
};
export const demoDnsZone: DnsZoneApiData = {
  domain: demoUrl,
  id: "1",
  recordSet: {
    cname: { settings: { ttl: { auto: true } }, fqdn: "demo" },
    txt: { settings: { ttl: { auto: true } }, entries: ["demo"] },
    mx: {
      value: {
        records: [{ fqdn: "demo", priority: 0 }],
        settings: { ttl: { auto: true } },
      },
    },
    srv: {},
    combinedARecords: {
      a: ["11.11.111.111"],
      aaaa: [],
      settings: { ttl: { auto: true } },
    },
  },
};

export const demoTld: TldApiData = {
  irtp: false,
  transferAuthCodeRequired: true,
  tld: "de",
  rgpDays: 100,
  transferAuthentication: "code",
};

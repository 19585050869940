import { MittwaldApi } from "../../api/Mittwald";
import Salutation from "../misc/Salutation";

export type PersonApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Commons_Person;

export class Person {
  public readonly data: PersonApiSchema;
  public readonly title?: Salutation;
  public readonly firstName?: string;
  public readonly lastName?: string;

  private constructor(data: PersonApiSchema) {
    this.data = Object.freeze(data);
    this.title = data.title ? new Salutation(data.title) : undefined;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }

  public static fromApiData(data: PersonApiSchema): Person {
    return new Person(data);
  }
}

export default Person;

import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import DeliveryBox from "./DeliveryBox";

export class DeliveryBoxList extends ListModel<DeliveryBox> {
  public static useLoadAllByProjectId(projectId: string): DeliveryBoxList {
    const deliveryBoxes = mittwaldApi.mailListDeliveryBoxes
      .getResource({
        path: { projectId },
      })
      .useWatchData()
      .map((d) => DeliveryBox.fromApiData(d));

    return new DeliveryBoxList(deliveryBoxes);
  }

  public static useLoadAllByPathParam(): DeliveryBoxList {
    const { projectId } = usePathParams("projectId");
    return DeliveryBoxList.useLoadAllByProjectId(projectId);
  }
}

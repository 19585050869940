export enum Role {
  customerOwner = "customerOwner",
  customerMember = "customerMember",
  customerAccountant = "customerAccountant",
  projectDeveloper = "projectDeveloper",
  projectAdmin = "projectAdmin",
  emailAdmin = "emailAdmin",
  authenticated = "authenticated",
  anybody = "anybody",
}

export const customerRoles: Role[] = [
  Role.customerMember,
  Role.customerOwner,
  Role.customerAccountant,
];

export const projectRoles: Role[] = [
  Role.projectDeveloper,
  Role.projectAdmin,
  Role.emailAdmin,
];

import { Schema } from "jsonschema";
import UserInput, { UserInputFormatTypes } from "../misc/userInput/UserInput";

export interface DomainHandleUserInputData {
  schema: Schema;
  name: string;
  format?: UserInputFormatTypes;
  required: boolean;
}
export class DomainHandleUserInput extends UserInput {
  private constructor(data: DomainHandleUserInputData) {
    super(data);
  }

  public static fromApiData(
    data: DomainHandleUserInputData,
  ): DomainHandleUserInput {
    return new DomainHandleUserInput(data);
  }
}

export default DomainHandleUserInput;

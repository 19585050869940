import invariant from "invariant";
import z from "zod";
import {
  ArticleAttributeKey,
  HostingArticle,
  ProSpaceArticle,
} from "../article";
import Bytes from "../misc/Bytes";

export class MachineType {
  public readonly cpu: number;
  public readonly memory: Bytes;
  public readonly name?: string;

  private constructor(cpu: number, memory: Bytes, name?: string) {
    invariant(cpu > 0, "CPU must greater then 0");

    this.cpu = cpu;
    this.memory = memory;
    this.name = name;
  }

  public static fromArticle(article: HostingArticle): MachineType {
    const cpu = article.requireAttribute(ArticleAttributeKey.vCpu);
    const ram = article.requireAttribute(ArticleAttributeKey.ram);
    const machineType =
      article instanceof ProSpaceArticle
        ? article.getAttribute(ArticleAttributeKey.specMachineType)
        : article.requireAttribute(ArticleAttributeKey.machineType);

    return new MachineType(
      parseInt(cpu.value),
      Bytes.of(parseInt(ram.value), "GiB"),
      machineType?.value,
    );
  }

  public stringify(): string {
    return JSON.stringify({
      name: this.name,
      cpu: this.cpu,
      memory: this.memory.bytes,
    });
  }

  public getDescription(type: "full" | "cpu" | "memory"): string {
    const cpu = `${this.cpu} vCPU`;
    const memory = `${this.memory.pretty()} RAM`;

    if (type === "full") {
      return `${cpu} / ${memory}`;
    }
    if (type === "cpu") {
      return cpu;
    }

    return memory;
  }

  public compare(other: MachineType): number {
    return this.cpu > other.cpu ? 1 : this.cpu === other.cpu ? 0 : -1;
  }

  public equals(other: MachineType): boolean {
    return this.stringify() === other.stringify();
  }

  public mustGetName(): string {
    invariant(!!this.name, "Required machine type name is not set");
    return this.name;
  }

  public static parse(serialized: string): MachineType {
    const { name, cpu, memory } = z
      .object({
        name: z.string(),
        cpu: z.number(),
        memory: z.number(),
      })
      .parse(JSON.parse(serialized));

    return new MachineType(cpu, new Bytes(memory), name);
  }
}

export default MachineType;

import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ApiDataError from "../../errors/ApiDataError";
import ListModel from "../misc/ListModel";
import AppVersion from "./AppVersion";
import SystemSoftware from "./SystemSoftware";
import SystemSoftwareVersion from "./SystemSoftwareVersion";

type ApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_SystemSoftwareVersion;

export class SystemSoftwareVersionList extends ListModel<SystemSoftwareVersion> {
  public static useLoadBySystemSoftwareAndVersionRange(
    systemSoftwareName: string,
    systemSoftwareId: string,
    versionRange: string,
  ): SystemSoftwareVersionList {
    const data = mittwaldApi.appListSystemsoftwareversions
      .getResource({
        path: { systemSoftwareId },
        query: versionRange
          ? { versionRange, recommended: true }
          : { recommended: true },
      })
      .useWatchData();

    return this.fromApiData(systemSoftwareName, data);
  }
  public static useLoadBySystemSoftwareAndAppVersion(
    systemSoftwareName: string,
    systemSoftwareId: string,
    appVersion: AppVersion,
  ): SystemSoftwareVersionList {
    const versionRange = appVersion.data.systemSoftwareDependencies?.find(
      (d) => d.systemSoftwareId === systemSoftwareId,
    )?.versionRange;

    const data = mittwaldApi.appListSystemsoftwareversions
      .getResource({
        path: { systemSoftwareId },
        query: versionRange
          ? { versionRange, recommended: true }
          : { recommended: true },
      })
      .useWatchData();

    return this.fromApiData(systemSoftwareName, data);
  }

  public static fromApiData(
    systemSoftwareName: string,
    apiData: ApiSchema[],
  ): SystemSoftwareVersionList {
    const items = apiData

      .map((v) => SystemSoftwareVersion.fromApiData(systemSoftwareName, v))
      .sort((a, b) => b.compare(a));

    return new SystemSoftwareVersionList(items);
  }

  public latest(): SystemSoftwareVersion {
    const latestVersion = this.items[0];

    if (!latestVersion) {
      throw new ApiDataError("App has no versions");
    }
    return latestVersion;
  }

  public useOtherVersions(currentVersion?: string): SystemSoftwareVersion[] {
    const versions = this.useItems();

    if (!currentVersion) {
      return [];
    }

    return versions.filter((v) => v.data.internalVersion !== currentVersion);
  }

  public static usePhpVersions(): SystemSoftwareVersionList | undefined {
    const php = SystemSoftware.usePhp();

    const data = mittwaldApi.appListSystemsoftwareversions
      .getResource({
        path: { systemSoftwareId: php.id },
      })
      .useWatchData();

    return SystemSoftwareVersionList.fromApiData(php.name, data);
  }
}

export default SystemSoftwareVersionList;

import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Bytes from "../../../../model/misc/Bytes";

interface Props {
  size: Bytes;
}

export const StorageSliderLabel: FC<Props> = (props) => {
  const { size } = props;

  const storageText = (
    <Text _color="brand-primary-dark" _inline strong text={size.pretty()} />
  );

  return (
    <Text
      i18n={{
        id: "storage",
        values: {
          size: storageText,
        },
      }}
    />
  );
};

export default StorageSliderLabel;

export type ContainerVolumeRelationApiData = string;

export interface ContainerVolumeRelationFormData {
  type: "directory" | "volume";
  volume: string;
  projectDirectory: string;
  containerPath: string;
}

export class ContainerVolumeRelation {
  public readonly data: ContainerVolumeRelationApiData;
  public readonly type?: "directory" | "volume";
  public readonly volume?: string;
  public readonly projectDirectory?: string;
  public readonly containerPath?: string;

  private constructor(data: ContainerVolumeRelationApiData) {
    const [prefix, suffix] = data.split(":");
    this.data = data;
    const type =
      prefix && prefix.startsWith("/") && suffix ? "directory" : "volume";
    this.type = type;
    this.volume = type === "volume" && suffix ? prefix : undefined;
    this.projectDirectory = type === "directory" && suffix ? prefix : "/";
    this.containerPath = suffix ?? prefix;
  }

  public static fromApiData(
    data: ContainerVolumeRelationApiData,
  ): ContainerVolumeRelation {
    return new ContainerVolumeRelation(data);
  }

  public static fromFormData(
    data: ContainerVolumeRelationFormData,
  ): ContainerVolumeRelation {
    return ContainerVolumeRelation.fromApiData(
      data.type === "volume"
        ? `${data.volume}:${data.containerPath}`
        : `${data.projectDirectory}:${data.containerPath}`,
    );
  }

  public asFormData(): ContainerVolumeRelationFormData {
    return {
      type: this.type,
      volume: this.volume,
      projectDirectory: this.projectDirectory,
      containerPath: this.containerPath,
    } as ContainerVolumeRelationFormData;
  }
}

import { KyClient } from "@mittwald/api-client/dist/http/KyClient";
import { MittwaldInternalApiClient } from "../InternalMittwald";
import defaultOptions from "./defaultOptions";
import { readAfterWriteSleep } from "./hooks/readAfterWriteSleep";

export const mittwaldInternalApiHttpClient = new KyClient({
  ky: {
    ...defaultOptions(),
    prefixUrl: import.meta.env.VITE_APP_INTERNAL_API_URI,
    hooks: {
      afterResponse: [readAfterWriteSleep],
    },
  },
});

export const mittwaldInternalApiClient = new MittwaldInternalApiClient(
  mittwaldInternalApiHttpClient,
);
MittwaldInternalApiClient.setInstance(mittwaldInternalApiClient);

export default mittwaldInternalApiClient;

import { MittwaldApi } from "../../api/Mittwald";
import { Article, ArticleFactory } from "./";

export type ArticleModifierApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Article_ReadableModifierArticleOptions;

export class ArticleModifier {
  public readonly maxCount: number;
  public readonly articleId: string;

  protected constructor(articleId: string, maxCount: number) {
    this.articleId = articleId;
    this.maxCount = maxCount;
  }

  public static fromApiData(data: ArticleModifierApiData): ArticleModifier {
    return new ArticleModifier(data.articleId, data.maxArticleCount);
  }

  public useArticle(): Article {
    return ArticleFactory.useLoadById(this.articleId);
  }

  public async loadArticle(): Promise<Article> {
    return ArticleFactory.loadById(this.articleId);
  }
}

export default ArticleModifier;

import errorInspectorRegistry from "@mittwald/flow-components/dist/components/ErrorBoundary/rendering/inspectors/registry";
import { registerErrorReportingDataExtractionFn } from "@mittwald/flow-lib/dist/errors/errorReporting/reportingDataExtraction";

const isModuleImportError = (error: unknown): boolean => {
  return (
    error instanceof Error &&
    (error.message.startsWith("Failed to fetch dynamically imported module") ||
      error.message.startsWith("error loading dynamically imported module"))
  );
};

errorInspectorRegistry.push((error, infos) => {
  if (!isModuleImportError(error)) {
    return false;
  }

  infos.infoTranslationKeys.push("moduleNotFound");
});

registerErrorReportingDataExtractionFn((error) => {
  if (!isModuleImportError(error)) {
    return;
  }

  return {
    noReport: true,
  };
});

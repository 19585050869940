import { iconTicket } from "@mittwald/flow-icons/dist/ticket";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import Conversation from "./Conversation";

export class ConversationModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "conversation",
    "conversation",
    iconTicket,
  );

  public constructor(conversationId: string) {
    super(conversationId, ConversationModelRelation.type);
  }

  public useConversation(): Conversation {
    return Conversation.useLoadById(this.id);
  }

  public useOptionalConversation(): Conversation | undefined {
    return Conversation.useTryLoadById(this.id);
  }
}

export default ConversationModelRelation;

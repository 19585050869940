import sleep from "@mittwald/flow-lib/dist/lib/sleep";

export const readAfterWriteSleep = async (
  request: Request,
  ignoredOptions: unknown,
  response: Response,
): Promise<Response> => {
  if (["POST", "PUT", "PATCH", "DELETE"].includes(request.method)) {
    await sleep(0.3);
  }
  return response;
};

import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoDate } from "../staticDemoData";
import { demoContract } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockContractCancelContractItemTermination(
    { path: { contractId: "*", contractItemId: "*" } },
    { status: 200, content: [demoContract] },
  );

  mittwaldApiRequestMocking.mockContractTerminateContractItem(
    { path: { contractItemId: "*", contractId: "*" } },
    { status: 201 },
  );

  mittwaldApiRequestMocking.mockContractGetNextTerminationDateForItem(
    { path: { contractItemId: "*", contractId: "*" } },
    {
      status: 200,
      content: {
        contractItemId: demoContract.baseItem.itemId,
        nextTerminationDate: demoDate,
      },
    },
  );

  mittwaldApiRequestMocking.mockContractTerminateContract(
    { path: { contractId: "*" } },
    { status: 201 },
  );

  mittwaldApiRequestMocking.mockContractListContracts(
    { path: { customerId: "*" } },
    { status: 200, content: [demoContract] },
  );

  mittwaldApiRequestMocking.mockContractGetDetailOfContractItem(
    { path: { contractItemId: "*", contractId: "*" } },
    { status: 200, content: demoContract.baseItem },
  );

  mittwaldApiRequestMocking.mockContractGetBaseItemOfContract(
    { path: { contractId: "*" } },
    { status: 200, content: demoContract.baseItem },
  );

  mittwaldApiRequestMocking.mockContractGetDetailOfContractByServer(
    { path: { serverId: "*" } },
    { status: 200, content: demoContract },
  );

  mittwaldApiRequestMocking.mockContractGetDetailOfContractByProject(
    { path: { projectId: "*" } },
    { status: 200, content: demoContract },
  );

  mittwaldApiRequestMocking.mockContractGetDetailOfContractByDomain(
    { path: { domainId: "*" } },
    { status: 200, content: demoContract },
  );

  mittwaldApiRequestMocking.mockContractGetDetailOfContract(
    { path: { contractId: "*" } },
    { status: 200, content: demoContract },
  );

  mittwaldApiRequestMocking.mockOrderCreateTariffChange({}, { status: 201 });

  mittwaldApiRequestMocking.mockOrderGetOrder(
    { path: { orderId: "*" } },
    { status: 404 },
  );

  mittwaldApiRequestMocking.mockOrderPreviewOrder(
    {},
    {
      status: 200,
      content: {
        totalPrice: 2000,
        domainPrice: 1500,
        feePrice: 500,
        storagePrice: 2000,
        machineTypePrice: 2000,
      },
    },
  );

  mittwaldApiRequestMocking.mockOrderCreateOrder({}, () => ({ status: 201 }));
};

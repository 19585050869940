import { useForm } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import {
  InvoiceSettings,
  InvoiceSettingsInputs,
  InvoiceSettingsPaymentInput,
} from "../../../../../../model/customer/InvoiceSettings";
import VirtualPageViewTracking from "../../../../../../widgets/components/tracking/components/VirtualPageViewTracking";
import ContactFormInputs from "../../../../customer/contractPartner/components/ContactFormInputs";

interface Props {
  trackingId?: string;
  watchedCustomerId: string;
  watchedPaymentData: InvoiceSettingsPaymentInput;
}

export const RecipientStep: FC<Props> = (props) => {
  const form = useForm<InvoiceSettingsInputs>({
    defaultValues: {
      paymentSettings: props.watchedPaymentData,
      recipient: { salutation: "other" },
      invoicePeriod: 1,
      invoiceRecipient: "other",
    },
    showSuccessFeedback: false,
    onSubmit: async (values) => {
      await InvoiceSettings.update(values, props.watchedCustomerId);
    },
  });

  return (
    <WizardStep form={form} id="recipient" indicatorText="recipient">
      <Suspense loadingView={<LoadingView _height={250} />}>
        {props.trackingId && (
          <VirtualPageViewTracking
            identifier={props.trackingId}
            name="checkout/recipient"
          />
        )}
        <Section.Item>
          <ContactFormInputs type="recipient" />
        </Section.Item>
      </Suspense>
    </WizardStep>
  );
};

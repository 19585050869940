import UserInput from "./UserInput";

export enum InstallationUserInputDataSourceName {
  description = "appinstallation.description",
}

export enum UserUserInputDataSourceName {
  firstName = "user.firstname",
  lastName = "user.lastname",
  username = "user.username",
  email = "user.email",
  ingressPath = "ingress.paths",
}

export type UserInputDataSourceNameLoose =
  | InstallationUserInputDataSourceName
  | UserUserInputDataSourceName
  | string;

export class UserInputDataSource {
  public readonly name: UserInputDataSourceNameLoose;
  public readonly userInput: UserInput;

  public constructor(userInput: UserInput, name: UserInputDataSourceNameLoose) {
    this.name = name;
    this.userInput = userInput;
  }
}

export default UserInputDataSource;

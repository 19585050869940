import { faSidebar } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown";
import { faCloudPlus } from "@fortawesome/pro-regular-svg-icons/faCloudPlus";
import { faHandshake } from "@fortawesome/pro-regular-svg-icons/faHandshake";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import React, { FC } from "react";
import Container from "../styled/Container";

export const ProSpaceLiteSection: FC = () => {
  return (
    <Container>
      <InfoBox.InfoItem icon={faHandshake} text="shared" />
      <InfoBox.InfoItem icon={faArrowUpArrowDown} text="upgrades" />
      <InfoBox.InfoItem icon={faSidebar} text="mStudioManagement" />
      <InfoBox.InfoItem icon={faCloudPlus} text="cloud" />
    </Container>
  );
};

export default ProSpaceLiteSection;

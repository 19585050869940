import { autorun } from "mobx";

export type CleanupFn = (() => any) | undefined | void | CleanupFn[];

function executeCleanup(cleanup: CleanupFn): void {
  if (Array.isArray(cleanup)) {
    cleanup.forEach(executeCleanup);
  } else if (cleanup) {
    cleanup();
  }
}

/**
 * Executes a cleanup function when autorun runs again
 */
export const autorunWithCleanup = (runFn: () => CleanupFn): void => {
  let cleanup: CleanupFn;

  autorun(() => {
    if (cleanup) {
      executeCleanup(cleanup);
    }
    cleanup = runFn();
  });
};

export default autorunWithCleanup;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { CheckPageState } from "@mittwald/flow-lib/dist/pages/state";
import { deepEqual } from "fast-equals";
import invariant from "invariant";
import slugify from "slugify";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { trimAndCompareStrings } from "../../lib/trimAndCompareStrings";
import { ContainerPortFormData } from "./ContainerPort";
import { ContainerPortList } from "./ContainerPortList";
import { ContainerUtils } from "./ContainerUtils";
import { ContainerVolumeRelationFormData } from "./ContainerVolumeRelation";
import { ContainerVolumeRelationList } from "./ContainerVolumeRelationList";

export type ContainerApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ServiceResponse;
export type ContainerStatus =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ServiceStatus;
export type ImageMeta =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ContainerImageConfig;
export type ContainerImageConfigExposedPort =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ContainerImageConfigExposedPort;
export type ContainerImageConfigEnv =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ContainerImageConfigEnv;
export type ContainerImageConfigVolume =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Container_ContainerImageConfigVolume;

export const portRegExp = /^([0-9]+)(:[0-9]+)?(\/(tcp|udp))?$/;
export const projectPathOrVolumeRegex = /^[a-z,A-Z/.\-0-9]+/;
export const containerPathRegex = /\/[a-z,A-Z/\-.0-9]+/;

export type EnvDefinitionType = "console" | "variables" | "upload";

export interface ImageMetaProps {
  imageMeta?: ImageMeta;
}

export interface AiMetaInfo {
  description?: string;
  isAiGenerated?: boolean;
  isSensitive?: boolean;
}

export interface UpdateContainerInputs {
  description: string;
  imageReference: string;
}

export type EntrypointSettingsType = "default" | "custom";

export interface UpdateContainerEntrypointInputs {
  entrypoint?: string;
  entrypointSelection: EntrypointSettingsType;
  command?: string;
  commandSelection: EntrypointSettingsType;
}

export interface EnvironmentInputs {
  environments: ContainerEnvVariable[];
  environmentText: string;
  envDefinitionType: EnvDefinitionType;
}

export interface CreateContainerInputs
  extends UpdateContainerInputs,
    UpdateContainerEntrypointInputs,
    EnvironmentInputs {
  volumes: ContainerVolumeRelationFormData[];
  ports: ContainerPortFormData[];
}

export interface ContainerEnvVariable {
  key: string;
  value: string;
  isAiGenerated?: boolean;
}

interface ContainerState {
  image: string;
  ports?: ContainerPortList;
  envs?: ContainerEnvVariable[];
  volumes: ContainerVolumeRelationList;
}

export interface ImageState
  extends Pick<ImageMeta, "isUserRoot" | "overwritingUser"> {
  isValidating: boolean;
}

export interface EntrypointMeta {
  entrypoint?: string;
  command?: string;
}

export class Container {
  public readonly data: ContainerApiData;
  public readonly id: string;
  public readonly stackId: string;
  public readonly description: string;
  public readonly serviceName: string;
  public readonly deployedState: ContainerState;
  public readonly pendingState: ContainerState;
  public readonly entrypoint?: string;
  public readonly command?: string;
  public readonly status?: ContainerStatus;
  public readonly recreateRequired?: boolean;

  private constructor(data: ContainerApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.stackId = data.stackId;
    this.description = data.description;
    this.serviceName = data.serviceName;
    this.status = data.status;
    this.entrypoint = data.pendingState.entrypoint?.join(" ");
    this.command = data.pendingState.command?.join(" ");
    this.deployedState = {
      image: data.deployedState.image,
      envs: data.deployedState.envs
        ? ContainerUtils.mapEnvApiValuesToModelValues(data.deployedState.envs)
        : undefined,
      volumes: ContainerVolumeRelationList.fromApiData(
        data.deployedState.volumes ?? [],
      ),
      ports: ContainerPortList.fromApiData(data.deployedState.ports ?? []),
    };
    this.pendingState = {
      image: data.pendingState.image,
      envs: data.pendingState.envs
        ? ContainerUtils.mapEnvApiValuesToModelValues(data.pendingState.envs)
        : undefined,
      volumes: ContainerVolumeRelationList.fromApiData(
        data.pendingState.volumes ?? [],
      ),
      ports: ContainerPortList.fromApiData(data.pendingState.ports ?? []),
    };
    this.recreateRequired = !deepEqual(data.pendingState, data.deployedState);
  }

  public static fromApiData(data: ContainerApiData): Container {
    return new Container(data);
  }

  public static useLoadById(id: string, stackId: string): Container {
    const container = mittwaldApi.containerGetService
      .getResource({ path: { stackId, serviceId: id } })
      .useWatchData();

    return new Container(container);
  }

  public static useTryLoadByName(
    name: string,
    stackId: string,
  ): Container | undefined {
    const container = mittwaldApi.containerGetStack
      .getResource({ path: { stackId } })
      .useWatchData()
      .services?.find((s) => s.serviceName === name);

    return container ? new Container(container) : undefined;
  }

  public static useTryLoadById(
    id?: string,
    stackId?: string,
  ): Container | undefined {
    if (!id || !stackId) {
      return;
    }
    const container = mittwaldApi.containerGetService
      .getResource({ path: { serviceId: id, stackId } })
      .useWatchData({ optional: true });
    return container ? new Container(container) : undefined;
  }

  public static useLoadByPathParam(stackId: string): Container {
    const { containerId } = usePathParams("containerId");
    return Container.useLoadById(containerId, stackId);
  }

  public static async createNew(
    values: CreateContainerInputs,
    stackId: string,
  ): Promise<Container> {
    const serviceName = Container.createServiceName(values.description);

    const [volumesToBeCreated, existingVolumesAndProjectPaths] =
      ContainerUtils.splitAndMapVolumeFormValuesToNewAndExisting(
        values.volumes,
        serviceName,
      );

    const envs =
      values.envDefinitionType === "variables"
        ? values.environments
        : ContainerUtils.mapEnvTextToEntries(values.environmentText);
    const response = await mittwaldApi.containerUpdateStack.request({
      requestBody: {
        services: {
          [serviceName]: {
            description: values.description,
            image: values.imageReference,
            volumes: ContainerVolumeRelationList.fromFormData([
              ...existingVolumesAndProjectPaths,
              ...volumesToBeCreated,
            ]).asStringArray(),
            ports: ContainerPortList.fromFormData(values.ports).asStringArray(),
            envs: ContainerUtils.mapEnvFormValuesToApiValues(envs),
            entrypoint:
              values.entrypoint?.trim() &&
              values.entrypointSelection === "custom"
                ? values.entrypoint.split(" ")
                : [],
            command:
              values.command?.trim() && values.commandSelection === "custom"
                ? values.command.split(" ")
                : [],
          },
        },
        volumes:
          volumesToBeCreated.length > 0
            ? volumesToBeCreated.reduce(
                (prev: { [key: string]: { name: string } }, curr) => {
                  prev[curr.volume] = { name: curr.volume };
                  return prev;
                },
                {},
              )
            : [],
      },
      path: { stackId },
    });

    assertStatus(response, 200);

    const container = response.content.services?.find(
      (s) => s.serviceName === serviceName,
    );

    invariant(container, "Error while creating container");

    return Container.fromApiData(container);
  }

  public async updateContainer(
    values: UpdateContainerInputs,
  ): Promise<Container | undefined> {
    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            description: !trimAndCompareStrings(
              values.description,
              this.description,
            )
              ? values.description
              : undefined,
            image: !trimAndCompareStrings(
              values.imageReference,
              this.pendingState.image,
            )
              ? values.imageReference
              : undefined,
          },
        },
      },
    });

    assertStatus(result, 200);

    const containerData = result.content.services?.find(
      (s) => s.serviceName === this.serviceName,
    );

    return containerData ? Container.fromApiData(containerData) : undefined;
  }

  public async resetToDeployedState(): Promise<void> {
    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            image: this.deployedState.image,
            envs: this.deployedState.envs
              ? ContainerUtils.mapEnvFormValuesToApiValues(
                  this.deployedState.envs,
                )
              : [],
            ports: this.deployedState.ports?.asStringArray(),
            volumes: this.deployedState.volumes.asStringArray(),
          },
        },
      },
    });

    assertStatus(result, 200);
  }

  public async updateEntrypoint(
    values: UpdateContainerEntrypointInputs,
  ): Promise<Container | void> {
    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            entrypoint:
              !trimAndCompareStrings(values.entrypoint, this.entrypoint) &&
              values.entrypoint?.trim() &&
              values.entrypointSelection === "custom"
                ? values.entrypoint.split(" ")
                : [],
            command:
              !trimAndCompareStrings(values.command, this.command) &&
              values.command?.trim() &&
              values.commandSelection === "custom"
                ? values.command.split(" ")
                : [],
          },
        },
      },
    });

    assertStatus(result, 200);

    const containerData = result.content.services?.find(
      (s) => s.serviceName === this.serviceName,
    );

    return containerData ? Container.fromApiData(containerData) : undefined;
  }

  public async updateVolumes(
    values: ContainerVolumeRelationFormData[],
  ): Promise<Container | undefined> {
    const [volumesToBeCreated, existingVolumesAndProjectPaths] =
      ContainerUtils.splitAndMapVolumeFormValuesToNewAndExisting(
        values,
        this.serviceName,
      );

    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            volumes: ContainerVolumeRelationList.fromFormData([
              ...existingVolumesAndProjectPaths,
              ...volumesToBeCreated,
            ]).asStringArray(),
          },
        },
        volumes:
          volumesToBeCreated.length > 0
            ? volumesToBeCreated.reduce(
                (prev: { [key: string]: { name: string } }, curr) => {
                  prev[curr.volume] = { name: curr.volume };
                  return prev;
                },
                {},
              )
            : [],
      },
    });

    assertStatus(result, 200);

    const containerData = result.content.services?.find(
      (s) => s.serviceName === this.serviceName,
    );

    return containerData ? Container.fromApiData(containerData) : undefined;
  }

  public async updatePorts(
    values: ContainerPortFormData[],
  ): Promise<Container | undefined> {
    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            ports: ContainerPortList.fromFormData(values).asStringArray(),
          },
        },
      },
    });

    assertStatus(result, 200);

    const containerData = result.content.services?.find(
      (s) => s.serviceName === this.serviceName,
    );

    return containerData ? Container.fromApiData(containerData) : undefined;
  }

  public async updateEnvs(
    values: ContainerEnvVariable[],
  ): Promise<Container | undefined> {
    const result = await mittwaldApi.containerUpdateStack.request({
      path: { stackId: this.stackId },
      requestBody: {
        services: {
          [this.serviceName]: {
            envs: ContainerUtils.mapEnvFormValuesToApiValues(values),
          },
        },
      },
    });

    assertStatus(result, 200);

    const containerData = result.content.services?.find(
      (s) => s.serviceName === this.serviceName,
    );

    return containerData ? Container.fromApiData(containerData) : undefined;
  }

  public async delete(): Promise<void> {
    const result = await mittwaldApi.containerUpdateStack.request({
      requestBody: {
        services: {
          [this.serviceName]: {},
        },
      },
      path: { stackId: this.stackId },
    });

    assertStatus(result, 200);
  }

  public async recreate(): Promise<void> {
    const result = await mittwaldApi.containerRecreateService.request({
      path: { stackId: this.stackId, serviceId: this.id },
    });

    assertStatus(result, 204);
  }

  public async restart(): Promise<void> {
    const result = await mittwaldApi.containerRestartService.request({
      path: { stackId: this.stackId, serviceId: this.id },
    });

    assertStatus(result, 204);
  }

  public async stop(): Promise<void> {
    const result = await mittwaldApi.containerStopService.request({
      path: { stackId: this.stackId, serviceId: this.id },
    });

    assertStatus(result, 204);
  }

  public async start(): Promise<void> {
    const result = await mittwaldApi.containerStartService.request({
      path: { stackId: this.stackId, serviceId: this.id },
    });

    assertStatus(result, 204);
  }

  public async downloadLog(): Promise<void> {
    const response = await mittwaldApi.containerGetServiceLogs.request({
      path: { stackId: this.stackId, serviceId: this.id },
    });

    assertStatus(response, 200);

    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(response.content),
    );
    element.setAttribute("target", "_blank");
    element.setAttribute("download", `${this.serviceName}.log`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public useLog(): string {
    return mittwaldApi.containerGetServiceLogs
      .getResource({
        path: { stackId: this.stackId, serviceId: this.id },
      })
      .useWatchData();
  }

  public static async getImageMeta(
    imageRef: string,
    projectId: string,
  ): Promise<ImageMeta | undefined> {
    const response = await mittwaldApi.containerGetContainerImageConfig.request(
      {
        query: {
          imageReference: imageRef,
          useCredentialsForProjectId: projectId,
        },
      },
    );
    if (response.status !== 200) {
      return;
    }
    return response.content;
  }

  public async getImageMeta(): Promise<ImageMeta | undefined> {
    return Container.getImageMeta(this.pendingState.image, this.data.projectId);
  }

  public containerRecreatePageStatus: CheckPageState = () => {
    return this.recreateRequired ? "warning" : null;
  };

  public getPortSelectOptions(): SelectOptions | undefined {
    return this.pendingState.ports?.getSelectOptions();
  }

  public static createServiceName(description: string): string {
    return slugify(description, {
      lower: true,
      strict: true,
    });
  }
}

import { iconInvoice } from "@mittwald/flow-icons/dist/invoice";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Invoice from "../accounting/Invoice";

export class InvoiceModelRelation extends ModelRelation {
  public static type = new ModelRelationType("invoice", "invoice", iconInvoice);

  public constructor(invoiceId: string) {
    super(invoiceId, InvoiceModelRelation.type);
  }

  public useInvoice(): Invoice {
    return Invoice.useLoadByInvoiceId(this.id);
  }

  public useOptionalInvoice(): Invoice | undefined {
    return Invoice.useTryLoadByInvoiceId(this.id);
  }
}

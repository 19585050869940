import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { useAutoFormSubmit } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useAutoFormSubmit";
import { useFieldValidationOnChange } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useFieldValidationOnChange";
import { useResetFormAfterFailedSubmit } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useResetFormAfterFailedSubmit";

/** Can be used in combination with the VerificationCodeInput to have auto submit
 * with auto-verification after x characters
 * @param form
 * @param maxLength
 * @param inputName
 * @param conditions
 */
export const useCodeInputAutoSubmit = (
  form: FormController<any>,
  inputName = "multiFactorCode",
  maxLength = 6,
  conditions:
    | { reset: boolean | undefined; autoSubmit: boolean | undefined }
    | undefined = undefined,
): void => {
  const code = form.watch(inputName);
  const codeLengthCondition = code ? code.length >= maxLength : false;

  useFieldValidationOnChange(form, [inputName]);
  useResetFormAfterFailedSubmit(form, conditions?.reset);
  useAutoFormSubmit(
    form,
    conditions?.autoSubmit
      ? codeLengthCondition && conditions.autoSubmit
      : codeLengthCondition,
  );
};

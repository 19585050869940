import { useRequiredAccessToken } from "../../hooks/useRequiredAccessToken";
import { DateTime } from "luxon";
import { strictToISO } from "../../lib/strictToISO";
import MetricsQueryRequest from "../metrics/MetricsQueryRequest";
import MetricsQueryResponse from "../metrics/MetricsQueryResponse";
import MetricsTimeRange from "../metrics/MetricsTimeRange";

export interface MetricsRequestOptions {
  range?: MetricsTimeRange;
  from?: DateTime;
  to?: DateTime;
  instant?: boolean;
}

export class Metrics {
  public static executeRequest(
    expression: string,
    options?: MetricsRequestOptions,
  ): MetricsQueryResponse {
    const from =
      strictToISO(options?.from) ?? strictToISO(options?.range?.getFromDate());

    const to =
      strictToISO(options?.to) ?? strictToISO(options?.range?.getToDate());

    const accessToken = useRequiredAccessToken();

    return new MetricsQueryRequest({
      backendUri: import.meta.env.VITE_METRICS_URI,
      accessToken,
      requestApiData: {
        queries: [
          {
            datasource: { uid: "MetricsAuthPrometheus", type: "prometheus" },
            expr: expression,
            refId: MetricsQueryRequest.defaultRefId,
            maxDataPoints: 1000,
            instant: options?.instant,
          },
        ],
        from,
        to,
      },
    }).useData({
      autoRefresh: {
        minutes: 3,
      },
    });
  }
}

import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import RedisDatabase from "./RedisDatabase";

export class RedisDatabaseList extends ListModel<RedisDatabase> {
  public static useLoadAllByProjectId(projectId: string): RedisDatabaseList {
    const data = mittwaldApi.databaseListRedisDatabases
      .getResource({ path: { projectId } })
      .useWatchData();

    const databases = data.map((d) => RedisDatabase.fromApiData(d));
    return new RedisDatabaseList(databases);
  }
  public static useTryLoadAllByProjectId(
    projectId?: string,
  ): RedisDatabaseList {
    const data = mittwaldApi.databaseListRedisDatabases
      .getResource(projectId ? { path: { projectId } } : null)
      .useWatchData();

    const databases = data ? data.map((d) => RedisDatabase.fromApiData(d)) : [];
    return new RedisDatabaseList(databases);
  }
}

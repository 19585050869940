import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { mittwaldApi } from "../../api/Mittwald";
import isNonEmptyArray from "../../lib/isNonEmptyArray";
import MachineType from "../server/MachineType";
import {
  Article,
  ArticleApiData,
  StorageArticleAttribute,
  StorageArticleModifier,
} from "./";

export class HostingArticle extends Article {
  public readonly machineType: MachineType;
  public readonly baseStorageAttribute: StorageArticleAttribute;
  public readonly storageModifier: StorageArticleModifier;

  public constructor(data: ArticleApiData) {
    super(data);
    this.machineType = this.getMachineType();
    this.baseStorageAttribute = this.getBaseStorageAttribute();
    this.storageModifier = this.constructStorageModifier();
  }

  private getMachineType(): MachineType {
    return MachineType.fromArticle(this);
  }

  private getBaseStorageAttribute(): StorageArticleAttribute {
    const storageAttributes = this.attributes.filter(
      (a): a is StorageArticleAttribute => a instanceof StorageArticleAttribute,
    );

    invariant(
      isNonEmptyArray(storageAttributes),
      `Storage attribute must not be empty. Article: ${this.id}`,
    );

    return storageAttributes[0];
  }

  private constructStorageModifier(): StorageArticleModifier {
    const modifier = this.modifiers.filter(
      (m): m is StorageArticleModifier => m instanceof StorageArticleModifier,
    )[0];

    invariant(
      modifier !== undefined,
      `Storage modifier must not be empty. Article: ${this.id}`,
    );

    return modifier;
  }

  public static async getArticle(articleId: string): Promise<HostingArticle> {
    const articleResponse = await mittwaldApi.articleGetArticle.request({
      path: {
        articleId,
      },
    });

    assertStatus(articleResponse, 200);

    return new HostingArticle(articleResponse.content);
  }
}

export default HostingArticle;

import { MittwaldApi } from "../../../api/Mittwald";

export type ProcessApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_Process;

export type Type =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_ProcessType;

export type State =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_ProcessState;

export class Process {
  public readonly data: ProcessApiData;
  public readonly id: string;
  public readonly type: Type;
  public readonly state: State;
  public readonly error: string | undefined;

  public constructor(data: ProcessApiData) {
    this.data = Object.freeze(data);
    this.id = data.transactionId;
    this.type = data.processType;
    this.state = data.state;
    this.error = data.error;
  }
}

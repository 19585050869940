import { faCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import Form, {
  FormController,
  useForm,
} from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Customer from "../../../../../model/customer/Customer";
import {
  InvoiceSettings,
  InvoiceSettingsInputs,
} from "../../../../../model/customer/InvoiceSettings";
import DebitForm from "../../payment/components/DebitForm";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import {
  getFormDefaultValues,
  InvoiceSettingsInputsWithStringInvoicePeriod,
} from "../../payment/components/PaymentSettingsPage";

interface Props {
  customer: Customer;
  paymentSettingsForm: FormController<InvoiceSettingsInputsWithStringInvoicePeriod>;
}

export const ReactivateDebit: FC<Props> = (props) => {
  const { customer, paymentSettingsForm } = props;
  const visibility = useVisibilityController();

  const invoiceSettings = customer.useInvoiceSettings();

  const reactionFormDefaultValues: InvoiceSettingsInputs = {
    ...getFormDefaultValues(invoiceSettings),
    paymentSettings: {
      iban: "",
      confirmDebit: false,
      accountHolder: "",
      bic: "",
      method: "debit",
    },
    invoicePeriod: invoiceSettings?.data.invoicePeriod ?? 1,
  };

  const form = useForm<InvoiceSettingsInputs>({
    defaultValues: reactionFormDefaultValues,
    translationKey: "reactivateDebit",
    onSubmit: async (values) => {
      await InvoiceSettings.update(values, customer.id);

      paymentSettingsForm.reset({
        ...getFormDefaultValues(invoiceSettings),
        paymentSettings: {
          method: "debit",
          iban: values.paymentSettings.iban,
          bic: values.paymentSettings.bic,
          accountHolder: values.paymentSettings.accountHolder,
          confirmDebit: true,
        },
      });
    },
  });

  return (
    <DefaultModal
      primary={{
        action: [{ form }, visibility.hide],
        text: "reactivateDebit",
        ok: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Layout>
          <Section.Item headline="checkBankDetails" headlineIcon={faCreditCard}>
            <Text i18n={{ id: "description" }} />
          </Section.Item>
          <Section.Item>
            <DebitForm />
          </Section.Item>
        </Section.Layout>
      </Form>
    </DefaultModal>
  );
};

export default ReactivateDebit;

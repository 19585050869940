import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoDate,
  demoEmail,
  demoSessionToken,
  demoUrl,
  demoUserId,
} from "../staticDemoData";
import {
  demoApiToken,
  demoBarcode,
  demoProfile,
  demoSession,
  demoSshKey,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockUserGetOwnEmail(
    {},
    { status: 200, content: { email: demoEmail } },
  );

  mittwaldApiRequestMocking.mockUserGetPasswordUpdatedAt(
    {},
    { status: 200, content: demoDate },
  );

  mittwaldApiRequestMocking.mockUserGetMfaStatus(
    {},
    { status: 200, content: { confirmed: false, initialized: false } },
  );

  mittwaldApiRequestMocking.mockUserDeleteUser({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserChangePassword(
    {},
    { status: 200, content: { token: demoSessionToken } },
  );

  mittwaldApiRequestMocking.mockUserTerminateAllSessions({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserInitPasswordReset({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserConfirmPasswordReset({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserAuthenticate(
    {},
    { status: 200, content: { token: demoSessionToken, expires: demoDate } },
  );

  mittwaldApiRequestMocking.mockUserAuthenticateMfa(
    {},
    { status: 200, content: { token: demoSessionToken, expires: demoDate } },
  );

  mittwaldApiRequestMocking.mockUserDisableMfa({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserRegister(
    {},
    { status: 201, content: { userId: demoUserId } },
  );

  mittwaldApiRequestMocking.mockUserInitMfa(
    {},
    { status: 200, content: { barcode: demoBarcode, url: demoUrl } },
  );

  mittwaldApiRequestMocking.mockUserConfirmMfa(
    {},
    {
      status: 200,
      content: { recoveryCodesList: ["recoveryCode"] },
    },
  );

  mittwaldApiRequestMocking.mockUserAuthenticateMfa(
    {},
    {
      status: 200,
      content: { token: demoSessionToken, expires: demoDate },
    },
  );

  mittwaldApiRequestMocking.mockUserResetRecoverycodes(
    {},
    { status: 200, content: { recoveryCodesList: ["ResetCode"] } },
  );

  mittwaldApiRequestMocking.mockUserVerifyRegistration({}, { status: 200 });

  mittwaldApiRequestMocking.mockUserLogout({}, () => ({ status: 200 }));

  mittwaldApiRequestMocking.mockUserListSessions(
    {},
    {
      status: 200,
      content: [demoSession],
    },
  );

  mittwaldApiRequestMocking.mockUserGetSession(
    { path: { tokenId: "*" } },
    { status: 200, content: demoSession },
  );

  mittwaldApiRequestMocking.mockUserChangeEmail({}, { status: 204 });

  mittwaldApiRequestMocking.mockUserResendVerificationEmail(
    {},
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserVerifyEmail({}, { status: 204 });

  mittwaldApiRequestMocking.mockUserTerminateSession(
    { path: { tokenId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockUserCheckToken(
    {},
    { status: 200, content: { id: "1", publicToken: demoSessionToken } },
  );

  mittwaldApiRequestMocking.mockUserSupportCodeRequest(
    {},
    { status: 200, content: { supportCode: "F8G3K6" } },
  );

  mittwaldApiRequestMocking.mockUserListSshKeys(
    {},
    { status: 200, content: { sshKeys: [demoSshKey] } },
  );

  mittwaldApiRequestMocking.mockUserDeleteSshKey(
    { path: { sshKeyId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockUserCreateSshKey({}, { status: 201 });

  mittwaldApiRequestMocking.mockUserGetSshKey(
    { path: { sshKeyId: "*" } },
    { status: 200, content: { sshKey: demoSshKey } },
  );

  mittwaldApiRequestMocking.mockUserEditSshKey(
    { path: { sshKeyId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockUserListApiTokens(
    {},
    { status: 200, content: [demoApiToken] },
  );

  mittwaldApiRequestMocking.mockUserDeleteApiToken(
    { path: { apiTokenId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockUserCreateApiToken(
    {},
    { status: 201, content: { token: "123456789" } },
  );

  mittwaldApiRequestMocking.mockUserGetApiToken(
    { path: { apiTokenId: "*" } },
    { status: 200, content: demoApiToken },
  );

  mittwaldApiRequestMocking.mockUserEditApiToken(
    { path: { apiTokenId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockUserListFeedback(
    { path: { userId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockUserGetUser(
    { path: { userId: "*" } },
    { status: 200, content: demoProfile },
  );

  mittwaldApiRequestMocking.mockUserUpdatePersonalInformation(
    { path: { userId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserRequestAvatarUpload(
    { path: { userId: "*" } },
    { status: 200, content: { refId: "1" } },
  );

  mittwaldApiRequestMocking.mockUserRemoveAvatar(
    { path: { userId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserAddPhoneNumber(
    { path: { userId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserVerifyPhoneNumber(
    { path: { userId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserRemovePhoneNumber(
    { path: { userId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockUserGetOwnAccount(
    {},
    { status: 200, content: demoProfile },
  );

  mittwaldApiRequestMocking.mockUserUpdateAccount({}, { status: 200 });

  mittwaldApiRequestMocking.mockFileGetFileUploadTypeRules(
    { path: { fileUploadType: "avatar" } },
    {
      status: 200,
      content: { maxSizeInKB: 3000, mimeTypes: ["image/jpeg"] },
    },
  );
};

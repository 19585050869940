import { ServerApiData } from "../../model/server/Server";
import { demoDate } from "../staticDemoData";

export const demoServer: ServerApiData = {
  customerId: "1",
  description: "Demo Server",
  id: "1",
  createdAt: demoDate,
  isReady: true,
  status: "ready",
  readiness: "ready",
  shortId: "1234567",
  machineType: {
    name: "shared.2xlarge",
    cpu: "4",
    memory: "8Gi",
  },
  clusterName: "fabbenstedt",
  storage: "50Gi",
};

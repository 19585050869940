import ListModel from "../../misc/ListModel";
import { Process, ProcessApiData, State, Type } from "./Process";

export class ProcessList<
  T extends ProcessApiData = ProcessApiData,
> extends ListModel<Process> {
  public constructor(items: T[]) {
    super(items.map((i) => new Process(i)));
  }

  private containsType(type: Type): boolean {
    return this.items.some((i) => i.type === type);
  }

  private containsState(processState: State): boolean {
    return this.items.some((i) => i.state === processState);
  }

  private containsStateWithType(processState: State, type: Type): boolean {
    return this.items.some((i) => i.state === processState && i.type === type);
  }

  public getFirstState(processState: State): Process | undefined {
    return this.items.find((i) => i.state === processState);
  }

  public isOwnerContactChangeFailed(): boolean {
    return this.containsStateWithType("FAILED", "OWNER_CHANGE");
  }

  public isAuthCodeMismatchError(): boolean {
    return this.items.some((i) =>
      i.error && i.state === "FAILED"
        ? i.error.includes("AuthCodeMismatchError") ||
          i.error.includes("ErrWrongAuthCode") ||
          i.error.includes("AuthInfo does not match")
        : false,
    );
  }

  public hasFailedProcess(): boolean {
    return this.containsState("FAILED");
  }

  public hasFailedTransfer(): boolean {
    return this.containsStateWithType("FAILED", "TRANSFER");
  }

  public hasOwnerChange(): boolean {
    return this.containsType("OWNER_CHANGE");
  }

  public containsDeclareRequested(): boolean {
    return this.items.some((i) => i.type === "DECLARE_REQUESTED");
  }
}

import { Options } from "@mittwald/api-client/dist/http/KyClient";

const kyDefaultRetryStatusCodes = [408, 413, 429, 500, 502, 503, 504];

const customRetryStatusCodes = [
  /**
   * Accessibility to objects may be setup asynchronously in the backend.
   * Doing a retry on "403 - Forbidden" grants the backend some time for this.
   */
  403, /**
   * Objects are created asynchronously in the backend.
   * Doing a retry on "404 - Not found" grants the backend some time for this.
   */ 404, 412,
];

export const defaultOptions = (limit?: number): Options["ky"] => ({
  retry: {
    statusCodes: [...kyDefaultRetryStatusCodes, ...customRetryStatusCodes],
    limit: limit ?? 3,
  },
  timeout: 30000,
  hooks: {},
});

export default defaultOptions;

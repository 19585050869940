import ApiDataError from "../../errors/ApiDataError";
import Bytes from "../misc/Bytes";
import { ArticleAttribute, ArticleAttributeApiData } from "./";

export class StorageArticleAttribute extends ArticleAttribute {
  public readonly bytes: Bytes;
  public static readonly key = "storage";

  public constructor(apiData: ArticleAttributeApiData) {
    super(apiData);
    if (this.unit === undefined) {
      throw new ApiDataError("Storage article attribute must have a unit");
    }
    this.bytes = Bytes.parse(this.valueWithUnit);
  }
}

export default StorageArticleAttribute;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import User from "../user/User";
import { Cronjob } from "./Cronjob";

export type CronjobExecutionStatusType =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Cronjob_CronjobExecution["status"];

export type CronjobExecutionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Cronjob_CronjobExecution;

type CronjobExecutionStatusEnum = Required<{
  [TKey in CronjobExecutionStatusType]: TKey;
}>;

export const CronjobExecutionStatus: CronjobExecutionStatusEnum = {
  Running: "Running",
  AbortedBySystem: "AbortedBySystem",
  AbortedByUser: "AbortedByUser",
  Complete: "Complete",
  Failed: "Failed",
  Pending: "Pending",
  TimedOut: "TimedOut",
};

export const cronjobMinTimeout = 1;
export const cronjobMaxTimeout = 86400;
export const cronjobDefaultTimeout = 3600;

export class CronjobExecution {
  public readonly id: string;
  public readonly data: CronjobExecutionApiData;
  public readonly durationInSeconds?: number;
  public readonly isRunning: boolean;
  public readonly hasLog: boolean;

  public cronjob: Cronjob;

  private constructor(data: CronjobExecutionApiData, cronjob: Cronjob) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.durationInSeconds = data.durationInMilliseconds
      ? Math.floor(data.durationInMilliseconds / 1000)
      : undefined;
    this.cronjob = cronjob;
    this.isRunning = data.status === "Running";
    this.hasLog = !!data.logPath;
  }

  public static fromApiData(
    data: CronjobExecutionApiData,
    cronjob: Cronjob,
  ): CronjobExecution {
    return new CronjobExecution(data, cronjob);
  }

  public static useLoadById(
    cronjob: Cronjob,
    executionId: string,
  ): CronjobExecution {
    const data = mittwaldApi.cronjobGetExecution
      .getResource({
        path: { cronjobId: cronjob.id, executionId },
      })
      .useWatchData();

    return CronjobExecution.fromApiData(data, cronjob);
  }

  public static useLoadByPathParam(cronjob: Cronjob): CronjobExecution {
    const { executionId } = usePathParams("executionId");
    return CronjobExecution.useLoadById(cronjob, executionId);
  }

  public useTryTriggeredByUserName(): string | undefined {
    if (this.data.triggeredBy?.id) {
      const user = User.useLoadById(this.data.triggeredBy.id);
      return user.fullName;
    }
  }

  public async downloadLog(): Promise<void> {
    if (!this.cronjob.data.projectId) {
      throw new Error("projectId not found");
    }
    const response = await mittwaldApi.projectFileSystemGetFileContent.request({
      path: { projectId: this.cronjob.data.projectId },
      query: { file: this.data.logPath },
    });

    assertStatus(response, 200);

    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(response.content),
    );
    element.setAttribute("target", "_blank");
    element.setAttribute("download", `${this.id}.log`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public useLog(): string | undefined {
    if (!this.cronjob.data.projectId) {
      throw new Error("projectId not found");
    }
    return mittwaldApi.projectFileSystemGetFileContent
      .getResource({
        path: { projectId: this.cronjob.data.projectId },
        query: { file: this.data.logPath },
      })
      .useWatchData({ optional: true });
  }
}

import isDefined from "@mittwald/flow-components/dist/lib/isDefined";
import * as R from "remeda";
import { MittwaldApi } from "../../../api/Mittwald";
import { ResolveSchemaReturn } from "../../../lib/jsonSchema";
import AppUserInput from "../../app/AppUserInput";
import AppVersion from "../../app/AppVersion";
import SystemSoftwareUserInput from "../../app/SystemSoftwareUserInput";
import {
  SystemSoftwareVersion,
  SystemSoftwareVersionApiData,
} from "../../app/SystemSoftwareVersion";
import DomainHandleUserInput from "../../domain/DomainHandleUserInput";
import ListModel from "../ListModel";
import UserInput, {
  UserInputFilterOptions,
  UserInputFormatTypes,
  UserInputName,
} from "./UserInput";
import { UserInputDataSourceNameLoose } from "./UserInputDataSource";

type AppVersionApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppVersion;

export class UserInputList extends ListModel<UserInput> {
  public static fromAppVersionData(
    appVersion: AppVersion,
    data: AppVersionApiSchema,
  ): UserInputList {
    const items =
      data.userInputs?.map((u) => AppUserInput.fromApiData(appVersion, u)) ??
      [];
    return new UserInputList(items);
  }

  public static fromSoftwareVersionData(
    systemSoftwareVersion: SystemSoftwareVersion,
    data: SystemSoftwareVersionApiData,
  ): UserInputList {
    const items =
      data.userInputs?.map((u) =>
        SystemSoftwareUserInput.fromApiData(systemSoftwareVersion, u),
      ) ?? [];
    return new UserInputList(items);
  }

  public static fromHandleSchema(
    data: ResolveSchemaReturn<any>,
  ): UserInputList {
    if (!data || R.pipe(data, R.keys).length === 0) {
      throw new Error("no properties");
    }

    return new UserInputList(
      R.pipe(
        data,
        R.values,
        R.map((data) => {
          return DomainHandleUserInput.fromApiData({
            schema: data.schema,
            name: String(data.name),
            format: data.schema.format as UserInputFormatTypes,
            required: data.required,
          });
        }),
      ),
    );
  }

  public add(input: UserInput): UserInputList {
    return new UserInputList([...this.items, input]);
  }

  public findByName(name: string | UserInputName): UserInput | undefined {
    return this.items.find((i) => i.name === name);
  }

  public findByDataSource(
    name: UserInputDataSourceNameLoose,
  ): UserInput | undefined {
    return this.items.find((i) => i.dataSource?.name === name);
  }

  public getAdditionalSteps(): string[] {
    return Array.from(
      new Set(
        this.items
          .map((i) => i.positionMeta)
          .filter(isDefined)
          .map((m) => m.step)
          .filter(isDefined),
      ).values(),
    );
  }

  public filter(options: UserInputFilterOptions): UserInputList {
    const filtered = this.items.filter((item) => item.matches(options));

    return new UserInputList(filtered);
  }
}

export default UserInputList;

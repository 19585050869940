export const checkHostnameMatchesWildcards = (
  hostname: string,
  wildcardHostnames: Array<string | undefined>,
): boolean => {
  for (const wildCardHostname of wildcardHostnames) {
    if (wildCardHostname) {
      if (wildCardHostname === hostname) {
        return true;
      }

      if (wildCardHostname.startsWith("*.")) {
        const hostnameParts = hostname.split(".");
        if (
          hostnameParts.slice(1, hostnameParts.length).join(".") ===
          wildCardHostname.replace("*.", "")
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

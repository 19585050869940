import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import Customer from "../../customer/Customer";
import { CustomerList } from "../../customer/CustomerList";

export class CustomerListUI {
  public readonly customerList: CustomerList;

  private constructor(customerList: CustomerList) {
    this.customerList = customerList;
  }

  public static of(customerList: CustomerList): CustomerListUI {
    return new CustomerListUI(customerList);
  }

  public useSelectOptions(propertyKey: keyof Customer = "id"): SelectOptions {
    const customers = this.customerList.useItems();
    const bankruptCustomers = this.customerList.useFilterByStatus("bankrupt");

    return customers
      .filter(
        (c) =>
          !bankruptCustomers.includes(c) &&
          c.checkMyRoleIsIn("member", "owner") &&
          !c.data.isBanned &&
          !c.data.isInDefaultOfPayment,
      )
      .map((c) => ({
        label: { text: c.name },
        value: c[propertyKey],
      }));
  }
}

export default CustomerListUI;

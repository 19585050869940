import HttpResource from "@mittwald/flow-lib/dist/resources/HttpResource";
import resourceFactoryBuilder from "@mittwald/flow-lib/dist/resources/resourceFactoryBuilder";
import { DateTime, Duration, DurationLikeObject } from "luxon";
import { useEffect } from "react";
import { MetricsQueryResponse } from "./MetricsQueryResponse";
import {
  MetricsQueryRequestOptions,
  MetricsQueryResponseApiData,
} from "./types";

const metricsHttpResource = resourceFactoryBuilder(
  (options: MetricsQueryRequestOptions) => {
    const { from, to, ...restApiData } = options.requestApiData;
    return new HttpResource<MetricsQueryResponseApiData>(options.backendUri, {
      json: {
        from: from ? from : DateTime.now().toISO(),
        to: to ? to : DateTime.now().toISO(),
        ...restApiData,
      },
      method: "post",
      headers: {
        "x-access-token": options.accessToken,
      },
    });
  },
);

interface UseDataOptions {
  autoRefresh?: DurationLikeObject;
}

export class MetricsQueryRequest {
  public static defaultRefId = "Result";

  private readonly options: MetricsQueryRequestOptions;

  public constructor(options: MetricsQueryRequestOptions) {
    this.options = options;
  }

  public useData(opts: UseDataOptions = {}): MetricsQueryResponse {
    const { autoRefresh } = opts;
    const autoRefreshMs = autoRefresh
      ? Duration.fromObject(autoRefresh).as("milliseconds")
      : undefined;

    const resource = metricsHttpResource(this.options);

    useEffect(() => {
      if (!autoRefreshMs) {
        return;
      }

      const interval = setInterval(() => {
        resource.expire({ autoReload: true });
      }, autoRefreshMs);

      return () => {
        clearInterval(interval);
      };
    }, [autoRefreshMs]);

    return new MetricsQueryResponse(resource.useWatchData());
  }
}

export default MetricsQueryRequest;

import { UserInputName } from "./UserInput";
import UserInputRecord, {
  ParsedValue,
  UserInputRecordApiSchema,
  UserInputRecordObject,
} from "./UserInputRecord";

export type UserInputsRecordObject = Record<string, ParsedValue>;

export class UserInputRecordList {
  public items: UserInputRecord[];

  private constructor(items: UserInputRecord[]) {
    this.items = items;
  }

  public isEmpty(): boolean {
    return this.items.length === 0;
  }

  public static fromApiData(
    data: UserInputRecordApiSchema[],
  ): UserInputRecordList {
    return new UserInputRecordList(
      data.map((item) => UserInputRecord.fromApiData(item)),
    );
  }

  public toObject(): UserInputsRecordObject {
    return Object.fromEntries(
      this.items.map((item) => [item.name, item.parsedValue]),
    );
  }

  public static fromObject(
    object: UserInputsRecordObject,
  ): UserInputRecordList {
    const items = Object.entries(object).map((item) =>
      UserInputRecord.fromObjectEntry(item),
    );
    return new UserInputRecordList(items);
  }

  public static toObjectList(
    object: UserInputsRecordObject = {},
  ): UserInputRecordObject[] {
    return UserInputRecordList.fromObject(object)
      .items.map((item) => item.toObject())
      .filter((item) => "value" in item && item.value);
  }

  public findByName(name: string | UserInputName): UserInputRecord | undefined {
    return this.items.find((i) => i.name === name);
  }
}

export default UserInputRecordList;

import { faSidebar } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown";
import { faCloudPlus } from "@fortawesome/pro-regular-svg-icons/faCloudPlus";
import { faHandsHoldingDiamond } from "@fortawesome/pro-regular-svg-icons/faHandsHoldingDiamond";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import React, { FC } from "react";
import Container from "../styled/Container";

export const ProSpaceSection: FC = () => {
  return (
    <Container>
      <InfoBox.InfoItem icon={faHandsHoldingDiamond} text="vm" />
      <InfoBox.InfoItem icon={faArrowUpArrowDown} text="upgrades" />
      <InfoBox.InfoItem icon={faSidebar} text="mStudioManagementRedis" />
      <InfoBox.InfoItem icon={faCloudPlus} text="cloud" />
    </Container>
  );
};

export default ProSpaceSection;

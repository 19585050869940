import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoConversation,
  demoConversationCategory,
  demoConversationMessage,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockConversationListConversations(
    {},
    { status: 200, content: [demoConversation] },
  );

  mittwaldApiRequestMocking.mockConversationListMessagesByConversation(
    { path: { conversationId: "*" } },
    { status: 200, content: [demoConversationMessage] },
  );

  mittwaldApiRequestMocking.mockConversationGetConversation(
    { path: { conversationId: "*" } },
    { status: 200, content: demoConversation },
  );

  mittwaldApiRequestMocking.mockConversationCreateConversation(
    {},
    {
      status: 201,
      content: { conversationId: demoConversation.conversationId },
    },
  );

  mittwaldApiRequestMocking.mockConversationCreateMessage(
    { path: { conversationId: "*" } },
    { status: 201 },
  );

  mittwaldApiRequestMocking.mockConversationListCategories(
    {},
    { status: 200, content: [demoConversationCategory] },
  );

  mittwaldApiRequestMocking.mockConversationSetConversationStatus(
    { path: { conversationId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockFileGetFileUploadTypeRules(
    { path: { fileUploadType: "conversation" } },
    {
      status: 200,
      content: { maxSizeInKB: 3000, mimeTypes: ["image/jpeg"] },
    },
  );

  mittwaldApiRequestMocking.mockConversationUpdateConversation(
    { path: { conversationId: "*" } },
    { status: 200 },
  );
};

import { types } from "mobx-state-tree";

export const model = types
  .model({
    firstFactor: types.maybe(
      types.model({
        email: types.string,
        password: types.string,
      }),
    ),
  })
  .actions((self) => ({
    setFirstFactorInformation: (email: string, password: string) => {
      self.firstFactor = {
        email,
        password,
      };
    },
    clearFirstFactorInformation: () => {
      self.firstFactor = undefined;
    },
  }));

export const loginStore = model.create({
  firstFactor: undefined,
});

export default loginStore;

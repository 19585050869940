import { MittwaldApi } from "../../api/Mittwald";
import Conversation from "./Conversation";
import ConversationItem from "./ConversationItem";

type ApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_StatusUpdate;

export class ConversationStatusUpdate extends ConversationItem {
  public readonly content: string;
  public readonly data: ApiSchema;

  private constructor(
    conversation: Conversation,
    index: number,
    data: ApiSchema,
  ) {
    super(conversation, index, data);
    this.content = data.messageContent;
    this.data = Object.freeze(data);
  }

  public static fromApiData(
    conversation: Conversation,
    index: number,
    data: ApiSchema,
  ): ConversationStatusUpdate {
    return new ConversationStatusUpdate(conversation, index, data);
  }
}

export default ConversationStatusUpdate;

import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

export type ContributorApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_Contributor;

class Contributor {
  public readonly data: ContributorApiData;

  public constructor(data: ContributorApiData) {
    this.data = Object.freeze(data);
  }

  public static useLoadById(contributorId: string): Contributor {
    const data = mittwaldApi.extensionGetContributor
      .getResource({ path: { contributorId } })
      .useWatchData();

    return new Contributor(data);
  }

  public static useTryLoadById(
    contributorId: string | undefined,
  ): Contributor | undefined {
    const data = mittwaldApi.extensionGetContributor
      .getResource(contributorId ? { path: { contributorId } } : null)
      .useWatchData({ optional: true });

    return data ? new Contributor(data) : undefined;
  }

  public static async loadById(contributorId: string): Promise<Contributor> {
    const data = await mittwaldApi.extensionGetContributor
      .getResource({ path: { contributorId } })
      .load();

    return new Contributor(data);
  }
}

export default Contributor;

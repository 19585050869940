import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { mittwaldApi } from "../../api/Mittwald";
import Bytes from "../misc/Bytes";
import MoneyValue from "../misc/MoneyValue";
import { ArticleApiData, HostingArticle, HostingPrices } from "./";

export enum ProSpaceArticleTag {
  proSpaceLite = "ps-basic",
  proSpace = "ps-plus",
  proSpaceDedicated = "ps-dedicated",
}

export enum ProSpaceResourceTag {
  cpu = "cpu-optimized",
  ram = "ram-optimized",
  balanced = "balance-optimized",
}

export interface ProSpaceArticleOrderPreviewInput {
  storageSize: Bytes;
}

export interface OrderProSpaceInput {
  description: string;
  customerId: string;
  storageSizeInBytes: number;
  useFreeTrial?: boolean;
  promotionCode?: string;
}

export interface AdjustProSpaceInput {
  storageSizeInBytes: number;
  contractId: string;
  article: ProSpaceArticle;
}

export class ProSpaceArticle extends HostingArticle {
  public readonly articleTag?: ProSpaceArticleTag;
  public readonly resourceTag?: ProSpaceResourceTag;

  public constructor(data: ArticleApiData) {
    super(data);
    this.articleTag = data.tags?.find((t) =>
      Object.values(ProSpaceArticleTag).includes(t.name as ProSpaceArticleTag),
    )?.name as ProSpaceArticleTag;
    this.resourceTag = data.tags?.find((t) =>
      Object.values(ProSpaceResourceTag).includes(
        t.name as ProSpaceResourceTag,
      ),
    )?.name as ProSpaceResourceTag;
  }

  public async order(input: OrderProSpaceInput): Promise<string> {
    const {
      description,
      storageSizeInBytes,
      customerId,
      useFreeTrial,
      promotionCode,
    } = input;

    const storageSize = Bytes.of(storageSizeInBytes, "bytes");

    const response = await mittwaldApi.orderCreateOrder.request({
      requestBody: {
        orderType: "projectHosting",
        orderData: {
          customerId,
          description,
          diskspaceInGiB: storageSize.in("GiB"),
          spec: this.machineType.name
            ? { machineType: this.machineType.name }
            : {
                vcpu: this.machineType.cpu,
                ram: this.machineType.memory.in("GiB"),
              },
          useFreeTrial,
          promotionCode,
        },
      },
    });

    assertStatus(response, 201);

    return response.content.orderId;
  }

  public useOrderPreview(
    input: ProSpaceArticleOrderPreviewInput,
  ): HostingPrices {
    const { storageSize } = input;

    const data = mittwaldApi.orderPreviewOrder
      .getResource({
        requestBody: {
          orderType: "projectHosting",
          orderData: {
            diskspaceInGiB: storageSize.in("GiB"),
            spec: this.machineType.name
              ? { machineType: this.machineType.name }
              : {
                  vcpu: this.machineType.cpu,
                  ram: this.machineType.memory.in("GiB"),
                },
          },
        },
      })
      .useWatchData();

    invariant("storagePrice" in data, "Expected hosting order preview");

    return new HostingPrices(
      new MoneyValue(data.machineTypePrice),
      new MoneyValue(data.storagePrice),
    );
  }

  public async getOrderPreview(
    input: ProSpaceArticleOrderPreviewInput,
  ): Promise<HostingPrices> {
    const { storageSize } = input;

    const response = await mittwaldApi.orderPreviewOrder.request({
      requestBody: {
        orderType: "projectHosting",
        orderData: {
          diskspaceInGiB: storageSize.in("GiB"),
          spec: this.machineType.name
            ? { machineType: this.machineType.name }
            : {
                vcpu: this.machineType.cpu,
                ram: this.machineType.memory.in("GiB"),
              },
        },
      },
    });

    assertStatus(response, 200);
    invariant(
      "storagePrice" in response.content,
      "Expected hosting order preview",
    );

    return new HostingPrices(
      new MoneyValue(response.content.machineTypePrice),
      new MoneyValue(response.content.storagePrice),
    );
  }
}

export default ProSpaceArticle;

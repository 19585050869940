import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoSftpUser, demoSshUser } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockSftpUserCreateSftpUser(
    { path: { projectId: "*" } },
    { status: 201, content: demoSftpUser },
  );

  mittwaldApiRequestMocking.mockSftpUserUpdateSftpUser(
    { path: { sftpUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockSftpUserGetSftpUser(
    { path: { sftpUserId: "*" } },
    { status: 200, content: demoSftpUser },
  );

  mittwaldApiRequestMocking.mockSftpUserDeleteSftpUser(
    { path: { sftpUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockSftpUserListSftpUsers(
    { path: { projectId: "*" } },
    { status: 200, content: [demoSftpUser] },
  );

  mittwaldApiRequestMocking.mockSshUserCreateSshUser(
    { path: { projectId: "*" } },
    { status: 201, content: demoSshUser },
  );

  mittwaldApiRequestMocking.mockSshUserUpdateSshUser(
    { path: { sshUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockSshUserGetSshUser(
    { path: { sshUserId: "*" } },
    { status: 200, content: demoSshUser },
  );

  mittwaldApiRequestMocking.mockSshUserDeleteSshUser(
    { path: { sshUserId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockSshUserListSshUsers(
    { path: { projectId: "*" } },
    { status: 200, content: [demoSshUser] },
  );
};

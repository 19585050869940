import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import UniversalBoundary from "@mittwald/flow-components/dist/components/UniversalBoundary";
import {
  HookSourcedValue,
  useUnpackedValue,
} from "@mittwald/flow-lib/dist/hooks/useUnpackedValue";
import React, { FC } from "react";
import CustomerContact from "../../../../model/customer/CustomerContact";

interface Props {
  contact: HookSourcedValue<CustomerContact | undefined>;
}

const loadingView = (
  <Text>
    <Skeleton count={5} />
  </Text>
);

export const CustomerContactInfos: FC<Props> = (props) => (
  <UniversalBoundary errorView={loadingView} loadingView={loadingView}>
    <Render>
      {() => {
        const contact = useUnpackedValue(props.contact);

        if (!contact) {
          return loadingView;
        }

        return (
          <>
            {contact.data.company && <Text>{contact.data.company}</Text>}
            {contact.data.address.addressPrefix && (
              <Text>{contact.data.address.addressPrefix}</Text>
            )}
            {contact.data.firstName && contact.data.lastName && (
              <Text>{`${contact.data.firstName} ${contact.data.lastName}`}</Text>
            )}
            <Text>{`${contact.data.address.street} ${contact.data.address.houseNumber}`}</Text>
            <Text>{`${contact.data.address.zip} ${contact.data.address.city}`}</Text>
            <Text>{contact.data.emailAddress}</Text>
            {contact.phoneNumber && (
              <Text>{contact.phoneNumber.formatted()}</Text>
            )}
          </>
        );
      }}
    </Render>
  </UniversalBoundary>
);

export default CustomerContactInfos;

import { CountryCode } from "@mittwald/flow-components/dist/components/PhoneNumber/resources/countries";
import Select from "@mittwald/flow-components/dist/components/Select";
import { useCountryOptions } from "@mittwald/flow-components/dist/hooks/useCountryOptions";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";

export interface CountrySelectProps {
  name: string;
  label?: I18nDefinition;
  disabled?: boolean;
  allowedCountryCodes?: CountryCode[];
}

export const CountrySelect: FC<CountrySelectProps> = (props) => {
  const allCountrySelectOptions = useCountryOptions(true);

  const countrySelectOptions = props.allowedCountryCodes
    ? allCountrySelectOptions.filter((e) =>
        props.allowedCountryCodes?.includes(e.value),
      )
    : allCountrySelectOptions;

  return (
    <Select
      disabled={props.disabled}
      isSearchable
      label={props.label ?? "country"}
      name={props.name}
      options={countrySelectOptions}
      placeholder="country"
      rules={{ required: true }}
    />
  );
};

export default CountrySelect;

import invariant from "invariant";

export const portRegExp = /^([0-9]+)(:[0-9]+)?(\/(tcp|udp))?$/;
const maxPort = 65535;
type ApiData = string;

export interface ContainerPortFormData {
  port: string;
}

export class ContainerPort {
  public readonly data: ApiData;
  public readonly internal: number;
  public readonly external: number;
  public readonly protocol?: string;

  private constructor(data: ApiData) {
    this.data = Object.freeze(data);
    const [portString, protocol] = data.split("/");
    invariant(portString, "Invalid container port definition!");
    const [internal, external] = portString.split(":");
    invariant(internal, "Invalid container port definition!");
    this.internal = parseInt(internal);
    this.external = external ? parseInt(external) : parseInt(internal);
    this.protocol = protocol;
  }

  public static fromApiData(data: ApiData): ContainerPort {
    return new ContainerPort(data);
  }

  public static fromFormData(data: ContainerPortFormData): ContainerPort {
    return ContainerPort.fromApiData(data.port);
  }

  public toString(externalOnly?: boolean): string {
    return externalOnly || this.internal === this.external
      ? `${this.external}${this.protocol ? `/${this.protocol}` : ""}`
      : `${this.internal}:${this.external}${this.protocol ? `/${this.protocol}` : ""}`;
  }

  public asFormData(): ContainerPortFormData {
    return { port: this.toString() };
  }

  public static validatePortNumbers(port: string): boolean {
    const portObj = ContainerPort.fromApiData(port);
    return (
      portObj.internal > 0 &&
      portObj.internal <= maxPort &&
      portObj.external > 0 &&
      portObj.external <= maxPort
    );
  }
}

import { MittwaldApi } from "../../api/Mittwald";

export enum ArticleAttributeKey {
  machineType = "machine_type",
  vCpu = "vcpu",
  ram = "ram",
  description = "description",
  domain = "domain",
  specMachineType = "spec.machine_type",
}

export type ArticleAttributeApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Article_ArticleAttributes;

export abstract class ArticleAttribute {
  public readonly key: string;
  public readonly value: string;
  public readonly unit?: string;
  public readonly valueWithUnit: string;

  protected constructor(apiData: ArticleAttributeApiData) {
    this.key = apiData.key;
    this.value = apiData.value ?? "";
    this.unit = apiData.unit;
    this.valueWithUnit =
      (apiData.unit === undefined
        ? apiData.value
        : `${apiData.value}${apiData.unit}`) ?? "";
  }
}

export default ArticleAttribute;

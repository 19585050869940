import { InvoiceApiData } from "../../model/accounting/Invoice";
import { CustomerApiData } from "../../model/customer/Customer";
import { CustomerContactApiData } from "../../model/customer/CustomerContact";
import { CustomerInviteApiData } from "../../model/customer/CustomerInvite";
import { CustomerMembershipApiData } from "../../model/customer/CustomerMembership";
import { InvoiceSettingsApiData } from "../../model/customer/InvoiceSettings";
import {
  demoAddress,
  demoDate,
  demoEmail,
  demoFirstName,
  demoLastName,
  demoName,
  demoShortId,
} from "../staticDemoData";

const demoContact: CustomerContactApiData = {
  address: demoAddress,
  emailAddress: demoEmail,
  lastName: demoLastName,
  firstName: demoFirstName,
  salutation: "mr",
  phoneNumbers: ["+49123456789"],
};

export const demoCustomer: CustomerApiData = {
  customerId: "1",
  name: "Demo Organisation",
  owner: demoContact,
  projectCount: 2,
  memberCount: 3,
  executingUserRoles: ["owner"],
  creationDate: demoDate,
  customerNumber: demoShortId,
};

export const demoInvoice: InvoiceApiData = {
  amountPaid: 1000,
  customerId: "1",
  date: demoDate,
  groups: [
    {
      contractId: "1",
      description: "Demo Invoice",
      items: [
        {
          itemId: "1",
          servicePeriod: {
            start: demoDate,
            end: demoDate,
          },
          price: {
            value: 1000,
            currency: "EUR",
          },
          contractItemId: "1",
          vatRate: 19,
          description: "Demo Invoice",
          serviceDate: demoDate,
          itemCancelledOrCorrectedBy: [],
        },
      ],
    },
  ],
  id: "1",
  invoiceNumber: "rg10000",
  invoiceType: "REGULAR",
  paymentSettings: {
    method: "invoice",
  },
  pdfId: "1",
  recipient: demoContact,
  status: "PAID",
  totalGross: 1100,
  totalNet: 1000,
  currency: "EUR",
};

export const demoInvoiceSettings: InvoiceSettingsApiData = {
  id: "1",
  invoicePeriod: 12,
  paymentSettings: {
    method: "invoice",
  },
  printedInvoices: false,
  recipient: demoContact,
  recipientSameAsOwner: false,
  targetDay: 24,
};

export const demoCustomerMembership: CustomerMembershipApiData = {
  customerId: "1",
  id: "1",
  role: "owner",
  userId: "1",
  memberSince: demoDate,
  inviteId: "1",
  mfa: true,
  email: "testmail@gmail.com",
};

export const demoCustomerInvite: CustomerInviteApiData = {
  id: "1",
  customerId: "1",
  role: "owner",
  customerName: "Demo Organisation",
  information: {
    invitedBy: demoName,
    userId: "1",
    invitationToken: "1",
  },
  avatarRefId: "",
  mailAddress: demoEmail,
};

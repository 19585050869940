import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import { demoServer } from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockProjectUpdateServerDescription(
    { path: { serverId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockProjectListServers(
    {},
    { status: 200, content: [demoServer] },
  );

  mittwaldApiRequestMocking.mockProjectGetServer(
    { path: { serverId: "*" } },
    { status: 200, content: demoServer },
  );

  mittwaldApiRequestMocking.mockProjectDeleteServerAvatar(
    { path: { serverId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockProjectRequestServerAvatarUpload(
    { path: { serverId: "*" } },
    { status: 200, content: { refId: "1" } },
  );
};

import * as Sentry from "@sentry/browser";
import { applySnapshot, types } from "mobx-state-tree";
import demoDataManager from "../demo/demoDataManager";
import { demoSessionToken } from "../demo/staticDemoData";
import history from "../history";
import { AuthenticationResult } from "../model/signup/Signup";
import initBrowserSessionPersistence from "./initBrowserSessionPersistence";

const addDemoSessionSentryBreadcrumb = (message: string): void => {
  Sentry.addBreadcrumb({
    category: "session.demoSession",
    type: "user",
    message,
  });
};

const browserSessionStore = types
  .model({
    impersonationAccessToken: types.maybe(types.string),
  })
  .views((self) => ({
    get browserImpersonated(): boolean {
      return !!self.impersonationAccessToken;
    },
  }))
  .actions((self) => ({
    setImpersonationAccessToken: (token: string): void => {
      self.impersonationAccessToken = token;
    },
    logout: (): boolean => {
      if (!self.impersonationAccessToken) {
        return false;
      }

      self.impersonationAccessToken = undefined;
      window.location.reload();
      history.push("/");
      return true;
    },
  }))
  .create({
    impersonationAccessToken: undefined,
  });

initBrowserSessionPersistence("session", browserSessionStore);

export const model = types
  .model({
    _accessToken: types.maybe(types.string),
    refreshToken: types.maybe(types.string),
    expires: types.maybe(types.Date),
    inactiveAccessTokens: types.array(types.string),
    impersonationUrls: types.map(types.string),
    lastLogIn: types.maybe(types.Date),
    mfaEnabled: types.maybe(types.boolean),
    employeeToken: types.maybe(types.string),
  })
  .views((self) => ({
    get inDemoMode(): boolean {
      return self._accessToken === demoSessionToken;
    },
    get accessToken(): string | undefined {
      const tokenFromBrowserSession =
        browserSessionStore.impersonationAccessToken;
      const selfToken = self._accessToken;
      return tokenFromBrowserSession ?? selfToken;
    },
    get browserImpersonated(): boolean {
      return browserSessionStore.browserImpersonated;
    },
  }))
  .actions((self) => {
    function setAuthentication(auth: AuthenticationResult): void {
      self._accessToken = auth.token;
      self.refreshToken = auth.refreshToken;
      self.expires = new Date(auth.expires);
    }

    function login(auth: AuthenticationResult): void {
      logout();
      setAuthentication(auth);
    }

    function logout(): void {
      if (browserSessionStore.logout()) {
        return;
      }

      if (self._accessToken !== undefined) {
        self.inactiveAccessTokens.clear();
        self.impersonationUrls.clear();
        self.mfaEnabled = undefined;
        self._accessToken = undefined;
        self.refreshToken = undefined;
        self.expires = undefined;
        history.push("/");
      }
    }

    function setImpersonationAccessToken(token: string): void {
      self._accessToken = token;
      self.lastLogIn = new Date();
    }

    function setEmployeeAccessToken(token: string): void {
      self.employeeToken = token;
    }

    function unsetEmployeeAccessToken(): void {
      self.employeeToken = undefined;
    }

    function impersonate(
      token: string,
      opts: { onlyBrowserSession?: boolean } = {},
    ): void {
      const { onlyBrowserSession = false } = opts;

      if (onlyBrowserSession) {
        browserSessionStore.setImpersonationAccessToken(token);
        return;
      }

      if (token === self._accessToken) {
        return;
      }
      if (self._accessToken) {
        self.inactiveAccessTokens.push(self._accessToken);
        self.impersonationUrls.set(
          self._accessToken,
          history.location.pathname,
        );
      }
      history.push("/");
      setImpersonationAccessToken(token);
    }

    function revokeLastImpersonation(): void {
      const lastAccessToken = self.inactiveAccessTokens.pop();

      if (lastAccessToken) {
        const url = self.impersonationUrls.get(lastAccessToken);
        self.impersonationUrls.delete(lastAccessToken);
        if (url) {
          history.push(url);
        }
        setImpersonationAccessToken(lastAccessToken);
      } else {
        logout();
      }
    }

    function setMfaEnabled(mfa: boolean): void {
      self.mfaEnabled = mfa;
    }

    function startDemoSession(): void {
      addDemoSessionSentryBreadcrumb("Starting demo session");
      impersonate(demoSessionToken);
    }

    function stopDemoSession(): void {
      addDemoSessionSentryBreadcrumb("Stopping demo session");
      revokeLastImpersonation();
      demoDataManager.reset();
    }

    return {
      login,
      startDemoSession,
      stopDemoSession,
      setMfaEnabled,
      impersonate,
      revokeLastImpersonation,
      logout,
      setEmployeeAccessToken,
      unsetEmployeeAccessToken,
      setAuthentication,
    };
  })
  .views((self) => ({
    get impersonated(): boolean {
      return (
        (browserSessionStore.browserImpersonated ||
          self.inactiveAccessTokens.length > 0) &&
        !self.inDemoMode
      );
    },
  }));

export const sessionStore = model.create({
  _accessToken: undefined,
  lastLogIn: undefined,
  inactiveAccessTokens: [],
  impersonationUrls: {},
  mfaEnabled: undefined,
});

// Update MobX store when local store changes, to have synced session data between windows/tabs
window.addEventListener("storage", (event) => {
  if (event.key === "mStudio.session") {
    const storageItem = window.localStorage.getItem("mStudio.session");
    if (storageItem) {
      applySnapshot(sessionStore, JSON.parse(storageItem));
    }
  }
});

export default sessionStore;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { getTld } from "@mittwald/flow-lib/dist/validation/domain";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import DomainName from "./DomainName";
import { Tld, TldApiData } from "./Tld";

export class TldList extends ListModel<Tld> {
  private static supportedTldsForOrder: TldApiData[] | undefined = undefined;

  public static async getTldByDomain(domain: string): Promise<Tld | undefined> {
    try {
      const domainTld = DomainName.parse(domain).tld;
      const tldsToOrder = await TldList.getSupportedTldsForOrder();
      const tld = tldsToOrder.find(
        (item) => item.tld.toLowerCase() === domainTld.toLowerCase(),
      );

      if (!tld) {
        return undefined;
      }

      return Tld.fromApiData(tld);
    } catch (e) {
      return undefined;
    }
  }

  public static async getSupportedTldsForOrder(): Promise<TldApiData[]> {
    if (!TldList.supportedTldsForOrder) {
      const response = await mittwaldApi.domainListTlds.request({});
      assertStatus(response, 200);

      TldList.supportedTldsForOrder = response.content;
      return response.content;
    }

    return TldList.supportedTldsForOrder;
  }

  public static useGetSupportedTldsForOrder(): TldList {
    const tlds = mittwaldApi.domainListTlds.getResource({}).useWatchData();
    const tldsMemo = useMemo(() => tlds.map((i) => Tld.fromApiData(i)), [tlds]);

    return new TldList(tldsMemo);
  }

  public useTldFromName(name: string): Tld | undefined {
    const cleanTdl = getTld(name);
    if (!cleanTdl) {
      return undefined;
    }

    return this.useTld(cleanTdl);
  }

  public useTld(name?: string): Tld | undefined {
    if (!name) {
      return undefined;
    }

    return useMemo(() => this.items.find((tld) => tld.name === name), [name]);
  }
}

import { iconCustomer } from "@mittwald/flow-icons/dist/customer";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import CustomerInvite from "./CustomerInvite";

export class CustomerInviteModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "membership",
    "customerinvite",
    iconCustomer,
  );

  public constructor(inviteId: string) {
    super(inviteId, CustomerInviteModelRelation.type);
  }

  public useOptionalInvite(): CustomerInvite | undefined {
    return CustomerInvite.useTryLoadById(this.id);
  }
}

export default CustomerInviteModelRelation;

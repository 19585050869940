import { BackupApiData } from "../../model/backup/Backup";
import { BackupScheduleApiData } from "../../model/backup/BackupSchedule";
import { demoDate } from "../staticDemoData";

export const demoBackup: BackupApiData = {
  createdAt: demoDate,
  deletable: false,
  id: "1",
  parentId: "1",
  projectId: "1",
  status: "Completed",
};

export const demoBackupSchedule: BackupScheduleApiData = {
  createdAt: demoDate,
  id: "1",
  isSystemBackup: true,
  projectId: "1",
  schedule: "18 3 * * *",
  ttl: "30d",
};

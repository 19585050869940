import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import Customer from "../customer/Customer";
import MoneyValue from "../misc/MoneyValue";
import ContractItem from "./ContractItem";
import ContractTermination from "./ContractTermination";

export type TerminationType = "nextDate" | "customDate";

export interface ContractTerminateInput {
  reason?: string;
  terminationTargetDate?: string;
  terminationType: TerminationType;
}

export type ContractPriceApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_Price;

export type ContractApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_Contract;

export class Contract {
  public readonly id: string;
  public readonly data: ContractApiData;
  public readonly baseItem: ContractItem;
  public readonly additionalItems: ContractItem[];
  public readonly allItems: ContractItem[];
  public readonly totalPrice: MoneyValue;
  public readonly termination?: ContractTermination;
  public readonly hasTermination: boolean;
  public readonly isTariffChangePossible: boolean;

  private constructor(data: ContractApiData) {
    this.id = data.contractId;
    this.data = Object.freeze(data);

    this.baseItem = ContractItem.fromApiData(this, 0, data.baseItem);
    this.additionalItems =
      data.additionalItems?.map((item, index) =>
        ContractItem.fromApiData(this, index, item),
      ) ?? [];

    this.totalPrice = this.additionalItems.reduce(
      (total, current) => total.add(current.totalPrice),
      this.baseItem.totalPrice,
    );

    this.termination = data.termination
      ? ContractTermination.fromApiData(this, data.termination)
      : undefined;

    this.hasTermination = !!data.termination;
    this.allItems = [this.baseItem, ...this.additionalItems];

    this.isTariffChangePossible =
      !this.hasTermination && this.baseItem.isTariffChangePossible;
  }

  public static fromApiData(data: ContractApiData): Contract {
    return new Contract(data);
  }

  public static useLoad(id: string): Contract {
    const data = mittwaldApi.contractGetDetailOfContract
      .getResource({
        path: {
          contractId: id,
        },
      })
      .useWatchData();

    return new Contract(data);
  }

  public static async load(id: string): Promise<Contract> {
    const response = await mittwaldApi.contractGetDetailOfContract.request({
      path: {
        contractId: id,
      },
    });

    assertStatus(response, 200);
    return new Contract(response.content);
  }

  public useCustomer(): Customer {
    return Customer.useLoadById(this.data.customerId);
  }

  public async terminate(input: ContractTerminateInput): Promise<void> {
    await Contract.terminate(this.id, input);
  }

  public static async terminate(
    contractId: string,
    input: ContractTerminateInput,
  ): Promise<void> {
    const { terminationTargetDate, reason, terminationType } = input;

    const response = await mittwaldApi.contractTerminateContract.request({
      path: {
        contractId,
      },
      requestBody: {
        reason,
        terminationTargetDate:
          terminationType === "customDate" && terminationTargetDate
            ? terminationTargetDate
            : undefined,
      },
    });

    assertStatus(response, 201);
  }
}

export default Contract;

export const demoSessionToken = "demo-session";
export const demoUserId = "demo-user-id";

export const demoDate = "2022-12-24T17:00:00.000Z";

export const demoFirstName = "Hobi";
export const demoLastName = "Tüske";
export const demoName = `${demoFirstName} ${demoLastName}`;

export const demoShortId = "123456";

export const demoPublicKey = {
  key: "ssh-rsa 123456789",
  comment: "Demo Public Key",
};

export const demoEmail = "demo@demo.de";
export const demoUrl = "demo.de";

export const demoAddress = {
  street: "Königsberger Straße",
  houseNumber: "4",
  zip: "32339",
  city: "Espelkamp",
  countryCode: "DE",
};

export const demoReference = {
  id: "1",
  domain: "project",
  aggregate: "project",
};

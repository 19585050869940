import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Ingress from "./Ingress";

export class IngressModelRelation extends ModelRelation {
  public static type = new ModelRelationType("ingress", "ingress", iconDomain);

  public constructor(ingressId: string) {
    super(ingressId, IngressModelRelation.type);
  }
  public useIngress(): Ingress {
    return Ingress.useLoadById(this.id);
  }
  public useOptionalIngress(): Ingress | undefined {
    return Ingress.useTryLoadById(this.id);
  }
}

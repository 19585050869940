import { CronjobApiData } from "../../model/cronjob/Cronjob";
import { CronjobExecutionApiData } from "../../model/cronjob/CronjobExecution";
import { demoDate, demoShortId } from "../staticDemoData";

export const demoCronjob: CronjobApiData = {
  id: "1",
  appId: "1",
  description: "Demo Cronjob",
  destination: {
    url: "https://google.com",
  },
  interval: "0 2 * * *",
  active: true,
  createdAt: demoDate,
  updatedAt: demoDate,
  latestExecution: {
    id: "1",
    status: "Complete",
    start: demoDate,
    end: demoDate,
    durationInMilliseconds: 1000,
    logPath: "xyz.log",
    successful: true,
  },
  nextExecutionTime: demoDate,
  shortId: demoShortId,
  projectId: "1",
  timeout: 3600,
};

export const demoCronjobExecution: CronjobExecutionApiData = {
  durationInMilliseconds: 1000,
  end: demoDate,
  executionEnd: demoDate,
  executionStart: demoDate,
  id: "1",
  start: demoDate,
  status: "Complete",
  successful: true,
  triggeredBy: {
    id: "1",
  },
};

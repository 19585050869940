import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import EmailAddress from "./EmailAddress";

export class EmailAddressList extends ListModel<EmailAddress> {
  public static useLoadAllByProjectId(projectId: string): EmailAddressList {
    const emailAddresses = mittwaldApi.mailListMailAddresses
      .getResource({
        path: {
          projectId,
        },
      })
      .useWatchData()
      .map((d) => EmailAddress.fromApiData(d));

    return new EmailAddressList(emailAddresses);
  }

  public static useLoadAllByPathParam(): EmailAddressList {
    const { projectId } = usePathParams("projectId");
    return EmailAddressList.useLoadAllByProjectId(projectId);
  }

  public findCatchAllAddressForDomain = (
    domain: string,
    currentAddress?: string,
  ): EmailAddress | null => {
    const catchAllList = this.items
      .filter(
        (emailAddress) =>
          EmailAddress.getEmailAddressDomainPart(emailAddress.address) ==
            domain && emailAddress.isCatchAll,
      )
      .filter((catchAll) => catchAll.address !== currentAddress);

    if (catchAllList[0]) {
      return catchAllList[0];
    }

    return null;
  };

  public addressExists = (address: string): boolean =>
    this.items.some((e) => e.address === address);
}

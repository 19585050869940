import { useAccessToken } from "./useAccessToken";

export const useRequiredAccessToken = (): string => {
  const token = useAccessToken();

  if (!token) {
    throw new Error("Access token is required");
  }
  return token;
};

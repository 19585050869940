import { MittwaldApi } from "../../../api/Mittwald";

export type UserInputRecordApiSchema =
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_HandleField
  | MittwaldApi.Components.Schemas.De_Mittwald_V1_App_SavedUserInput;

export type ParsedValue = boolean | string;

export interface UserInputRecordObject {
  name: string;
  value: string;
}

export class UserInputRecord {
  public readonly name: string;
  public readonly value: string;
  public readonly parsedValue: ParsedValue;

  private constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
    this.parsedValue = UserInputRecord.parseValue(this.value);
  }

  public toObject(): UserInputRecordObject {
    return {
      name: this.name,
      value: this.value,
    };
  }

  public static fromApiData(data: UserInputRecordApiSchema): UserInputRecord {
    return new UserInputRecord(data.name, data.value);
  }

  public static fromObjectEntry(entry: [string, ParsedValue]): UserInputRecord {
    const [name, parsedValue] = entry;
    return new UserInputRecord(
      name,
      UserInputRecord.stringifyValue(parsedValue),
    );
  }

  public static parseValue(value: string): ParsedValue {
    if (value === "true") {
      return true;
    }
    if (value === "false") {
      return false;
    }
    return value;
  }

  public static stringifyValue(value: ParsedValue): string {
    if (value === true) {
      return "true";
    }
    if (value === false) {
      return "false";
    }
    return value;
  }
}

export default UserInputRecord;

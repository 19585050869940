import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ApiDataError from "../../errors/ApiDataError";
import AppVersion from "../app/AppVersion";
import ListModel from "../misc/ListModel";
import App from "./App";

type ApiSchema = MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppVersion;

export class AppVersionList extends ListModel<AppVersion> {
  public static useLoadByApp(
    app: App,
    onlyRecommended?: boolean,
  ): AppVersionList {
    const data = mittwaldApi.appListAppversions
      .getResource({
        path: { appId: app.id },
        query: { recommended: onlyRecommended ?? false },
      })
      .useWatchData();

    return this.fromApiData(app, data);
  }

  public static fromApiData(app: App, apiData: ApiSchema[]): AppVersionList {
    const items = apiData
      .map((v) => AppVersion.fromApiData(app, v))
      .sort((a, b) => b.compare(a));
    return new AppVersionList(items);
  }

  public static empty(): AppVersionList {
    return new AppVersionList([]);
  }

  public latest(): AppVersion {
    const latestVersion = this.items[0];

    if (!latestVersion) {
      throw new ApiDataError("App has no versions");
    }

    return latestVersion;
  }

  public findById(id: string): AppVersion | undefined {
    return this.items.find((v) => v.data.id === id);
  }

  public static useLoadLaterVersions(
    currentVersionId: string,
    app: App,
  ): AppVersionList {
    const data = mittwaldApi.appListUpdateCandidatesForAppversion
      .getResource({
        path: { appId: app.id, baseAppVersionId: currentVersionId },
      })
      .useWatchData();

    return this.fromApiData(app, data);
  }
}

export default AppVersionList;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import { ArticleTemplateName, ArticleAttributeKey } from "../article";
import ArticleFactory from "../article/ArticleFactory";
import SpaceServerArticle, {
  SpaceServerArticleOrderInput,
} from "../article/SpaceServerArticle";
import Contract, { ContractTerminateInput } from "../contract/Contract";
import Customer from "../customer/Customer";
import Bytes from "../misc/Bytes";
import ListModel from "../misc/ListModel";
import MoneyValue from "../misc/MoneyValue";
import Order, { PendingOrder } from "../order/Order";
import {
  CreateProjectInput,
  ProjectList,
  SpaceServerProject,
  UpdateProjectInputs,
} from "../project";
import MachineType from "./MachineType";
import ServerList from "./ServerList";
import { ServerMetrics } from "./ServerMetrics";
import Storage from "./Storage";

export interface ServerTerminateInput extends ContractTerminateInput {
  serverId: string;
  confirmTermination: boolean;
}

export interface ServerModifyInput {
  serverId: string;
  diskspaceInGiB: number;
  machineType: string;
}

export type ServerApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Project_Server;

export interface UpdateServerInputs {
  description: string;
  notificationActive: boolean;
  notificationThreshold?: number;
}

export class Server {
  public readonly id: string;
  public readonly data: ServerApiData;
  public readonly storage: Storage;

  private constructor(data: ServerApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.storage = Storage.fromServer(this);
  }

  public static fromApiData(data: ServerApiData): Server {
    return new Server(data);
  }

  public static useLoadById(id: string): Server {
    return new Server(
      mittwaldApi.projectGetServer
        .getResource({
          path: {
            serverId: id,
          },
        })
        .useWatchData(),
    );
  }

  public static async loadById(id: string): Promise<Server> {
    const response = await mittwaldApi.projectGetServer.request({
      path: {
        serverId: id,
      },
    });

    assertStatus(response, 200);

    return Server.fromApiData(response.content);
  }
  public static useLoadByPathParam(): Server {
    const { serverId } = usePathParams("serverId");
    return Server.useLoadById(serverId);
  }

  public useMachineType(): MachineType {
    return this.useArticle().machineType;
  }

  public static useLoadAll(): ServerList {
    return ServerList.useLoad();
  }

  public static useLoadByCustomerId(customerId: string): Server[] {
    return mittwaldApi.projectListServers
      .getResource({
        query: {
          customerId,
        },
      })
      .useWatchData()
      .map((s) => Server.fromApiData(s));
  }

  public static useTryLoadByCustomerId(
    customerId?: string,
  ): Server[] | undefined {
    return mittwaldApi.projectListServers
      .getResource(
        customerId === undefined
          ? null
          : {
              query: {
                customerId,
              },
            },
      )
      .useWatchData()
      ?.map((s) => Server.fromApiData(s));
  }

  public static async getLoadByCustomerId(
    customerId: string,
  ): Promise<Server[]> {
    const response = await mittwaldApi.projectListServers.request({
      query: {
        customerId,
      },
    });

    assertStatus(response, 200);

    return response.content.map((s) => Server.fromApiData(s));
  }

  public static useTryLoadById(id?: string): Server | undefined {
    const data = mittwaldApi.projectGetServer
      .getResource(id ? { path: { serverId: id } } : null)
      .useWatchData({
        optional: true,
      });

    return data ? new Server(data) : undefined;
  }

  public useContract(): Contract {
    const contractId = mittwaldApi.contractGetDetailOfContractByServer
      .getResource({
        path: {
          serverId: this.id,
        },
      })
      .useWatchData().contractId;

    return Contract.useLoad(contractId);
  }

  public async loadContract(): Promise<Contract> {
    const contractResponse =
      await mittwaldApi.contractGetDetailOfContractByServer.request({
        path: {
          serverId: this.id,
        },
      });

    assertStatus(contractResponse, 200);

    return Contract.load(contractResponse.content.contractId);
  }

  public useArticle(): SpaceServerArticle {
    return SpaceServerArticle.coerce(
      this.useContract().baseItem.baseArticle!.useArticle(),
    );
  }

  public useNextPossibleTariffChangeDate(
    price: MoneyValue,
  ): DateTime | undefined {
    const baseItem = this.useContract().baseItem;
    const baseItemPrice = baseItem.totalPrice;
    return price < baseItemPrice
      ? baseItem.nextPossibleDowngradeDate
      : baseItem.nextPossibleUpgradeDate;
  }

  public async loadArticle(): Promise<SpaceServerArticle> {
    return SpaceServerArticle.coerce(
      await (await this.loadContract()).baseItem.baseArticle!.loadArticle(),
    );
  }

  public useCustomer(): Customer {
    return Customer.useLoadById(this.data.customerId);
  }

  public useProjects(): ProjectList {
    return ProjectList.useLoadByServerId(this.id);
  }

  public static useProjects(serverId: string): ProjectList {
    return ProjectList.useLoadByServerId(serverId);
  }

  public static usePendingServerInstallations(): ListModel<PendingOrder> {
    const allOrders = Order.useLoadAll({
      includesStatus: ["CONFIRMED", "NEW"],
    });

    return Server.usePendingServerInstallationsByOrders(allOrders);
  }

  public static usePendingServerInstallationsForCustomer(
    customerId: string,
  ): ListModel<PendingOrder> {
    const allOrders = Order.useLoadAllForCustomer(customerId, {
      includesStatus: ["CONFIRMED", "NEW"],
    });

    return Server.usePendingServerInstallationsByOrders(allOrders);
  }

  private static usePendingServerInstallationsByOrders(
    orders: Order[],
  ): ListModel<PendingOrder> {
    const existingServers = Server.useLoadAll().useItems();
    const existingServerDescriptions = existingServers.map(
      (s) => s.data.description,
    );

    const spaceServerArticles = ArticleFactory.useLoadAllByTemplate(
      ArticleTemplateName.spaceServerHosting,
    );

    return new ListModel(
      orders
        .filter((o) => o.data.type === "NEW_ORDER")
        .filter((o) =>
          spaceServerArticles.some((a) => o.items[0]?.referencesArticle(a)),
        )
        .map((o) => ({
          data: {
            description: o.items[0]?.getAttributeValue(
              ArticleAttributeKey.description,
            ),
            orderStatus: o.data.status,
          },
        }))
        .filter(
          (i) =>
            i.data.description === undefined ||
            !existingServerDescriptions.includes(i.data.description),
        ),
    );
  }

  public async terminate(
    input: Omit<ServerTerminateInput, "serverId">,
  ): Promise<void> {
    return Server.terminate({
      ...input,
      serverId: this.id,
    });
  }

  public static async loadContract(serverId: string): Promise<Contract> {
    const contract =
      await mittwaldApi.contractGetDetailOfContractByServer.request({
        path: {
          serverId,
        },
      });

    assertStatus(contract, 200);

    return Contract.fromApiData(contract.content);
  }

  public static async terminate(input: ServerTerminateInput): Promise<void> {
    const { serverId } = input;
    const contract = await this.loadContract(serverId);
    await contract.terminate(input);
  }

  public static async adjust(input: ServerModifyInput): Promise<void> {
    const { serverId, machineType, diskspaceInGiB } = input;

    const contract = await this.loadContract(serverId);

    const response = await mittwaldApi.orderCreateTariffChange.request({
      requestBody: {
        tariffChangeType: "server",
        tariffChangeData: {
          contractId: contract.id,
          machineType,
          diskspaceInGiB,
        },
      },
    });

    assertStatus(response, 201);
  }

  public async adjust(
    input: Omit<ServerModifyInput, "serverId">,
  ): Promise<void> {
    return Server.adjust({
      ...input,
      serverId: this.id,
    });
  }

  public async updateDescription(description: string): Promise<void> {
    const response = await mittwaldApi.projectUpdateServerDescription.request({
      path: { serverId: this.id },
      requestBody: { description },
    });

    assertStatus(response, 204);
  }

  public async updateStorageNotificationSettings(
    values: Pick<
      UpdateProjectInputs,
      "notificationThreshold" | "notificationActive"
    >,
  ): Promise<void> {
    // setting the threshold to undefined is the equivalent to turning notifications off
    const bytes =
      values.notificationThreshold && values.notificationActive
        ? Math.ceil(Bytes.of(values.notificationThreshold, "GiB").valueOf())
        : undefined;
    const response =
      await mittwaldApi.storagespaceReplaceServerNotificationThreshold.request({
        path: { serverId: this.id },
        requestBody: { notificationThresholdInBytes: bytes },
      });

    assertStatus(response, 204);
  }

  public async requestAvatarUpload(): Promise<string> {
    const response = await mittwaldApi.projectRequestServerAvatarUpload.request(
      {
        path: {
          serverId: this.id,
        },
      },
    );

    assertStatus(response, 200);

    return response.content.refId;
  }

  public async removeAvatar(): Promise<void> {
    const response = await mittwaldApi.projectDeleteServerAvatar.request({
      path: { serverId: this.id },
    });

    assertStatus(response, 204);
  }

  public getMetrics(): ServerMetrics {
    return ServerMetrics.of(this);
  }

  public async createProject(input: CreateProjectInput): Promise<void> {
    await SpaceServerProject.createNew(input);
  }

  public static async createNew(
    input: SpaceServerArticleOrderInput,
  ): Promise<void> {
    await SpaceServerArticle.order(input);
  }
}

export default Server;

import { iconCustomer } from "@mittwald/flow-icons/dist/customer";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import Customer from "./Customer";

export class CustomerModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "customer",
    "customer",
    iconCustomer,
  );

  public constructor(customerId: string) {
    super(customerId, CustomerModelRelation.type);
  }

  public useCustomer(): Customer {
    return Customer.useLoadById(this.id);
  }

  public useOptionalCustomer(): Customer | undefined {
    return Customer.useTryLoadById(this.id);
  }
}

export default CustomerModelRelation;

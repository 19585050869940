import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Customer from "../../../../model/customer/Customer";
import { InvoiceSettings } from "../../../../model/customer/InvoiceSettings";
import CustomerContactInfos from "./CustomerContactInfos";
import { IbanText } from "./IbanText";

export const PaymentData: FC<{ customerId: string; showDetails?: boolean }> = (
  props,
) => {
  const { customerId, showDetails = true } = props;

  const customer = Customer.useLoadById(customerId);
  const invoiceSettings = InvoiceSettings.useTryLoadByCustomer(customer);
  const paymentSettings = invoiceSettings?.data.paymentSettings;

  return (
    <>
      {paymentSettings && (
        <Box _flexDirection="column">
          <Text i18n={paymentSettings.method} strong />
          {paymentSettings.method === "debit" ? (
            <>
              <Text i18n={{ text: paymentSettings.accountHolder }} />
              {showDetails && (
                <>
                  <IbanText iban={paymentSettings.iban} />
                </>
              )}
            </>
          ) : showDetails ? (
            <CustomerContactInfos
              contact={{
                useValue: () => invoiceSettings.contact,
              }}
            />
          ) : (
            <Text
              i18n={{
                text:
                  customer.contact?.data.firstName &&
                  customer.contact.data.lastName
                    ? `${customer.contact.data.firstName} ${customer.contact.data.lastName}`
                    : customer.contact?.data.company,
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default PaymentData;

import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { allSalutations } from "../../misc/Salutation";

export class SalutationUI {
  public static salutationSelectOptions(): SelectOptions {
    return allSalutations.map((salutation) => ({
      label: salutation,
      value: salutation,
    }));
  }
}

export default SalutationUI;

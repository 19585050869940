export const ANALYTICS_EVENT = "AnalyticsEvent";

export type AnalyticsEventPayload<T> = T & {
  event: string;
  identifier?: string;
};

export interface OfType<Type> {
  triggerAnalytics<Key extends keyof Type>(name: Key, data: Type[Key]): void;
}
export interface OfTypeForStatic<Type> {
  addEventListener<Key extends keyof Type>(
    key: Key,
    fn: (data: AnalyticsEventPayload<Type[Key]>) => void,
  ): void;
}

export interface TrackingActionData
  extends Record<string, Record<string, any>> {}

export interface WindowWithDataLayer extends Window {
  dataLayer: {
    push: CallableFunction;
  };
}

export interface VirtualPageViewTrackingProps {
  name: string;
  identifier: string;
}

export interface CheckoutItem {
  sku: string;
  name: string;
  category: string;
  price: number | bigint;
  quantity: number;
}
export interface WidgetTrackingTypes {
  checkout: {
    identifier?: string;
    orderId: string;
    isTesting: boolean;
    transactionTotal: number | bigint;
    transactionProducts: CheckoutItem[];
  };
  virtualPageView: {
    name: string;
    identifier?: string;
  };
}

import ModelRelation from "./ModelRelation";
import ModelRelationType from "./ModelRelationType";

export class GenericModelRelation extends ModelRelation {
  public constructor(id: string, ref: ModelRelationType) {
    super(id, ref);
  }
}

export default GenericModelRelation;

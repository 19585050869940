import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { mittwaldApi } from "../../api/Mittwald";
import Bytes from "../misc/Bytes";
import Server from "../server/Server";
import {
  ProjectBase,
  CreateProjectInput,
  ProjectApiData,
  SpaceServerProjectMetrics,
  UpdateProjectInputs,
} from "./";

export class SpaceServerProject extends ProjectBase {
  public readonly serverId: string;

  public constructor(data: ProjectApiData) {
    super(data);
    invariant(data.serverId, "space-server project requires serverId");
    this.serverId = data.serverId;
  }

  public useServer(): Server | undefined {
    const inherited = this.useMyMembership().data.inherited;
    return Server.useTryLoadById(inherited ? this.data.serverId : undefined);
  }

  public getMetrics(): SpaceServerProjectMetrics {
    return SpaceServerProjectMetrics.of(this);
  }

  public static async createNew(
    values: CreateProjectInput,
    serverId?: string,
  ): Promise<string> {
    const response = await mittwaldApi.projectCreateProject.request({
      path: { serverId: serverId ?? values.serverId },
      requestBody: { description: values.description },
    });

    assertStatus(response, 201);

    return response.content.id;
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.projectDeleteProject.request({
      path: { projectId: this.id },
    });

    assertStatus(response, 204);
  }

  public async updateStorageNotificationSettings(
    values: Pick<
      UpdateProjectInputs,
      "notificationThreshold" | "notificationActive"
    >,
  ): Promise<void> {
    // setting the threshold to undefined is the equivalent to turning notifications off
    const bytes =
      values.notificationThreshold && values.notificationActive
        ? Math.ceil(Bytes.of(values.notificationThreshold, "GiB").valueOf())
        : undefined;
    const response =
      await mittwaldApi.storagespaceReplaceProjectNotificationThreshold.request(
        {
          path: { projectId: this.id },
          requestBody: { notificationThresholdInBytes: bytes },
        },
      );

    assertStatus(response, 204);
  }
}

export default SpaceServerProject;

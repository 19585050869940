import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Extension from "./Extension";
import ExtensionInstance from "./ExtensionInstance";

export class ExtensionInstanceModelRelation extends ModelRelation {
  public static type = new ModelRelationType(
    "extension",
    "extensionInstance",
    iconExtension,
  );

  public constructor(extensionInstanceId: string) {
    super(extensionInstanceId, ExtensionInstanceModelRelation.type);
  }

  public useExtensionInstance(): ExtensionInstance {
    return ExtensionInstance.useLoadById(this.id);
  }

  public useOptionalExtensionInstance(): ExtensionInstance | undefined {
    return ExtensionInstance.useTryLoadById(this.id);
  }

  public useExtension(): Extension {
    const extensionInstance = this.useExtensionInstance();
    return extensionInstance.useExtension();
  }

  public useOptionalExtension(): Extension | undefined {
    const extensionInstance = this.useOptionalExtensionInstance();
    return extensionInstance ? extensionInstance.useTryExtension() : undefined;
  }
}

export default ExtensionInstanceModelRelation;

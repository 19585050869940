import { MittwaldApi } from "../../api/Mittwald";

export type CronjobCommandDestinationApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Cronjob_CronjobCommand;

export class CronjobCommandDestination {
  public readonly data: CronjobCommandDestinationApiData;

  private constructor(data: CronjobCommandDestinationApiData) {
    this.data = Object.freeze(data);
  }

  public static fromApiData(
    data: CronjobCommandDestinationApiData,
  ): CronjobCommandDestination {
    return new CronjobCommandDestination(data);
  }
}

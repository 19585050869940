export const demoDomainArticles = [
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0001",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "de",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".de Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".de Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 69,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0002",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "com",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".com Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".com Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0003",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "net",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".net Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".net Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0004",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "org",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".org Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".org Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0005",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "eu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".eu Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".eu Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0006",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "at",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".at Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".at Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0007",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "info",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".info Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".info Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0008",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "ch",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".chDomain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".ch Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 199,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0009",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "biz",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".biz Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".biz Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 99,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
  {
    additionalArticles: [],
    addons: [],
    articleId: "DOM22-0010",
    attributes: [
      {
        key: "domain",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "auth_code",
        customerEditable: true,
        required: false,
        readonly: false,
      },
      {
        key: "project_id",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "toplevel",
        value: "digital",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    balanceAddonKey: "inclusive_domains",
    contractDurationInMonth: 12,
    description: ".digital Domain",
    hasIndependentContractPeriod: true,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [],
    name: ".digital Domain",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [],
    price: {
      basePrice: {
        staticPlainValue: 299,
      },
      setupFeeArticleId: "DOM22-SF-0001",
    },
    tags: [],
    template: {
      id: "fa357d31-57ce-43f9-8a06-c813544b905f",
      name: "domain",
      description: "Domain",
      type: "additional",
      isRecurring: true,
      attributes: [
        {
          key: "domain",
          customerEditable: true,
          required: true,
        },
        {
          key: "auth_code",
          customerEditable: true,
        },
        {
          key: "project_id",
          customerEditable: true,
          required: true,
        },
        {
          key: "toplevel",
        },
      ],
    },
  },
];

export const demoServerArticles = [
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0001",
    attributes: [
      {
        key: "machine_type",
        value: "shared.xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "4",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "10",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den Einstieg als Reseller von Hosting-Angeboten",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.xlarge",
      memory: "4Gi",
      cpu: "2",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0002",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0003",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0004",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0005",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0006",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 3500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0002",
    attributes: [
      {
        key: "machine_type",
        value: "shared.2xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "8",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "25",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den professionellen Hosting-Reseller",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.2xlarge",
      memory: "8Gi",
      cpu: "4",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0001",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0003",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0004",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0005",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0006",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 6500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0003",
    attributes: [
      {
        key: "machine_type",
        value: "shared.3xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "60",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den legendären Hosting-Reseller",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.3xlarge",
      memory: "16Gi",
      cpu: "8",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0001",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0002",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0004",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0005",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0006",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 12500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0004",
    attributes: [
      {
        key: "machine_type",
        value: "shared.4xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "140",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den legendären Hosting-Reseller",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.4xlarge",
      memory: "32Gi",
      cpu: "16",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0002",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0003",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0001",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0005",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0006",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 24500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0005",
    attributes: [
      {
        key: "machine_type",
        value: "shared.5xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "32",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "64",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "300",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den legendären Hosting-Reseller",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.5xlarge",
      memory: "64Gi",
      cpu: "32",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0001",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0002",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0003",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0004",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0006",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 48500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [],
    articleId: "MT22-0006",
    attributes: [
      {
        key: "machine_type",
        value: "shared.6xlarge",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "50",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "SpaceServer",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "64",
        unit: "vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "128",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "recommended_projects",
        value: "650",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Perfekt für den professionellen Hosting-Reseller",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: {
      name: "shared.6xlarge",
      memory: "128Gi",
      cpu: "64",
    },
    modifierArticles: [
      {
        articleId: "MT22-Storage",
        maxArticleCount: 50,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "Space-Server",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "MT22-0001",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0003",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0002",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0004",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "MT22-0005",
        info: {
          articleName: "Space-Server",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 96500,
      },
    },
    tags: [],
    template: {
      id: "8e62513f-8857-4d26-b19d-9c5b1a33ecb5",
      name: "Multi-Tec-Hosting",
      description: 'MultiTec über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
          value: "shared.xlarge",
        },
        {
          key: "storage",
          value: "50",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "SpaceServer",
        },
        {
          key: "vcpu",
          value: "2",
          unit: "vcpu",
          unitInternal: "vcpu",
        },
        {
          key: "ram",
          value: "4",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "recommended_projects",
          value: "10",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "MT22-Storage",
          maxArticleCount: 50,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
];

export const demoProSpaceArticles = [
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-BASIC-0001",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "1",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "1",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        value: "1",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        value: "1",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace lite",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 1000,
      },
    },
    tags: [
      {
        id: "d40846c8-e783-44b6-97a1-1984793129d7",
        name: "ps-basic",
        description: "ProSpace Basic",
        hexColor: "#FF3933",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "redis",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql_dedicated",
        value: "false",
        type: "choice",
        hidden: false,
      },
    ],
    articleId: "PS23-BASIC-0002",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "1",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "2",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        value: "1",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace lite",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 1400,
      },
    },
    tags: [
      {
        id: "d40846c8-e783-44b6-97a1-1984793129d7",
        name: "ps-basic",
        description: "ProSpace Basic",
        hexColor: "#FF3933",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "redis",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql_dedicated",
        value: "false",
        type: "choice",
        hidden: false,
      },
    ],
    articleId: "PS23-BASIC-0003",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "4",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace lite",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 2600,
      },
    },
    tags: [
      {
        id: "d40846c8-e783-44b6-97a1-1984793129d7",
        name: "ps-basic",
        description: "ProSpace Basic",
        hexColor: "#FF3933",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "redis",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql",
        value: "true",
        type: "choice",
        hidden: false,
      },
      {
        key: "mysql_dedicated",
        value: "false",
        type: "choice",
        hidden: false,
      },
    ],
    articleId: "PS23-DED-0001",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "4",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.2cpu.4gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 3800,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0002",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "8",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.2cpu.8gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 6200,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0003",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.2cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 11000,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0004",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "8",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.4cpu.8gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 7400,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0005",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.4cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 12200,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0006",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.4cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 21800,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0007",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.8cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 14600,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0008",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.8cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 24200,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0009",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "64",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.8cpu.64gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 43400,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0010",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.16cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 29000,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0011",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "64",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.16cpu.64gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 48200,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-DED-0012",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "128",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.16cpu.128gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace (dedizierte Resourcen)",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-PLUS-0001",
        info: {
          articleName: "proSpace",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 86600,
      },
    },
    tags: [
      {
        id: "0d4ee4cc-a51d-4582-bca3-bbdd19d4befe",
        name: "ps-dedicated",
        description: "ProSpace Dedicated",
        hexColor: "#E0E712",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0001",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "4",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.2cpu.4gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 3200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0002",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "8",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.2cpu.8gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 5200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0003",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "2",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.2cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 9200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0004",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "8",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.4cpu.8gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 6200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0005",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.4cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 10200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0006",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "4",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.4cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 18200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0007",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "16",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.8cpu.16gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 12200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0008",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.8cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 20200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0009",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "8",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "64",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.8cpu.64gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 36200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0010",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "32",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.16cpu.32gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 24200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f7ce86d-99ab-49f0-9e09-40e291f3974d",
        name: "cpu-optimized",
        description: "CPU optimized VCPU/RAM ratio",
        hexColor: "#DAF7A6",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0011",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "64",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.16cpu.64gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 40200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "0f6c5314-89d2-4ee6-956c-84145a1e5f64",
        name: "balance-optimized",
        description: "balanced optimization between VCPU/RAM ratio",
        hexColor: "#900C3F",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
  {
    additionalArticles: [
      {
        articleId: "DOM22-0001",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0002",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0003",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0004",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0005",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0006",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0007",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0008",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0009",
        info: {
          fromArticleTemplate: true,
        },
      },
      {
        articleId: "DOM22-0010",
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    addons: [
      {
        key: "node",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "redis",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql",
        value: "true",
        hidden: true,
        type: "choice",
      },
      {
        key: "mysql_dedicated",
        value: "false",
        hidden: true,
        type: "choice",
      },
    ],
    articleId: "PS23-PLUS-0012",
    attributes: [
      {
        key: "machine_type",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "storage",
        value: "20",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "description",
        value: "ProSpace",
        customerEditable: true,
        required: true,
        readonly: false,
      },
      {
        key: "vcpu",
        value: "16",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "ram",
        value: "128",
        unit: "GB",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.ram",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.hardware_spec.vcpu",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "spec.machine_type",
        value: "prospace.shared.16cpu.128gb",
        customerEditable: false,
        required: false,
        readonly: false,
      },
      {
        key: "allowed_features",
        value: "[]",
        customerEditable: false,
        required: false,
        readonly: false,
      },
    ],
    contractDurationInMonth: 1,
    description: "Lorem Ipsum",
    hasIndependentContractPeriod: false,
    hideOnInvoice: false,
    machineType: "undefined",
    modifierArticles: [
      {
        articleId: "PS23-Storage",
        maxArticleCount: 124,
        info: {
          fromArticleTemplate: true,
        },
      },
    ],
    name: "proSpace",
    orderable: "full",
    picture: "undefined",
    possibleArticleChanges: [
      {
        articleId: "PS23-BASIC-0001",
        info: {
          articleName: "proSpace lite",
          articleTemplateType: "base",
        },
      },
      {
        articleId: "PS23-DED-0001",
        info: {
          articleName: "proSpace (dedizierte Resourcen)",
          articleTemplateType: "base",
        },
      },
    ],
    price: {
      basePrice: {
        staticPlainValue: 72200,
      },
    },
    tags: [
      {
        id: "39ae4eb6-390c-4687-be29-0c466f59037f",
        name: "ps-plus",
        description: "ProSpace Plus",
        hexColor: "#F4A70C",
      },
      {
        id: "574856e5-94e8-4e2d-9f51-9285191b491d",
        name: "ram-optimized",
        description: "RAM optimized VCPU/RAM ratio",
        hexColor: "#FF5733",
      },
    ],
    template: {
      id: "022a6cbb-aa7c-4201-91e0-009d6207f64d",
      name: "Pro-Space-Hosting",
      description: 'ProSpace über "machineType"-Resource-Definitionen',
      type: "base",
      isRecurring: true,
      attributes: [
        {
          key: "machine_type",
        },
        {
          key: "storage",
          value: "20",
          unit: "GB",
          unitInternal: "Gi",
        },
        {
          key: "description",
          customerEditable: true,
          required: true,
          value: "ProSpace",
        },
        {
          key: "vcpu",
        },
        {
          key: "ram",
          unit: "GB",
          unitInternal: "GB",
        },
        {
          key: "spec.hardware_spec.ram",
        },
        {
          key: "spec.hardware_spec.vcpu",
        },
        {
          key: "spec.machine_type",
        },
        {
          key: "allowed_features",
          value: "[]",
        },
      ],
      addons: [
        {
          key: "node",
          value: "true",
          type: "choice",
        },
        {
          key: "redis",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql",
          value: "true",
          type: "choice",
        },
        {
          key: "mysql_dedicated",
          value: "false",
          type: "choice",
        },
      ],
      additionalArticles: [
        {
          articleId: "DOM22-0001",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0002",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0003",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0004",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0005",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0006",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0007",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0008",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0009",
          info: {
            fromArticleTemplate: true,
          },
        },
        {
          articleId: "DOM22-0010",
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
      modifierArticles: [
        {
          articleId: "PS23-Storage",
          maxArticleCount: 124,
          info: {
            fromArticleTemplate: true,
          },
        },
      ],
    },
  },
];

export const demoStorageArticle = {
  articleId: "PS23-Storage",
  attributes: [
    {
      key: "storage",
      value: "10",
      mergeType: "add",
      unit: "GB",
      customerEditable: false,
      required: false,
      readonly: false,
    },
  ],
  contractDurationInMonth: 1,
  description: "Erweitert den Speicherplatz eines ProSpace-Server",
  name: "ProSpace-Speicherplatz",
  orderable: "full",
  price: 500,
  template: {
    id: "1",
    name: "Speicher für Space-Server aller Art",
    description: "Space-Storage",
    type: "modifier",
    isRecurring: true,
    attributes: [
      {
        key: "storage",
        value: "50",
        unit: "GB",
        unitInternal: "Gi",
      },
    ],
  },
};

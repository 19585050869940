import { Box } from "@mittwald/flow-components/dist/components/Box";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";
import React, { FC } from "react";

export const FeatureSwitch: FC<{ name: string; disabled?: boolean }> = (
  props,
) => {
  return (
    <Box _alignSelf="flex-start" _noShrink>
      <Switch disabled={props.disabled} name={props.name} />
    </Box>
  );
};

export default FeatureSwitch;

import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { MySqlDatabase } from "./MySqlDatabase";

export class MySqlDatabaseList extends ListModel<MySqlDatabase> {
  public static useLoadAllByProjectId(projectId: string): MySqlDatabaseList {
    const databases = mittwaldApi.databaseListMysqlDatabases
      .getResource({ path: { projectId } })
      .useWatchData()
      .map((d) => MySqlDatabase.fromApiData(d));

    return new MySqlDatabaseList(databases);
  }
}

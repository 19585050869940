import invariant from "invariant";
import { MittwaldApi } from "../../api/Mittwald";

export type CustomerRoleName =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Membership_CustomerRoles;

const customerRoles: Record<CustomerRoleName, true> = {
  owner: true,
  member: true,
  accountant: true,
  notset: true,
};

export const allCustomerRoles = Object.keys(customerRoles);

export const allAvailableCustomerRoles = allCustomerRoles.filter(
  (role) => role !== "notset",
);

export class CustomerRole {
  public readonly name: CustomerRoleName;

  public constructor(name: CustomerRoleName) {
    invariant(
      allCustomerRoles.includes(name),
      `Unknown customer role: ${name}`,
    );
    this.name = name;
  }

  public is(name: CustomerRoleName): boolean {
    return this.name === name;
  }
}

export default CustomerRole;

import { MittwaldApi } from "../../api/Mittwald";
import Article from "../article/Article";
import ArticleFactory from "../article/ArticleFactory";
import MoneyValue from "../misc/MoneyValue";
import ContractItem from "./ContractItem";

export type ContractItemArticleApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_Article;

export class ContractItemArticle {
  public readonly data: ContractItemArticleApiData;
  public readonly contractItem: ContractItem;
  public readonly name: string;
  public readonly description?: string;
  public readonly unitPrice: MoneyValue;
  public readonly totalPrice: MoneyValue;
  public readonly amount: number;

  private constructor(
    contractItem: ContractItem,
    data: ContractItemArticleApiData,
  ) {
    this.contractItem = contractItem;
    this.data = Object.freeze(data);
    this.name = data.name;
    this.description = data.description;
    this.amount = data.amount;
    this.unitPrice = MoneyValue.fromApiContractPrice(data.unitPrice);
    this.totalPrice = this.unitPrice.multiply(data.amount);
  }

  public static fromApiData(
    contractItem: ContractItem,
    data: ContractItemArticleApiData,
  ): ContractItemArticle {
    return new ContractItemArticle(contractItem, data);
  }

  public useArticle(): Article {
    return ArticleFactory.useLoadById(this.data.id);
  }

  public async loadArticle(): Promise<Article> {
    return ArticleFactory.loadById(this.data.id);
  }
}

export default ContractItemArticle;

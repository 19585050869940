import { DeliveryBoxApiData } from "../../model/mail/DeliveryBox";
import { EmailAddressApiData } from "../../model/mail/EmailAddress";
import { ProjectSettingApiData } from "../../model/mail/ProjectSetting";
import { demoDate, demoEmail } from "../staticDemoData";

export const demoDeliveryBox: DeliveryBoxApiData = {
  id: "1",
  name: "d1000",
  projectId: "1",
  description: "Demo Postausgang",
  authenticationEnabled: true,
  sendingEnabled: true,
  passwordUpdatedAt: demoDate,
  updatedAt: demoDate,
};

export const demoMailAddress: EmailAddressApiData = {
  id: "1",
  projectId: "1",
  address: demoEmail,
  forwardAddresses: [],
  receivingDisabled: false,
  isCatchAll: false,
  isArchived: false,
  updatedAt: demoDate,
  mailbox: {
    name: "mailboxDemoName",
    passwordUpdatedAt: demoDate,
    sendingEnabled: true,
    spamProtection: {
      active: true,
      relocationMinSpamScore: 1,
      autoDeleteSpam: false,
      folder: "spam",
    },
    storageInBytes: {
      current: {
        value: 100000000,
        updatedAt: demoDate,
      },
      limit: 2147483648,
    },
  },
  autoResponder: {
    active: false,
    message: "Demo Nachricht",
  },
};

export const demoProjectSettings: ProjectSettingApiData = {
  blacklist: [],
  projectId: "1",
  whitelist: [demoEmail],
};

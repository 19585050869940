import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Customer, { CustomerUseOrdersOptions } from "../customer/Customer";
import DueDate from "../misc/DueDate";
import { AnyProject } from "../project";
import OrderItem from "./OrderItem";

export type OrderApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_CustomerOrder;

export type OrderStatusApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_OrderStatus;

export interface PendingOrder {
  data: {
    description?: string;
    orderStatus: OrderStatusApiData;
  };
}

export type UseOrdersOptions = MittwaldApi.Paths.V2_Orders.Get.Parameters.Query;

export class Order {
  public readonly data: OrderApiData;
  public readonly id: string;
  public readonly items: OrderItem[];
  public readonly type: "upgrade" | "downgrade";
  public readonly dueDate: DueDate;
  public readonly isExecuted: boolean;
  public readonly isConfirmed: boolean;
  public readonly isAborted: boolean;
  public readonly isRejected: boolean;
  public static readonly freeTrialUntil = DateTime.now().plus({ day: 10 });

  private constructor(data: OrderApiData) {
    this.data = Object.freeze(data);
    this.id = data.orderId;

    const items = data.items;
    this.items = items.map((i) => OrderItem.fromApiData(this, i));
    this.type = data.dueDate ? "downgrade" : "upgrade";
    this.dueDate = data.dueDate
      ? new DueDate(DateTime.fromISO(data.dueDate))
      : DueDate.now();
    this.isExecuted = data.status === "EXECUTED";
    this.isConfirmed = data.status === "CONFIRMED";
    this.isAborted = data.status === "ABORTED";
    this.isRejected = data.status === "REJECTED";
  }

  public static fromApiData(data: OrderApiData): Order {
    return new Order(data);
  }

  public useWatchCanBeAborted(): boolean {
    return this.dueDate.useIsOverdue().watch() && this.isConfirmed;
  }

  public static useLoadAll(options?: UseOrdersOptions): Order[] {
    const orders = mittwaldApi.orderListOrders
      .getResource({ query: options })
      .useWatchData();

    return orders.map((order) => {
      return Order.fromApiData(order);
    });
  }

  public static useLoadAllForCustomer(
    customerId: string,
    options?: CustomerUseOrdersOptions,
  ): Order[] {
    const customer = Customer.useLoadById(customerId);
    return customer.useOrders(options);
  }

  public static useLoadRejected(customerId?: string): Order[] {
    if (customerId) {
      const customer = Customer.useLoadById(customerId);
      return customer.useOrders({ includesStatus: ["REJECTED"] });
    }

    return Order.useLoadAll({ includesStatus: ["REJECTED"] });
  }

  public static useLoadMostRecent(customerId?: string): Order | undefined {
    if (customerId) {
      const customer = Customer.useLoadById(customerId);
      return customer.useOrders({ limit: 1 })[0];
    }

    return Order.useLoadAll({ limit: 1 })[0];
  }

  public static useLoadAllForProject(
    project: AnyProject,
    options?: CustomerUseOrdersOptions,
  ): Order[] {
    return project.useOrders(options);
  }

  public static useTryLoadById(id: string): Order | undefined {
    const data = mittwaldApi.orderGetOrder
      .getResource({ path: { orderId: id } })
      .useWatchData({ optional: true });

    return data ? Order.fromApiData(data) : undefined;
  }
}

export default Order;

import { MittwaldApi } from "../../api/Mittwald";
import UserInput from "../misc/userInput/UserInput";
import AppVersion from "./AppVersion";

export type AppUserInputApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_UserInput;

export class AppUserInput extends UserInput {
  public readonly appVersion: AppVersion;

  private constructor(appVersion: AppVersion, data: AppUserInputApiData) {
    super(data);
    this.appVersion = appVersion;
  }

  public static fromApiData(
    appVersion: AppVersion,
    data: AppUserInputApiData,
  ): AppUserInput {
    return new AppUserInput(appVersion, data);
  }
}

export default AppUserInput;

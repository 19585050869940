import { DateTime as DateTimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import {
  HookSourcedValue,
  useUnpackedValue,
} from "@mittwald/flow-lib/dist/hooks/useUnpackedValue";
import { DateTime } from "luxon";
import React, { FC } from "react";
import {
  ProSpaceArticle,
  SpaceServerArticle,
} from "../../../../../../model/article";
import ContractItem from "../../../../../../model/contract/ContractItem";
import Bytes from "../../../../../../model/misc/Bytes";

interface Props {
  contractItem: ContractItem;
  article: ProSpaceArticle | SpaceServerArticle;
  storageSize: HookSourcedValue<Bytes>;
}
export const TariffChangeExecutionDateInfo: FC<Props> = (props) => {
  const { contractItem, article } = props;
  const storageSize = useUnpackedValue(props.storageSize);

  const pricePreview = article.useOrderPreview({
    storageSize,
  });

  const executionDate = contractItem.useNextPossibleTariffChangeDate(
    pricePreview.total,
  );

  const dateElem =
    executionDate && executionDate > DateTime.now() ? (
      <DateTimeComponent format="date" value={executionDate} />
    ) : (
      <Translate i18n="immediately" />
    );

  if (!executionDate) {
    return null;
  }

  return (
    <TranslationProvider name="TariffChangeExecutionDateInfo" type="component">
      <Translate
        i18n={{
          id: executionDate > DateTime.now() ? "future" : "instant",
          values: {
            date: dateElem,
          },
        }}
      />
    </TranslationProvider>
  );
};

export default TariffChangeExecutionDateInfo;

import { MoneyValueInput } from "@mittwald/flow-components/dist/components/MoneyValue";
import { UnpackedValue } from "@mittwald/flow-lib/dist/hooks/useUnpackedValue";
import invariant from "invariant";
import { ContractPriceApiData } from "../contract/Contract";

export class MoneyValue {
  public euroCent: number;

  public constructor(euroCent: number) {
    this.euroCent = euroCent;
  }

  public static fromApiContractPrice(
    contractPrice: ContractPriceApiData,
  ): MoneyValue {
    invariant(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      contractPrice.currency === "EUR",
      `Contract price must be of type EUR. Received ${contractPrice.currency}`,
    );

    return new MoneyValue(contractPrice.value);
  }

  public static empty(): MoneyValue {
    return new MoneyValue(0);
  }

  public toMoneyValueInput(): UnpackedValue<MoneyValueInput> {
    return this.euroCent / 100;
  }

  public toYearlyMoneyValueInput(): UnpackedValue<MoneyValueInput> {
    return (this.euroCent * 12) / 100;
  }

  public add(other: MoneyValue): MoneyValue {
    return new MoneyValue(this.euroCent + other.euroCent);
  }

  public subtract(other: MoneyValue): MoneyValue {
    return new MoneyValue(this.euroCent - other.euroCent);
  }

  public multiply(by: number): MoneyValue {
    return new MoneyValue(this.euroCent * by);
  }

  public valueOf(): number {
    return this.euroCent;
  }
}

export default MoneyValue;

import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { isEUMember } from "../utils";
import IBAN from "iban";

const isBicRequired = (iban: string): boolean => {
  if (iban.length < 2) {
    return false;
  }
  const countryCode = iban.slice(0, 2);

  return !isEUMember(countryCode);
};

interface Props {
  isDisabled?: boolean;
}

export const DebitForm: FC<Props> = (props) => {
  const { isDisabled } = props;
  const form = useFormContext();

  const watchedPaymentIban = form.watch("paymentSettings.iban");

  return (
    <TranslationProvider name="debitForm" type="component">
      <ColumnLayout medium={[3, 2]}>
        <TextField
          disabled={isDisabled}
          label="accountHolder"
          name="paymentSettings.accountHolder"
          rules={{ required: true }}
        />
      </ColumnLayout>
      <ColumnLayout medium={[3, 2]}>
        <TextField
          disabled={isDisabled}
          label="iban"
          name="paymentSettings.iban"
          rules={{
            required: true,
            getValueAs: {
              cleanup: (value) => value?.trimStart(),
            },
            setValueAs: {
              cleanup: (value) => value?.trimStart(),
            },
            validate: {
              invalidIban: (value) => IBAN.isValid(value.toUpperCase()),
            },
          }}
        />
        {isBicRequired(watchedPaymentIban) && (
          <TextField
            label="bic"
            name="paymentSettings.bic"
            rules={{ required: true }}
          />
        )}
      </ColumnLayout>
      <CheckBox
        disabled={isDisabled}
        name="paymentSettings.confirmDebit"
        rules={{ required: true }}
        title="confirmDebit"
      />
    </TranslationProvider>
  );
};

export default DebitForm;

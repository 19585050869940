import { formatPhoneNumber } from "@mittwald/flow-components/dist/components/PhoneNumber/libs";

export class PhoneNumber {
  public readonly value: string;

  public constructor(value: string) {
    this.value = value;
  }

  public formatted(): string {
    return formatPhoneNumber(this.value);
  }
}

export default PhoneNumber;

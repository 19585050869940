import Bytes from "../misc/Bytes";
import { ArticleAttribute, ArticleAttributeApiData } from "./";

export class RAMArticleAttribute extends ArticleAttribute {
  public readonly bytes: Bytes;
  public static readonly key = "ram";

  public constructor(apiData: ArticleAttributeApiData) {
    super(apiData);
    this.bytes = Bytes.parse(this.valueWithUnit);
  }
}

export default RAMArticleAttribute;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import invariant from "invariant";
import { mittwaldApi } from "../../api/Mittwald";
import { ArticleFactory } from "../article";
import {
  AdjustProSpaceInput,
  ProSpaceArticle,
  ArticleAttributeKey,
  ArticleId,
  ArticleTemplateName,
} from "../article";
import Contract from "../contract/Contract";
import Bytes from "../misc/Bytes";
import ListModel from "../misc/ListModel";
import Order, { PendingOrder } from "../order/Order";
import MachineType from "../server/MachineType";
import {
  ProjectList,
  ProSpaceProjectMetrics,
  ProjectBase,
  ProjectApiData,
} from "./";

export class ProSpaceProject extends ProjectBase {
  public readonly projectHostingId: string;

  public constructor(data: ProjectApiData) {
    super(data);
    invariant(
      data.projectHostingId,
      "proSpace project requires projectHostingId",
    );
    this.projectHostingId = data.projectHostingId;
  }

  public useContract(): Contract {
    const data = mittwaldApi.contractGetDetailOfContractByProject
      .getResource({
        path: {
          projectId: this.id,
        },
      })
      .useWatchData();

    return Contract.useLoad(data.contractId);
  }

  public useOptionalContract(): Contract | undefined {
    const membershipIsInherited = this.useMyMembership().data.inherited;
    const data = mittwaldApi.contractGetDetailOfContractByProject
      .getResource(
        membershipIsInherited
          ? {
              path: {
                projectId: this.id,
              },
            }
          : null,
      )
      .useWatchData({ optional: true, throwOnError: false });

    return data ? Contract.useLoad(data.contractId) : undefined;
  }

  public useStorage(): Bytes {
    const contract = this.useContract();
    const article = this.useArticle();
    const storageAmount =
      contract.baseItem.articles.find(
        (a) => a.data.id === ArticleId.proSpaceHostingStorage,
      )?.amount ?? 0;

    const modifierBytes = article.storageModifier.useBytes().bytes;
    const baseStorage = article.baseStorageAttribute.bytes.bytes;
    return Bytes.of(baseStorage + modifierBytes * storageAmount, "bytes");
  }

  public useMachineType(): MachineType {
    return this.useArticle().machineType;
  }

  public useArticle(): ProSpaceArticle {
    return this.useContract().baseItem.baseArticle!.useArticle() as ProSpaceArticle;
  }

  public useIsProSpaceLite(): boolean {
    return !this.data.serverId && !this.data.serverShortId;
  }

  public getMetrics(): ProSpaceProjectMetrics {
    return ProSpaceProjectMetrics.of(this);
  }

  public async adjust(values: AdjustProSpaceInput): Promise<void> {
    const storageSize = Bytes.of(values.storageSizeInBytes, "bytes");

    const response = await mittwaldApi.orderCreateTariffChange.request({
      requestBody: {
        tariffChangeType: "projectHosting",
        tariffChangeData: {
          contractId: values.contractId,
          diskspaceInGiB: storageSize.in("GiB"),
          spec: values.article.machineType.name
            ? { machineType: values.article.machineType.name }
            : {
                vcpu: values.article.machineType.cpu,
                ram: values.article.machineType.memory.in("GiB"),
              },
        },
      },
    });
    assertStatus(response, 201);
  }

  public static usePendingProSpaceOrders(
    customerId?: string,
  ): ListModel<PendingOrder> {
    const existingProjects = customerId
      ? ProjectList.useLoadByCustomerId(customerId).useItems()
      : ProjectList.useLoad().useItems();
    const existingProjectDescriptions = existingProjects.map(
      (s) => s.data.description,
    );

    const proSpaceArticles = ArticleFactory.useLoadAllByTemplate(
      ArticleTemplateName.proSpaceHosting,
    );

    const allOrders = customerId
      ? Order.useLoadAllForCustomer(customerId, {
          includesStatus: ["CONFIRMED", "NEW"],
        })
      : Order.useLoadAll({
          includesStatus: ["CONFIRMED", "NEW"],
        });

    return new ListModel(
      allOrders
        .filter((o) => o.data.type === "NEW_ORDER")
        .filter((o) =>
          proSpaceArticles.some((a) => o.items[0]?.referencesArticle(a)),
        )
        .map((o) => ({
          data: {
            description: o.items[0]?.getAttributeValue(
              ArticleAttributeKey.description,
            ),
            orderStatus: o.data.status,
          },
        }))
        .filter(
          (i) =>
            i.data.description === undefined ||
            !existingProjectDescriptions.includes(i.data.description),
        ),
    );
  }
}

export default ProSpaceProject;

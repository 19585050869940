import { iconApp } from "@mittwald/flow-icons/dist/app";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import AppInstallation from "./AppInstallation";

export class AppModelRelation extends ModelRelation {
  public static type = new ModelRelationType("app", "appinstallation", iconApp);

  public constructor(appInstallationId: string) {
    super(appInstallationId, AppModelRelation.type);
  }

  public useAppInstallation(): AppInstallation {
    return AppInstallation.useLoadById(this.id);
  }

  public useOptionalAppInstallation(): AppInstallation | undefined {
    return AppInstallation.useTryLoadById(this.id);
  }
}

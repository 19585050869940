import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ContractItem from "./ContractItem";
import ContractItemArticle from "./ContractItemArticle";

export type TariffChangeApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Contract_TariffChange;

export class TariffChange {
  public readonly data: TariffChangeApiData;
  public readonly scheduledAtDate: DateTime;
  public readonly targetDate: DateTime;
  public readonly contractItem: ContractItem;
  public readonly newArticles: ContractItemArticle[];

  private constructor(contractItem: ContractItem, data: TariffChangeApiData) {
    this.contractItem = contractItem;
    this.data = Object.freeze(data);
    this.scheduledAtDate = DateTime.fromISO(data.scheduledAtDate);
    this.targetDate = DateTime.fromISO(data.targetDate);
    this.newArticles = data.newArticles.map((a) =>
      ContractItemArticle.fromApiData(this.contractItem, a),
    );
  }

  public static fromApiData(
    contractItem: ContractItem,
    data: TariffChangeApiData,
  ): TariffChange {
    return new TariffChange(contractItem, data);
  }

  public async cancel(): Promise<void> {
    const result = await mittwaldApi.contractCancelContractTariffChange.request(
      {
        path: {
          contractId: this.contractItem.contract.id,
          contractItemId: this.contractItem.id,
        },
      },
    );

    assertStatus(result, 200);
  }
}

export default TariffChange;

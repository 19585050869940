import { hash } from "object-code";
import { MittwaldApiV1 } from "../../../api/MittwaldV1";
import { ArticleApiData } from "../../article";

export interface DomainPriceApiData {
  tld: string;
  price?: number;
  mStudio: boolean;
  controlCenter: boolean;
}

export type TldApiData = MittwaldApiV1.Components.Schemas.TopLevelDomain;

export class DomainPrice {
  public readonly data: DomainPriceApiData;
  public readonly id: string;
  public readonly tld: string = "";
  public startPrice: number = 0;
  public mStudio: boolean = false;
  public controlCenter: boolean = false;

  private constructor(data: DomainPriceApiData) {
    this.data = Object.freeze(data);
    this.id = hash(data.tld).toString();
    this.tld = data.tld;
    this.startPrice = data.price ?? 0;
    this.controlCenter = data.controlCenter;
    this.mStudio = data.mStudio;
  }

  public get name(): string {
    return `.${this.tld}`;
  }

  public static fromArticleApiData = (data: ArticleApiData): DomainPrice => {
    const topLevel = data.attributes?.find((a) => a.key === "toplevel");
    return new DomainPrice({
      price: data.price ? data.price / 100 : 0,
      mStudio: true,
      controlCenter: false,
      tld: (topLevel ? topLevel.value : "") ?? "",
    });
  };

  public static fromTldApiData = (data: TldApiData): DomainPrice => {
    return new DomainPrice({
      price: Number.parseFloat(data.price ?? ""),
      mStudio: false,
      controlCenter: true,
      tld: data.name ?? "",
    });
  };
}

export default DomainPrice;

import { parse } from "psl";
import z from "zod";

const ParsedDomain = z.object({
  tld: z.string(),
  sld: z.string(),
  domain: z.string(),
});

export class DomainName {
  public readonly domain: string;
  public readonly tld: string;
  public readonly sld: string;

  public constructor(domain: string) {
    const parsed = DomainName.parse(domain);

    this.tld = parsed.tld;
    this.domain = parsed.domain;
    this.sld = parsed.sld;
  }

  public static parse(domain: string): z.infer<typeof ParsedDomain> {
    const parsedDomain = parse(domain);
    if (parsedDomain.error) {
      throw parsedDomain.error;
    }
    return ParsedDomain.parse(parsedDomain);
  }

  public getUrl(): string {
    return `https://${this.domain}`;
  }
}

export default DomainName;

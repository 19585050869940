import { faCartShopping } from "@fortawesome/pro-regular-svg-icons/faCartShopping";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import Order from "./Order";

export class OrderModelRelation extends ModelRelation {
  public static type = new ModelRelationType("order", "order", faCartShopping);

  public constructor(orderId: string) {
    super(orderId, OrderModelRelation.type);
  }

  public useOptionalOrder(): Order | undefined {
    return Order.useTryLoadById(this.id);
  }
}
